/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 06.06.2016
 *
 * inherit from "...\src\.modman\Mindscreen.LabbeAll\skin\frontend\labbe\default\css\styles.css"
 */

/* Topics ================================================================================ */
.topics {
    margin-bottom: @spacing-l;
}

.topics ul {
    margin: 0;
}

.topics li {
    margin-bottom: @spacing-s - 2;
}

.topics h3 {
    margin-bottom: @spacing-xs;
}

.topics h2 {
    font-weight: @font-weight-normal;
    color: @mint-dark;
}

.c1 {
    text-align: center;
}

.topics table, .topics table td, .topics table th {
    border: solid 1px @mint-lighter;
    padding: 5px 3px 8px 3px;
}

.topics table th {
    font-weight: bold;
    padding: @spacing-xs @spacing-xxs @spacing-s - 2 @spacing-xs;
    color: @mint-dark;
    background-color: @mint-lighter;
}

.topics dl {
    display: block;
    text-align: center;
}

.topics dt {
}

.topics dd {
    display: block;
    padding: 4px 10%;
}

.topics dd p {
    text-align: left;
    font-size: @font-size-s;
    font-style: italic;
}

/* topics index list */
.topics-index-list {
    -moz-column-count: 2;
    -moz-column-rule: none;
    -moz-column-gap: 29px;
    -webkit-column-count: 2;
    -webkit-column-rule: none;
    -webkit-column-gap: 29px;
    padding-bottom: @spacing-l;
    background: url(../images/hr-dotted-orange.png) repeat-x bottom left;
}

.topics-category-list {
    margin: 1em 0 3em 0;
}

.topics-category-list h2 {
}

.topics-category-list ul {
    margin-bottom: 2em;
}

.topics-category-list li {
    margin: 0.4em 0;
}

.topic-header {
    font-weight: bold;
    margin-bottom: 1em;
}

.topic-article {
    margin: 2em 0 3em 0;
}

.topics-idea-list .sectionheader {
    clear: both;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

/* topic featured */
.topic-featured {
    margin-top: 50px;
}

.topics.topic-featured {
    margin-bottom: 0;
}

/* topics typo general */
.hinweis {
    border-radius: 8px;
    background: #fff;
    margin: 20px 0;
    padding: 10px 20px;
    border: 1px solid #778d0b;
    background: none repeat scroll 0% 0% transparent;
}

.hinweis h3 {
    color: #778d0b;
}

.hinweis li {
    padding-left: 8px;
    background: url(../images/topics-list-icon.png) no-repeat left 0.6em;
}

/* topics magazine teaser */
.topic-grid .img {
    display: block;
    width: 180px;
    height: 120px;
    overflow: hidden;
}

.topic-grid li {
    width: 260px;
    padding: 0 10px 5px 0;
    min-height: 190px;
    float: left;
}

.topic-grid .topic-info {
    padding-top: 0.6em;
}

.topic-grid li strong {
    display: block;
    padding-bottom: 0.5em;
}

/* Grid-List */
.grid-list {
    margin: 2em 0;
    position: relative;

    a {
        display: block;
        text-align: center;
    }

    ul {
        margin: 0 !important;
        width: auto !important;
    }

    li.last {
        margin-right: 0px;
    }

    .link-arrow-before {
        margin: @spacing-xxs 0 0 0;
        position: relative;
        display: block;
    }

    .link-arrow-before {
        .icon {
            margin: @spacing-xxs 0 0 0;
            height: @spacing-s - 2;
            width: @spacing-s - 2;
            background: url(../images/more-link.png) no-repeat 0 0;
            display: block;
            overflow: hidden;
            text-indent: -99999px;
            left: 0.5em;
            margin-top: 0px;
            position: absolute;
            top: 30%;
        }

        .text {
            margin-left: @spacing-m - 2;
            display: block;
            text-align: left;
        }
    }

    .w150 {

        li {
            min-height: @grid-height-l !important;
        }

        .grid-topic-image {
            width: @grid-width-m;
            height: @grid-width-m;
        }
    }
}

.grid-list li,
.std .grid-list ul li {
    float: left;
    width: @grid-width-l;
    min-height: @grid-height-m;
    margin: 0 @spacing-l @spacing-s 0 !important;
    padding-left: 0 !important;
    background: none;
    font-size: @font-size-sm;
}

.main .grid-list a:hover,
.main .grid-list a:focus {
    background-color: #fff !important;
    color: #416595 !important;
    text-decoration: none !important;
}

.grid-list a:hover .link-arrow-before strong,
.grid-list a:focus .link-arrow-before strong {
    background: #4E73A0;
    color: #fff;
}

.grid-topic-image {
    width: @grid-width-s;
    height: @grid-width-s;
    overflow: hidden;
    margin: 0 auto;
}

.grid-list .w150 a:hover .grid-topic-image,
.grid-list .w150 a:focus .grid-topic-image {
    background-position: 0 -@grid-width-m !important;
}

.grid-list a:hover .grid-topic-image,
.grid-list a:focus .grid-topic-image {
    background-position: 0 -@grid-width-s !important;
}

/* sectionheader */
.sectionheader {
    position: relative;
    z-index: 0;
    background-color: #FEF8D6;
    margin: 0;
    margin-bottom: 10px;
    color: #CB4510;
    font-size: 116.66%;
    font-weight: bold;

    .mod-content {
        position: relative;
        z-index: 2;
        border: solid 1px #fff;
    }

    .x1 {
        background-position: 1px top;
        z-index: 3;
        top: -1px; /* set to the size of the glow */
        left: 0px;
        bottom: 0;
    }

    .x2 {
        background-position: 1px bottom;
        z-index: 4;
        bottom: -1px;
        right: 0px;
        padding: 0;
    }

    h1 {
        font-size: 100%;
    }

    .block-title {
        padding: 2px 8px;
    }
}

.sectionheader .x1,
.sectionheader .x2 {
    display: block;
    background: url(../images/hr-dotted-orange.png) repeat-x;
    /* turn off for IE6 or use a GIF */
    _background: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
}

/* Content Columns */
.col2-set {
    clear: both;

    .col-narrow {
        width: 33%;
    }

    .col-wide {
        width: 65%;
    }
}

.col2-set .col-1,
.print-col2-set .col-1 {
    float: left;
    width: 50%;
    position: relative;
}

.col2-set .col-2,
.print-col2-set .col-2 {
    float: right;
    width: 50%;
    position: relative;
}

.grid-list:after,
#magazine-teaser:after,
.col-main:after,
.col2-set:after,
.col3-set:after,
.col4-set:after,
.col-1:after,
.col-2:after,
.col-3:after {

    display: block;
    content: ".";
    clear: both;
    font-size: 0;
    line-height: 0;
    height: 0;
    overflow: hidden;
}

.topics-topic-view {

    .product-item {

        .image {
            display: none;
        }
        .add-to-cart {
            display: none;
        }

        .price-box {
            display: none;
        }

        .hideme {
            display: none;
        }

        .qty {
            display: none;
        }
    }
}