/**
*
 * content styles checkout, individual styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 16.06.16
 *
 */

.checkout-onepage__spacing {
    margin: @spacing-sm 0 (@spacing-m + @spacing-xs) !important;
}

// lists with radio buttons
.checkout-onepage__list {
    .checkout-onepage__spacing();

    li {
        display: inline-block;
        padding: 0 0 @spacing-m !important;

        &:last-child {
            padding: 0 !important;
        }

        .screen-sm({ width: 50% !important; padding: 0 !important; });

        label {
            color: @black !important;
            font-size: @font-size-l;
            letter-spacing: @letter-spacing-3xs !important;
            line-height: @line-height-m;
        }
    }
}

// lists with input, form-fields
.checkout-onepage__form-list {
    .checkout-onepage__spacing();

    li {
        margin-bottom: @spacing-s !important;

        #billing-new-address-form & {
            margin-bottom: 0 !important;
            padding: 0 !important;
        }

        #shipping-new-address-form & {

            &:first-child {
                margin-bottom: 0 !important;
            }
        }
    }

    label {
        top: 12px !important;
        color: @color__attributes !important;

        &:before {
            top: 0 !important;
        }
        
        &[for="billing:country_id"],
        &[for="billing:salutation"],
        &[for="billing:region_id"],
        &[for="billing-address-select"],
        &[for="shipping:country_id"],
        &[for="shipping:salutation"],
        &[for="shipping:region_id"],
        &[for="shipping-address-select"] {
            display: block;
            position: relative !important;
            top: -5px !important;
            left: 0 !important;
            width: auto;
            padding-left: @spacing-l;
            color: @brand-primary !important;
            font-style: @font-style-italic !important;
            letter-spacing: @letter-spacing-xxs !important;
            
            em {
                position: relative;
                top: auto;
                right: auto;
                margin-left: 3px;
                color: @color__attributes;
            }
        }

        #billing-new-address-form & {

            &[for="billing:email"]{

                &:before {
                    content: '';
                }
            }
        }
    }
}

// buttons
.checkout-onepage__buttons {
    margin: 0 0 @spacing-m !important;
    padding: 0 !important;

    .screen-md({ margin: 0 !important; });
}

.checkout-onepage__buttons--left {
    .checkout-onepage__buttons();

    .screen-sm({ margin: @spacing-m 0 !important; });
    .screen-md({ text-align: left !important; });
}

.checkout-onepage__buttons--block {
    .checkout-onepage__buttons();
    
    a {
        display: inline-block;
        margin: @spacing-s 0;
        float: left;
    }

    button {

        #checkout-step-billing &,
        #checkout-step-shipping &,
        #checkout-step-payment & {
            float: right;
        }
    }
}

// step items
#checkout-step-login {
    padding: @spacing-s 0;

    .col-1 {
        border-bottom: 1px solid @grey-light;

        .screen-sm({ width: 100%; });
        .screen-md({ width: 50%; padding-right: @spacing-s; border-bottom: none; });
    }

    .col-2 {
        .screen-sm({ width: 100%; });
        .screen-md({ width: 50%; padding-left: @spacing-s; });
        }
}

.checkout-onepage__info-text {
    display: none;

    .screen-sm({ display: block; });
}

.chosen-container-single .chosen-single {
    .border-radius(0);
    .shadow();

    padding: 0;
    height: 40px;
    color: @color__attributes;
    background: transparent;
    border: none;
    letter-spacing: @letter-spacing-xxs;
    line-height: @line-height-2xl;

    div b {
        background-position: 0 8px;
    }
}

.chosen-container-active.chosen-with-drop .chosen-single {
    .shadow();

    background: transparent !important;
    border: none !important;

    div b {
        background-position: 0 8px;
    }
}

.checkout-onepage__control-list {
    display: block;
    margin: @spacing-s 0 @spacing-m;
    overflow: hidden;

    li {

        &.control {

            label {
                color: @black;
                letter-spacing: @letter-spacing-3xs !important;
            }
        }
    }
}

.checkout-onepage__payment-list {
    margin: @spacing-s 0;

    label {
        font-weight: @font-weight-normal;
        letter-spacing: @letter-spacing-xxs;
    }
}

.agreement-content {
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;

    .screen-sm({ max-width: 300px; });

    a {
        color: @orange-dark;
        text-decoration: none !important;
    }
}

.checkout-cart-index .checkout-types,
#review-buttons-container {
    display: block;
    width: 100%;
    padding: @spacing-s;
    float: none;

    .screen-sm({ width: 50%; max-width: 300px; margin: @spacing-s; padding: 0; float: right; clear: both; });

    > .btn-checkout {
        width: 100%;
        margin: @spacing-s 0;
    }

    span {
        letter-spacing: @letter-spacing-xxs;

        i {
            margin: 0 @spacing-xs;
            font-weight: @font-weight-bold !important;
        }
    }
}

#co-payment-form {

    .input-box {
        margin: 0 0 @spacing-l;
    }
    
    .payone__info-text {
        display: none;
    }
}