/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 13.06.2016
 */
.checkout-cart {

    .screen-sm2({ margin-top: 0; });
    //.screen-md({ margin-top: 2 * @spacing-l; });

    .page-title {

        @media (max-width: @screen-sm2-max) {
            text-align: center;

            h1 {
                color: @black !important;
                font-size: @font-size-l !important;
                font-weight: @font-weight-bold !important;
                text-transform: uppercase;
            }

            .mobile-qty {
                color: @orange-dark;
                font-weight: @font-weight-normal;
            }
        }

        h1 {
            padding-left: @spacing-sm;
            color: @mint;
            font-weight: @font-weight-normal;
            letter-spacing: 1px;
        }

        hr {
            border: 1px solid @mint;
            margin-top: 0;
        }

        .screen-md({ margin-top: 0; });
    }

    .btn-continue {
        padding-left: @spacing-m;
        color: @black;
        font-weight: @font-weight-bold;
        font-size: @font-size-m;
        letter-spacing: 1px;
        text-transform: uppercase;

        &:before {
            font-family: FontAwesome;
            position: absolute;
            margin: 0 -@spacing-m;
            font-weight: @font-weight-bold;
            font-size: @font-size-sm;
            content: "\f053";
        }
    }

    .totals {
        margin-top: @spacing-xl - 10;
    }

    .btn-checkout {
        .button-cart--primary();

        width: 100%;

        .screen-md({ float: none });
    }

    .data-table {
        margin-top: 0 !important;

        img {
            width: @grid-width-s - 20 !important;
            height: @grid-width-s - 20 !important;
        }

        .product-name {
            padding-bottom: @spacing-xs !important;

            a {
                font-size: @font-size-m !important;
                font-weight: @font-weight-bold;
                text-transform: none !important;
            }
        }

        .product-image {
            float: left;
            border: none;
        }

        .cart-item-actions {
            display: flex;
            clear: both;
            padding-top: @spacing-s;
        }

        .cart-item-info {
            min-height: 50px;
            margin-left: 110px;

            @media (min-width: @screen-sm) {
                margin-left: 0;
            }

            .tier-prices {
                color: @orange;
                font-size: @font-size-sm;
                font-weight: @font-weight-bold;
                margin-bottom: 0 !important;
            }

            .cart-item-info__item {
                font-size: @font-size-sm;
            }

        }

        .cart-actions {
            min-width: 100px;

            .edit-item {
                color: @grey-darkest;
                position: absolute;
                font-size: @font-size-s;
                padding: @spacing-xxs @spacing-s;

                &:hover {
                    color: @grey-darker !important;
                }
            }
        }

        .update-item-qty {
            display: flex;
            margin-left: auto;

            .items {
                background-color: @grey;
                color: @black;
                cursor: pointer;
                display: inline-block;
                height: @spacing-l - 3;
                line-height: @spacing-l - 3;
                text-align: center;
                width: @spacing-l - 3;
                margin: @spacing-xs;

                &:hover {
                    background-color: @black;
                    color: @white;
                }
            }

            .qty {
                margin-top: @spacing-xs;
                height: @spacing-l - 3;
                width: @spacing-l - 3 !important;
            }
        }

        .btn-update {
            background-color: @white !important;
            padding: 0 @spacing-xs !important;
            height: @spacing-l;
            z-index: -1;

            .fa-refresh {
                font-size: @font-size-xl !important;
                color: @white;
            }
        }

        .cart-item-price {
            margin-left: auto;
            text-align: right;

            .cart-price {
                font-size: @font-size-m !important;
            }

            .cart-item-single-price {
                color: @puro-settings__color--footer-text-color;

                .price {
                    font-size: @font-size-sm !important;
                }
            }

            .cart-item-subtotal {
                font-weight: @font-weight-bold;
            }
        }

        .fa-remove {
            color: @mint;
            font-size: @font-size-xl;

            &:hover {
                color: @mint-dark;
            }
        }

        .cart-item__download-info {
            font-size: @font-size-sm;
            margin-left: auto;
            margin-top: @spacing-m;
        }

    }

    .totals {
        background-color: @white !important;
        padding: 0 !important;

        tbody {

            tr {
                background: @grey-lighter;
                border-top: 1px solid @grey-light;

                &:last-child {
                    border-bottom: 1px solid @grey-light;
                }

                td:last-child {
                    text-align: right;
                }
            }

            td {
                padding: @spacing-sm !important;
            }
        }

        &.affix {
            position: relative;

            .screen-sm2({ position: fixed; top: 13px; width: 294px; z-index: 100; });
            .screen-md({ top: 17px; });
            .screen-md-2({ top: 33px; });
            .screen-lg({ width: 370px; })
        }

        .link-extern {
            text-decoration: underline;
        }

        .delivery-de {
            font-weight: @font-weight-normal;
            font-style: italic;
        }

        .totals-table__tax {
            font-size: @font-size-sm !important;
            font-style: @font-style-italic;
            font-weight: @font-weight-normal !important;

            .screen-sm({ text-align: left !important; });
        }

        tfoot {
            border-top: none !important;

            .table--totals {

                td {
                    padding-top: @spacing-l !important;

                    &:first-child {

                        .screen-sm({ text-align: left !important; });
                    }

                    span,
                    strong {
                        font-size: @font-size-xl;
                    }
                }
            }
        }

        .checkout-types {
            display: block;
            padding: 0 !important;

            .screen-sm({ width: 100%; max-width: inherit; });
        }
    }
}

.checkout-cart__trust {
    color: @brand-primary;
    text-align: center;
    clear: both;

    dt {
        padding-top: @spacing-l;
        font-size: @font-size-m;
        font-weight: @font-weight-normal;
        letter-spacing: @letter-spacing-xxs;

        i {
            position: relative;
            top: -1px;
        }
    }

    dd {
        color: @brand-primary--light;
        font-size: @font-size-m;
        font-style: @font-style-italic;
        letter-spacing: @letter-spacing-xxs;
    }
}

.cart__additional-info {

    .additionalInfo__entry {
        font-size: @font-size-m;
        letter-spacing: normal;
        text-transform: none;
    }
}

.cart-review__additional-info {

    .additionalInfo__entry {
        font-size: @font-size-l;
        letter-spacing: normal;
        text-transform: none;
    }
}