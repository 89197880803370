button:not(.navbar-toggle) {
    padding: @spacing-s (@spacing-xxs * 3) !important;

    .screen-xs({ padding: (@spacing-xxs * 3) (@spacing-s + 2) !important });

    * {
        font-size: @font-size-m !important;
        font-weight: @font-weight-light !important;
        letter-spacing: @letter-spacing-3xs;

        .screen-xs({ font-size: @font-size-l !important; font-weight: @font-weight-normal !important; })
    }
}

.btn-default {
    line-height: @line-height-m !important;

    &:hover {
        background-color: @mint-dark !important;
    }
}

.button {
    cursor: pointer;
    border: 1px solid transparent !important;
    border-radius: 4px;
    height: 38px;

    &:before {
        content: none !important;
    }

    span {
        position: relative;
        padding: 0 @spacing-xxs !important;
        line-height: @line-height-m !important;
        vertical-align: inherit !important;

        .product-boxes__buttons & {
            height: auto !important;
            padding: 0 !important;
            line-height: @line-height-xs !important;
        }

        span > span {
            display: inline-block;
            height: auto !important;
            padding: 0 !important;
            float: inherit;
            line-height: @line-height-xs !important;

            &.available,
            &.available-hover {
                line-height: @line-height-m !important;
            }
        }
    }
}

.button--primary {
    .transition(.6ms);

    background-color: @mint-lighter !important;

    &:active,
    &:focus,
    &:hover {
        background-color: @mint !important;
    }
}

.button--secondary {
    .transition(.6ms);

    background-color: @pink-light !important;

    &:active,
    &:focus,
    &:hover {
        background-color: @pink !important;
    }
}

.button-cart--primary {
    .transition(.6ms);

    background-color: @orange-dark !important;

    &:active,
    &:focus,
    &:hover {
        background-color: @black !important;
    }

    span {
        line-height: @line-height-m !important;
        height: auto !important;
    }
}

.button--clear {
    .transition(.6ms);

    color: @orange-dark;
    text-decoration: none;
    text-transform: uppercase;

    &:active,
    &:focus,
    &:hover {
        color: @black;
    }
}

.mobile-button {
    background-color: transparent !important;
}

.search-button {
    .flex(1);

    margin-left: 0;
    color: @color__button;
    background-color: @color__button--background;
    border: 1px solid @color__button--background;
    text-transform: uppercase;

    &:hover {
        color: @color__button--hover !important;
    }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%% Button Cart - all types %%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// noinspection ALL
.btn-cart {
    text-transform: uppercase;
    width: 100%;
    font-size: @font-size-l;
    font-weight: @font-weight-normal;

    &:hover,
    &:active,
    &:focus {

        .product-type-grouped &,
        .product-type-configurable & {

            .screen-md({ .available-hover {
                display: block;
            } .available {
                  display: none;
              } });
        }
    }

    .availability {
        padding: 0 !important;
    }

    .available-hover {
        display: none;
    }

    .fa {
        position: absolute;
        top: @spacing-xxs;
        right: 0;

        &.fa-shopping-cart {

            .product-type-grouped &,
            .product-type-configurable & {
                display: none;
            }
        }

        &.fa-arrow-right {

            .product-type-simple &,
            .product-type-downloadable & {
                display: none;
            }

            //show info icon for "not-saleable" products
            .not-saleable & {
                display: block;
            }
        }
    }

    .price {
        color: inherit !important;
        float: right;
        padding-left: 0 !important;

        .product-boxes__buttons & {
            color: @white !important;
        }
    }
    .minimal-price {
        line-height: inherit;
        margin: inherit;
        text-transform: lowercase;
        display: inline-block;
    }

    .not-saleable & {
        background-color: @grey-darker !important;

        .out-of-stock-hover {
            display: none;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: @grey-dark !important;

            .screen-md({ .out-of-stock-hover {
                display: block !important;
            } .out-of-stock {
                  display: none;
              } });
        }

        .fa-shopping-cart {
            display: none;
        }
    }

    .hideme,
    .old-price-label,
    .old-price,
    .special-price .price-label {
        display: none;
    }

    .regular-price,
    .special-price {
        display: inline-block;
    }

    .special-price {
        line-height: inherit;
        margin: 0;
    }

    .price-box * {
        padding: 0;
    }

    .price-label {
        margin: 0 @spacing-xxs;
        float: left;
        text-transform: lowercase;
    }
}

.product-view .actions-wrapper,
.product-view .product-options-bottom {

    .add-to-cart {
        .display-flex();
        .flex-wrap(wrap);
        .align-items();

        margin: 0;
        padding: 0;
        border-top: none;

        .screen-md({ margin: 0 @spacing-s; });

        .input-box {
            margin: @spacing-xs @spacing-s @spacing-s 0;

            .screen-xs({ margin: @spacing-xs @spacing-s @spacing-xs 0; });

            .items {
                color: @black;
                background-color: @grey;

                &:active,
                &:focus,
                &:hover {
                    background-color: @black;
                }
            }

            input {
                color: @color__attributes;
                border: 1px solid @color__attributes !important;

                &:active,
                &:focus {
                    background-color: @mint-transparent !important;
                }
            }
        }

        .addtocart {

            .button-wrapper {
                display: block;
                width: inherit;
                height: auto;
                background-color: transparent;

                .btn-cart {
                    .transition(background .6s);
                    .border-radius(5px);

                    padding: @spacing-s @spacing-m !important;
                    font-size: @font-size-l;
                    line-height: @line-height-s;
                    background: @button__primary;
                    border: none;

                    &:active,
                    &:focus,
                    &:hover {
                        background: @black;
                    }

                    span {
                        height: auto;
                        padding: 0 0 1px;
                        font-weight: @font-weight-normal !important;
                        line-height: @line-height-s;
                        vertical-align: middle;

                        .fa {
                            margin: 0 0 0 @spacing-s;
                            padding: 0 !important;
                            float: right;
                        }
                    }
                }
            }
        }

        .wishlist {
            .order(3);

            width: 100%;

            .screen-xs({ width: auto; margin-left: @spacing-s; });

            .add-to-links {
                margin-bottom: 0;

                li {
                    border-right: none;

                    .link-wishlist {
                        background-color: transparent;

                        &:before {
                            position: relative;
                            top: auto;
                            bottom: auto;
                            border-right: none;
                        }

                        .fa {
                            padding: @spacing-s 0;
                            color: darken(@grey-light, 15%);
                            font-size: @font-size-xxl;
                            
                            &:active,
                            &:focus,
                            &:hover {
                                color: @mint;
                            }
                        }
                    }
                }
            }
        }

        .add-to-cart__label--pdf {
            margin-right: @spacing-m;
            color: darken(@grey-light, 15%);
            font-size: @font-size-xxl;
        }
    }
}

.product-view .product-options-bottom {

    .add-to-cart {

        .screen-md({ padding: 0; });
    }
}

.product-shop--pdf {

    .add-to-cart {

        .screen-md({ padding: 0 @spacing-s !important; });
    }
}