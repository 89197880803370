/**
 *
 * main styles checkout onepage, steps, content, grid
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 15.06.16
 *
 */

// grid
.checkout-onepage-index {

    .main-container .main-wrapper {
        margin-top: 0;
    }

    .container {
        .screen-sm({ max-width: 100%; });
        .screen-md({ max-width: 970px; });
    }

    .col-lg-9 {
        padding: 0 !important;

        .screen-md({ padding: 0 @spacing-sm !important; });
    }
}

// main header
.checkout-onepage-index {

    .checkout-onepage__head {
        display: none;

        .screen-md({ display: block; });
    }
    
    .checkout-onepage__title {
        padding: 0 @spacing-m;
        color: @brand-primary;
    }
}

// steps title styles
.main-container .opc li {
    margin: 0;

    .step-title {

        .number {
            margin-left: @spacing-m;
            color: @brand-primary--light;
            font-style: @font-style-italic;
        }

        h2 {
            color: @brand-primary--light;
            font-style: @font-style-italic;
        }
    }

    &.active {
        margin: 0 0 @spacing-m;

        .step-title {
            padding: @spacing-s 0;
            background-color: @white;
            border-top: 2px solid @color__border--primary;
            border-bottom: 2px solid @color__border--primary;

            .number {
                margin-left: @spacing-m;
                color: @black;
                background: transparent;
                font-style: @font-style-italic;

                &:before {
                    display: inline-block;
                    margin-right: @spacing-s;
                    font-family: FontAwesome;
                    content: '\f00c';
                }
            }

            h2 {
                color: @black;
                font-style: @font-style-italic;
            }
        }

        &.allow {

            .step-title {
                padding: @spacing-m 0 !important;
                border-bottom: 2px solid @color__border--primary !important;

                .number {
                    color: @black !important;
                }

            }

            h2 {
                color: @black !important;
            }
        }
    }

    &:first-child {

        &.allow {

            .step-title {
                padding: @spacing-m 0 @spacing-s;

                .screen-md({ border-top: 2px solid @color__border--primary; });
            }
        }
    }

    &:last-child {

        &.active {

            .step-title {
                padding: 0 0 @spacing-s;
            }
        }
    }

    &.allow {

        .step-title {
            padding: 0 0 @spacing-s;
            border-top: none;
            border-bottom: none;

            .number {
                margin-left: @spacing-m;
                color: @brand-primary--light;
                background: transparent;
                font-style: @font-style-italic;

                &:before {
                    display: inline-block;
                    margin-right: @spacing-s;
                    font-family: FontAwesome;
                    content: '\f00c';
                }
            }

            h2 {
                color: @brand-primary--light;
                font-style: @font-style-italic;
            }
        }
    }
}

// buttons styles
#onepage-guest-register-button {
    .button-cart--primary();
}

.checkout-onepage-index {

    .buttons-set {

        a {
            .transition(color .6ms);

            text-decoration: underline;

            &:active,
            &:focus,
            &:hover {
                color: @brand-primary;
            }
        }

        .button {
            .button-cart--primary();
        }

        .back-link a {
            .button--clear();
        }
    }
}

// inner styles
.main-container .opc li .step {
    border: none;
    border-bottom: 2px solid @brand-primary;

    &#checkout-step-review {
        border-bottom: none;
    }

    .col2-set {
        padding: 0 @spacing-m;
    }

    h3 {
        margin-bottom: @spacing-s !important;
        font-size: @font-size-l !important;
        font-weight: @font-weight-bold !important;
        border-bottom: none !important;
        letter-spacing: @letter-spacing-3xs !important;
        text-transform: uppercase;
    }
}