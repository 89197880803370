/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 26.05.2016
 */

.extra-attributes {
    .display-flex();
    //.justify-content(space-between);

    margin: 0 @spacing-xs;
    padding-top: @spacing-s;
    clear: both;

    .flex-item {
        .flex(1);

        &:before {
            font-family: FontAwesome;
            font-size: @font-size-xl;
            padding-left: @spacing-xs;
        }
    }
}

.extra-attributes--age {

    &:before {
        color: @pink-light;
        content: "\f1ae";
    }

    .extra-attributes--label {
        color: @pink-light;
    }
}

.extra-attributes--technology {

    &:before {
        color: @yellow;
        content: "\f0c4";
    }

    .extra-attributes--label {
        color: @yellow;
    }
}

.extra-attributes--promotion {

    &:before {
        color: @mint-lighter;
        content: "\f0e6";
    }

    .extra-attributes--label {
        color: @mint-lighter;
    }
}

.extra-attributes--label,
.extra-attributes--value {
    font-size: @font-size-s;
    letter-spacing: @letter-spacing-3xs;
    text-transform: uppercase;
}

.product-tabs-topic__h2 {
    display: block !important;
}