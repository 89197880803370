//noinspection ALL
#search_mini_form {
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;
    padding: 10px !important;

    @media (min-width: @screen-sm) {
        width: 700px !important;
        height: auto !important;
        margin: 0 auto !important;
        padding: @spacing-m 0 !important;
        border: none !important;
    }

    .screen-md({ width: 750px !important; padding: @spacing-xl 0 @spacing-l 0 !important; });

    .search-wrapper {
        .display-flex();

        height: 34px;
        text-align: right;

        .form-search {
            display: inline !important;
            width: auto;
            margin-right: 10px;
            padding-right: 0 !important;
            float: left;
            flex: 2;

            @media (min-width: @screen-phone) {
                flex: 5;
            }

            .category-filter {
                display: none;
            }

            input {
                padding: 7px 15px !important;
                font-size: @font-size-m !important;
                background-color: @grey;
                line-height: inherit !important;
                font-style: @font-style-italic;
                color: @black !important;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }

            .search__label {
                font-size: @font-size-m !important;
                position: absolute;
                padding: 10px 15px !important;
            }
        }

    }

    .popular-search {
        display: none;

        @media (min-width: @screen-sm) {
            .display-flex();
        }

        .popular-search__header {
            display: block;
            width: 100%;
            padding: 10px 0;
            color: @grey-dark;

            @media (min-width: @screen-sm) {
                display: inline;
                width: auto;
                padding: 10px 0 0;
            }
        }

        .popular-search__searchwords {
            .display-flex();
            .flex-wrap(wrap);

            margin-bottom: 0;

            @media (min-width: @screen-sm) {
                margin: 10px 0 0 10px;
            }

            li {

                a {
                    //color: @mint;

                    &:active,
                    &:focus,
                    &:hover {
                        //color: darken(@mint, 15%);
                        text-decoration: underline;
                    }
                }

                &:not(:last-child) {

                    &:after {
                        padding: 0 7px 0 5px;
                        color: @black;
                        content: "|";
                    }
                }
            }
        }
    }
}

#navbar-search {
    width: 100%;
    margin-right: 150px;
    color: @black;
    top: 51px !important;

    @media (min-width: @screen-sm) {
        position: fixed;
        left: 0;
        right: 0 !important;
        width: 100% !important;
        margin: 0;
        background: @white;
        border-bottom: 1px solid @grey-dark;
    }
}

//noinspection ALL
#search_mb_mini_form {

    #search_mini_form;

    height: 80px !important;
    padding-top: @spacing-m !important;

    .screen-sm({ padding: @spacing-xl 0 @spacing-l 0 !important; });
}

.switcher-content__search {

    @media (min-width: @screen-md) {
        position: fixed !important;
        top: 91px !important;
        left: 0;
        right: 0 !important;
        width: 100%;
        margin: 0;
        background: @white;
        border-bottom: 1px solid @grey-dark;

        .is-sticky & {
            top: 71px !important;
        }
    }

    @media (min-width: @screen-2lg) {

        .is-sticky & {
            //top: 72px !important;
        }
    }
}