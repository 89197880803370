.messages {

    .screen-sm({ margin: @spacing-m; });
    .screen-md({ margin: @spacing-s @spacing-sm @spacing-m; });

    #messages_product_view & {
        display: block;
        margin: 0 @spacing-m @spacing-m;
    }

    .checkout-cart & {
        display: block;
        margin: @spacing-m;
    }

    li {
        font-size: @font-size-l;
        letter-spacing: @letter-spacing-xs;

        li:before {
            display: inline-block;
            margin-right: @spacing-s;
            font-family: FontAwesome;
        }
    }

    .success-msg {
        color: @brand-primary;

        li:before {
            content: '\f00c';
        }
    }

    .notice-msg {
        color: @brand-secondary;

        li:before {
            content: '\f061';
        }
        
        .checkout-cart & {
            color: @pink;

            li:before {
                content: '\f05c';
            }
        }
    }

    .error-msg {
        color: @pink;

        li:before {
            content: '\f05c';
        }
    }
}
