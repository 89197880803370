/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 05.07.2016
 */

.owl-controls {
    top: 0;
    bottom: auto !important;
    margin: 0 !important;
    height: 100%;

    .product-img-box & {
        position: absolute;
        width: 100%;
    }
}

.owl-pagination {
    bottom: 55px;
    width: 100%;
    z-index: 70;

    .product-img-box & {
        position: absolute;
        bottom: -50px;
    }

    .slide-home & {
        position: absolute;
    }

    .owl-page {

        span {
            width: @spacing-sm !important;
            height: @spacing-sm !important;
            border-radius: 50% !important;
        }
    }
}

.owl-buttons {
    position: relative;
    height: 100%;

    div {
        background: none !important;
        font-size: @font-size-xxxl !important;
    }

    .owl-prev,
    .owl-next {
        position: absolute;
        top: 0;
        right: 0 !important;
        bottom: 0;
        left: 0 !important;
        width: 60px;
        height: 60px;
        margin: auto !important;
    }

    .owl-prev {
        right: auto !important;
        left: 0;
    }

    .owl-next {
        right: 0;
        left: auto !important;
    }
}