button:not(.navbar-toggle) {
  padding: 10px 9px !important;
}
@media (min-width: 480px) {
  button:not(.navbar-toggle) {
    padding: 9px 12px !important;
  }
}
button:not(.navbar-toggle) * {
  font-size: 1rem !important;
  font-weight: 300 !important;
  letter-spacing: 0.01rem;
}
@media (min-width: 480px) {
  button:not(.navbar-toggle) * {
    font-size: 1.25rem !important;
    font-weight: 400 !important;
  }
}
.btn-default {
  line-height: 1.19 !important;
}
.btn-default:hover {
  background-color: #007a6d !important;
}
.button {
  cursor: pointer;
  border: 1px solid transparent !important;
  border-radius: 4px;
  height: 38px;
}
.button:before {
  content: none !important;
}
.button span {
  position: relative;
  padding: 0 3px !important;
  line-height: 1.19 !important;
  vertical-align: inherit !important;
}
.product-boxes__buttons .button span {
  height: auto !important;
  padding: 0 !important;
  line-height: 0.75 !important;
}
.button span span > span {
  display: inline-block;
  height: auto !important;
  padding: 0 !important;
  float: inherit;
  line-height: 0.75 !important;
}
.button span span > span.available,
.button span span > span.available-hover {
  line-height: 1.19 !important;
}
.button--primary {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  background-color: #66ddd1 !important;
}
.button--primary:active,
.button--primary:focus,
.button--primary:hover {
  background-color: #00c6b2 !important;
}
.button--secondary {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  background-color: #ffa3a3 !important;
}
.button--secondary:active,
.button--secondary:focus,
.button--secondary:hover {
  background-color: #ff6666 !important;
}
.button-cart--primary {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  background-color: #ff5f00 !important;
}
.button-cart--primary:active,
.button-cart--primary:focus,
.button-cart--primary:hover {
  background-color: #000000 !important;
}
.button-cart--primary span {
  line-height: 1.19 !important;
  height: auto !important;
}
.button--clear {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  color: #ff5f00;
  text-decoration: none;
  text-transform: uppercase;
}
.button--clear:active,
.button--clear:focus,
.button--clear:hover {
  color: #000000;
}
.mobile-button {
  background-color: transparent !important;
}
.search-button {
  flex: 1;
  margin-left: 0;
  color: #ffffff;
  background-color: #00c6b2;
  border: 1px solid #00c6b2;
  text-transform: uppercase;
}
.search-button:hover {
  color: #ffffff !important;
}
.btn-cart {
  text-transform: uppercase;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 400;
}
@media (min-width: 1024px) {
  .product-type-grouped .btn-cart:hover .available-hover,
  .product-type-grouped .btn-cart:active .available-hover,
  .product-type-grouped .btn-cart:focus .available-hover,
  .product-type-configurable .btn-cart:hover .available-hover,
  .product-type-configurable .btn-cart:active .available-hover,
  .product-type-configurable .btn-cart:focus .available-hover {
    display: block;
  }
  .product-type-grouped .btn-cart:hover .available,
  .product-type-grouped .btn-cart:active .available,
  .product-type-grouped .btn-cart:focus .available,
  .product-type-configurable .btn-cart:hover .available,
  .product-type-configurable .btn-cart:active .available,
  .product-type-configurable .btn-cart:focus .available {
    display: none;
  }
}
.btn-cart .availability {
  padding: 0 !important;
}
.btn-cart .available-hover {
  display: none;
}
.btn-cart .fa {
  position: absolute;
  top: 3px;
  right: 0;
}
.product-type-grouped .btn-cart .fa.fa-shopping-cart,
.product-type-configurable .btn-cart .fa.fa-shopping-cart {
  display: none;
}
.product-type-simple .btn-cart .fa.fa-arrow-right,
.product-type-downloadable .btn-cart .fa.fa-arrow-right {
  display: none;
}
.not-saleable .btn-cart .fa.fa-arrow-right {
  display: block;
}
.btn-cart .price {
  color: inherit !important;
  float: right;
  padding-left: 0 !important;
}
.product-boxes__buttons .btn-cart .price {
  color: #ffffff !important;
}
.btn-cart .minimal-price {
  line-height: inherit;
  margin: inherit;
  text-transform: lowercase;
  display: inline-block;
}
.not-saleable .btn-cart {
  background-color: #cdcdcd !important;
}
.not-saleable .btn-cart .out-of-stock-hover {
  display: none;
}
.not-saleable .btn-cart:hover,
.not-saleable .btn-cart:active,
.not-saleable .btn-cart:focus {
  background-color: #b3b3b3 !important;
}
@media (min-width: 1024px) {
  .not-saleable .btn-cart:hover .out-of-stock-hover,
  .not-saleable .btn-cart:active .out-of-stock-hover,
  .not-saleable .btn-cart:focus .out-of-stock-hover {
    display: block !important;
  }
  .not-saleable .btn-cart:hover .out-of-stock,
  .not-saleable .btn-cart:active .out-of-stock,
  .not-saleable .btn-cart:focus .out-of-stock {
    display: none;
  }
}
.not-saleable .btn-cart .fa-shopping-cart {
  display: none;
}
.btn-cart .hideme,
.btn-cart .old-price-label,
.btn-cart .old-price,
.btn-cart .special-price .price-label {
  display: none;
}
.btn-cart .regular-price,
.btn-cart .special-price {
  display: inline-block;
}
.btn-cart .special-price {
  line-height: inherit;
  margin: 0;
}
.btn-cart .price-box * {
  padding: 0;
}
.btn-cart .price-label {
  margin: 0 3px;
  float: left;
  text-transform: lowercase;
}
.product-view .actions-wrapper .add-to-cart,
.product-view .product-options-bottom .add-to-cart {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border-top: none;
}
@media (min-width: 1024px) {
  .product-view .actions-wrapper .add-to-cart,
  .product-view .product-options-bottom .add-to-cart {
    margin: 0 10px;
  }
}
.product-view .actions-wrapper .add-to-cart .input-box,
.product-view .product-options-bottom .add-to-cart .input-box {
  margin: 5px 10px 10px 0;
}
@media (min-width: 480px) {
  .product-view .actions-wrapper .add-to-cart .input-box,
  .product-view .product-options-bottom .add-to-cart .input-box {
    margin: 5px 10px 5px 0;
  }
}
.product-view .actions-wrapper .add-to-cart .input-box .items,
.product-view .product-options-bottom .add-to-cart .input-box .items {
  color: #000000;
  background-color: #f3f3f3;
}
.product-view .actions-wrapper .add-to-cart .input-box .items:active,
.product-view .product-options-bottom .add-to-cart .input-box .items:active,
.product-view .actions-wrapper .add-to-cart .input-box .items:focus,
.product-view .product-options-bottom .add-to-cart .input-box .items:focus,
.product-view .actions-wrapper .add-to-cart .input-box .items:hover,
.product-view .product-options-bottom .add-to-cart .input-box .items:hover {
  background-color: #000000;
}
.product-view .actions-wrapper .add-to-cart .input-box input,
.product-view .product-options-bottom .add-to-cart .input-box input {
  color: #656565;
  border: 1px solid #656565 !important;
}
.product-view .actions-wrapper .add-to-cart .input-box input:active,
.product-view .product-options-bottom .add-to-cart .input-box input:active,
.product-view .actions-wrapper .add-to-cart .input-box input:focus,
.product-view .product-options-bottom .add-to-cart .input-box input:focus {
  background-color: rgba(0, 198, 178, 0.2) !important;
}
.product-view .actions-wrapper .add-to-cart .addtocart .button-wrapper,
.product-view .product-options-bottom .add-to-cart .addtocart .button-wrapper {
  display: block;
  width: inherit;
  height: auto;
  background-color: transparent;
}
.product-view .actions-wrapper .add-to-cart .addtocart .button-wrapper .btn-cart,
.product-view .product-options-bottom .add-to-cart .addtocart .button-wrapper .btn-cart {
  -moz-transition: background 0.6s !important;
  -webkit-transition: background 0.6s !important;
  -o-transition: background 0.6s !important;
  -ms-transition: background 0.6s !important;
  transition: background 0.6s !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 10px 20px !important;
  font-size: 1.25rem;
  line-height: 1;
  background: #ff5f00;
  border: none;
}
.product-view .actions-wrapper .add-to-cart .addtocart .button-wrapper .btn-cart:active,
.product-view .product-options-bottom .add-to-cart .addtocart .button-wrapper .btn-cart:active,
.product-view .actions-wrapper .add-to-cart .addtocart .button-wrapper .btn-cart:focus,
.product-view .product-options-bottom .add-to-cart .addtocart .button-wrapper .btn-cart:focus,
.product-view .actions-wrapper .add-to-cart .addtocart .button-wrapper .btn-cart:hover,
.product-view .product-options-bottom .add-to-cart .addtocart .button-wrapper .btn-cart:hover {
  background: #000000;
}
.product-view .actions-wrapper .add-to-cart .addtocart .button-wrapper .btn-cart span,
.product-view .product-options-bottom .add-to-cart .addtocart .button-wrapper .btn-cart span {
  height: auto;
  padding: 0 0 1px;
  font-weight: 400 !important;
  line-height: 1;
  vertical-align: middle;
}
.product-view .actions-wrapper .add-to-cart .addtocart .button-wrapper .btn-cart span .fa,
.product-view .product-options-bottom .add-to-cart .addtocart .button-wrapper .btn-cart span .fa {
  margin: 0 0 0 10px;
  padding: 0 !important;
  float: right;
}
.product-view .actions-wrapper .add-to-cart .wishlist,
.product-view .product-options-bottom .add-to-cart .wishlist {
  order: 3;
  width: 100%;
}
@media (min-width: 480px) {
  .product-view .actions-wrapper .add-to-cart .wishlist,
  .product-view .product-options-bottom .add-to-cart .wishlist {
    width: auto;
    margin-left: 10px;
  }
}
.product-view .actions-wrapper .add-to-cart .wishlist .add-to-links,
.product-view .product-options-bottom .add-to-cart .wishlist .add-to-links {
  margin-bottom: 0;
}
.product-view .actions-wrapper .add-to-cart .wishlist .add-to-links li,
.product-view .product-options-bottom .add-to-cart .wishlist .add-to-links li {
  border-right: none;
}
.product-view .actions-wrapper .add-to-cart .wishlist .add-to-links li .link-wishlist,
.product-view .product-options-bottom .add-to-cart .wishlist .add-to-links li .link-wishlist {
  background-color: transparent;
}
.product-view .actions-wrapper .add-to-cart .wishlist .add-to-links li .link-wishlist:before,
.product-view .product-options-bottom .add-to-cart .wishlist .add-to-links li .link-wishlist:before {
  position: relative;
  top: auto;
  bottom: auto;
  border-right: none;
}
.product-view .actions-wrapper .add-to-cart .wishlist .add-to-links li .link-wishlist .fa,
.product-view .product-options-bottom .add-to-cart .wishlist .add-to-links li .link-wishlist .fa {
  padding: 10px 0;
  color: #b6b6b6;
  font-size: 1.953rem;
}
.product-view .actions-wrapper .add-to-cart .wishlist .add-to-links li .link-wishlist .fa:active,
.product-view .product-options-bottom .add-to-cart .wishlist .add-to-links li .link-wishlist .fa:active,
.product-view .actions-wrapper .add-to-cart .wishlist .add-to-links li .link-wishlist .fa:focus,
.product-view .product-options-bottom .add-to-cart .wishlist .add-to-links li .link-wishlist .fa:focus,
.product-view .actions-wrapper .add-to-cart .wishlist .add-to-links li .link-wishlist .fa:hover,
.product-view .product-options-bottom .add-to-cart .wishlist .add-to-links li .link-wishlist .fa:hover {
  color: #00c6b2;
}
.product-view .actions-wrapper .add-to-cart .add-to-cart__label--pdf,
.product-view .product-options-bottom .add-to-cart .add-to-cart__label--pdf {
  margin-right: 20px;
  color: #b6b6b6;
  font-size: 1.953rem;
}
@media (min-width: 1024px) {
  .product-view .product-options-bottom .add-to-cart {
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .product-shop--pdf .add-to-cart {
    padding: 0 10px !important;
  }
}
input,
.chosen-container-single {
  font-size: 1rem;
  letter-spacing: 0.01rem;
  border-radius: 0 !important;
}
input[type="password"],
input[type="text"],
.chosen-container-single,
.qty {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 30px !important;
  font-style: italic;
  border: 1px solid #00c6b2 !important;
  box-shadow: none !important;
}
.field__select-box {
  display: block;
  margin-bottom: 20px;
}
.field__select-box .chosen-container-single {
  height: 40px;
}
/* Remove controls from Firefox */
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
/* Remove controls from Safari and Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
  /* Removes leftover margin */
}
.qty {
  padding: 0 !important;
  height: 38px;
  width: 41px !important;
  margin-bottom: 0 !important;
  font-size: 1rem;
  font-style: normal;
  text-align: center;
}
.qty:active,
.qty:focus,
.qty:hover {
  border: 1px solid #00ad9b !important;
  background-color: rgba(0, 198, 178, 0.2) !important;
}
.checkout-onepage__control-list input[type=radio],
.checkout-onepage__payment-list input[type=radio],
.checkout-onepage__list input[type=radio] {
  display: none;
}
input[type=radio] + label .radio-button {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  bottom: -2px;
  margin-right: 5px;
  padding: 6px;
  border: 1px solid #000000;
}
input[type=radio]:checked + label .radio-button:before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-50%) translatey(-50%) !important;
  -moz-transform: translate(-50%) translatey(-50%) !important;
  -ms-transform: translate(-50%) translatey(-50%) !important;
  -o-transform: translate(-50%) translatey(-50%) !important;
  transform: translate(-50%) translatey(-50%) !important;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background: #000000;
  content: '';
}
.checkout-onepage__control-list input[type=checkbox],
.checkout-onepage__payment-list input[type=checkbox],
input[type=checkbox] .checkout-onepage__list {
  display: none;
}
input[type=checkbox] + label .checkbox-button {
  display: inline-block;
  position: relative;
  bottom: -2px;
  margin-right: 5px;
  padding: 6px;
  border: 1px solid #000000;
}
input[type=checkbox]:checked + label .checkbox-button:before {
  -webkit-transform: translate(-50%) translatey(-50%) !important;
  -moz-transform: translate(-50%) translatey(-50%) !important;
  -ms-transform: translate(-50%) translatey(-50%) !important;
  -o-transform: translate(-50%) translatey(-50%) !important;
  transform: translate(-50%) translatey(-50%) !important;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background: #000000;
  content: '';
}
p.required {
  float: left;
  color: #656565 !important;
  font-size: 0.8rem;
  font-style: italic !important;
  letter-spacing: 0.05rem;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #656565;
  letter-spacing: 0.05rem;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #656565;
  letter-spacing: 0.05rem;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #656565;
  letter-spacing: 0.05rem;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #656565;
  letter-spacing: 0.05rem;
}
h1,
.h1 {
  font-size: 1.953rem;
  font-weight: 400;
}
h2,
.h2 {
  font-size: 1.563rem;
}
h3,
.h3 {
  font-size: 1.25rem;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-size: 1rem;
}
p,
.description--default {
  color: #656565;
  font-size: 1rem;
  letter-spacing: 0.01rem;
  line-height: 1.39;
}
a {
  outline: 0 !important;
}
/**
*
 * collapse main styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 31.05.16
 *
 */
.button--collapse {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  background: #ffffff;
  color: #333333;
  font-size: 1.25rem;
  border-top: 1px solid #f3f3f3;
  text-align: center;
}
@media (min-width: 1024px) {
  .button--collapse {
    display: none;
  }
}
.button--collapse .fa {
  -moz-transition: transform 0.6s !important;
  -webkit-transition: transform 0.6s !important;
  -o-transition: transform 0.6s !important;
  -ms-transition: transform 0.6s !important;
  transition: transform 0.6s !important;
  -webkit-transform: rotate(-180deg) !important;
  -moz-transform: rotate(-180deg) !important;
  -ms-transform: rotate(-180deg) !important;
  -o-transform: rotate(-180deg) !important;
  transform: rotate(-180deg) !important;
}
.button--collapse.collapsed .fa {
  -moz-transition: transform 0.6s !important;
  -webkit-transition: transform 0.6s !important;
  -o-transition: transform 0.6s !important;
  -ms-transition: transform 0.6s !important;
  transition: transform 0.6s !important;
  -webkit-transform: rotate(0deg) !important;
  -moz-transform: rotate(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  -o-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}
.list__collapse {
  order: 3;
  width: 100%;
}
.list__collapse .configurable-optionlist {
  margin: 0;
}
@media (min-width: 1024px) {
  .list__collapse {
    display: block !important;
    height: auto !important;
  }
}
/**
 *
 * all table default styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 08.06.16
 *
 */
table {
  margin: 0 0 20px;
  border: none !important;
  border-color: transparent !important;
}
thead {
  background: #f9f9f9;
  border-bottom: 1px solid #dcdcdc !important;
}
#shopping-cart-table thead {
  background: transparent;
}
#shopping-cart-table thead td {
  padding: 10px 0;
}
th {
  padding: 15px 10px !important;
  color: #000000 !important;
  font-weight: 400 !important;
  letter-spacing: 0.01rem;
  line-height: 1.19 !important;
  text-align: unset !important;
}
th.a-left {
  text-align: left !important;
}
tbody tr {
  border-bottom: 1px solid #dcdcdc !important;
}
tbody tr td {
  padding: 15px 10px !important;
  font-size: 0.9rem !important;
}
tbody tr td.a-left {
  text-align: left;
}
tbody tr td.last .cart-price {
  font-weight: 900;
}
tbody .product-name {
  margin: 0 !important;
  font-weight: 900;
  line-height: 1.39;
  text-align: left;
  text-transform: inherit !important;
}
tfoot tr {
  background: #f9f9f9;
  border-bottom: 1px solid #dcdcdc !important;
}
tfoot tr.table--totals,
tfoot tr.table--tax {
  background: transparent;
}
tfoot tr td {
  padding: 15px 10px !important;
}
tfoot tr td:first-child {
  text-align: left !important;
}
@media (min-width: 768px) {
  tfoot tr td:first-child {
    text-align: right !important;
  }
}
tfoot tr td:first-child span {
  font-style: italic;
}
tfoot tr td a {
  text-decoration: underline;
}
tfoot tr.table--totals {
  border-bottom: none !important;
}
tfoot tr.table--totals td {
  padding: 15px 10px 5px !important;
}
tfoot tr.table--totals span,
tfoot tr.table--totals strong {
  color: #ff5f00;
  font-size: 1.953rem;
  font-weight: 400 !important;
  letter-spacing: 0.05rem;
}
tfoot tr.table--tax {
  padding: 0 10px;
  font-style: italic;
  font-weight: 400;
  border-bottom: none !important;
}
tfoot tr.table--tax td {
  padding: 0 10px !important;
  color: #656565;
}
/**
*
 * main validation styles for all forms
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 22.06.16
 *
 */
.validation-advice {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  top: 36px;
  padding: 10px !important;
  color: #ffffff !important;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  background: #ff6666;
  border: none;
}
.validation-advice:before {
  margin-right: 10px;
  color: #ffffff;
}
.validation-advice:after {
  border-bottom-color: #ff6666;
}
.main-container .opc li ul.form-list li input.validation-failed {
  border: 1px solid #ff6666 !important;
}
/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 05.07.2016
 */
.owl-controls {
  top: 0;
  bottom: auto !important;
  margin: 0 !important;
  height: 100%;
}
.product-img-box .owl-controls {
  position: absolute;
  width: 100%;
}
.owl-pagination {
  bottom: 55px;
  width: 100%;
  z-index: 70;
}
.product-img-box .owl-pagination {
  position: absolute;
  bottom: -50px;
}
.slide-home .owl-pagination {
  position: absolute;
}
.owl-pagination .owl-page span {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
}
.owl-buttons {
  position: relative;
  height: 100%;
}
.owl-buttons div {
  background: none !important;
  font-size: 2.441rem !important;
}
.owl-buttons .owl-prev,
.owl-buttons .owl-next {
  position: absolute;
  top: 0;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  width: 60px;
  height: 60px;
  margin: auto !important;
}
.owl-buttons .owl-prev {
  right: auto !important;
  left: 0;
}
.owl-buttons .owl-next {
  right: 0;
  left: auto !important;
}
.cms-index-index .main-container .main-wrapper {
  margin-top: 130px;
}
.cat-label {
  font-family: Lato, arial, serif;
}
.header-wrapper .header-container .header-search {
  position: inherit !important;
}
@media (min-width: 768px) {
  .header-wrapper .header-container .header-search {
    bottom: -105px;
  }
}
@media (min-width: 1024px) {
  .header-wrapper .header-container .header-search {
    position: relative !important;
  }
}
.header-wrapper .header-container .header-search .search-container {
  position: inherit !important;
  height: 100%;
}
.header-wrapper .header-container .sticky-wrapper .header-maincart {
  border-left: 1px solid #dcdcdc;
}
.header-wrapper .header-container .sticky-wrapper .header-maincart .cart-container {
  width: auto !important;
  line-height: 0 !important;
  font-size: 1.25rem;
  height: 100%;
}
@media (min-width: 1025px) {
  .right-header-menu {
    right: 10px !important;
  }
}
@media (min-width: 1025px) {
  .is-sticky .right-header-menu {
    right: 5px !important;
  }
}
.right-header-menu .header-setting {
  width: auto !important;
}
.right-header-menu .overwrite-setting {
  line-height: 0 !important;
  font-size: 1.25rem !important;
}
@media (min-width: 1025px) {
  .right-header-menu .overwrite-setting {
    width: auto !important;
  }
}
.right-header-menu .overwrite-setting i {
  padding: 17px 20px !important;
}
@media (min-width: 1025px) {
  .right-header-menu .overwrite-setting i {
    padding: 35px 15px !important;
  }
}
@media (min-width: 1200px) {
  .right-header-menu .overwrite-setting i {
    padding: 35px 25px !important;
  }
}
@media (min-width: 1601px) {
  .right-header-menu .overwrite-setting i {
    padding: 35px !important;
  }
}
@media (min-width: 1025px) {
  .is-sticky .right-header-menu .overwrite-setting i {
    padding: 25px 15px !important;
  }
}
@media (min-width: 1200px) {
  .is-sticky .right-header-menu .overwrite-setting i {
    padding: 25px !important;
  }
}
@media (min-width: 1024px) {
  .header-wrapper .header-container .right-header-menu .header-search {
    width: auto !important;
  }
}
.header-wrapper .header-container .header-maincart,
.header-wrapper .header-container .header-setting {
  width: auto !important;
}
.icon-cart-header {
  color: inherit;
}
.icon-cart-header:hover {
  color: inherit;
}
/**
*
 * global styles, not ui
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 28.05.16
 *
 */
.product-sale-label {
  display: inline-block;
  top: 10px !important;
  right: auto !important;
  left: 10px;
  padding: 10px 14px !important;
  color: #000000;
  font-size: 13px !important;
  background-color: #f2e500;
  border-radius: 50%;
}
.catalog-product-view .product-sale-label {
  margin: 10px;
  font-weight: 900;
}
@media (min-width: 1024px) {
  .product-shop-wrapper .product-sale-label {
    float: left;
  }
}
.label-square {
  position: absolute;
  top: auto !important;
  bottom: 0;
  padding: 5px 10px !important;
  color: #000000;
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  z-index: 6;
}
.product-new-label {
  position: absolute;
  top: auto !important;
  bottom: 0;
  padding: 5px 10px !important;
  color: #000000;
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  z-index: 6;
  left: 0;
  background-color: #f2e500;
}
.product-choice-info {
  position: absolute;
  top: auto !important;
  bottom: 0;
  padding: 5px 10px !important;
  color: #000000;
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  z-index: 6;
  right: 0;
  background: #ffffff;
}
.tag-product-list .row > .col-lg-3 {
  display: none;
  width: 0 !important;
}
.tag-product-list .row > .col-lg-6 {
  width: 100% !important;
}
#v-cookielaw .v-message {
  text-align: center;
}
#main-header-sticky-wrapper:not(.is-sticky) .main-header,
#mobile-sticky-sticky-wrapper:not(.is-sticky) .main-header {
  position: absolute;
  border-bottom: 1px solid #b3b3b3;
}
#main-header {
  border-bottom: 1px solid #b3b3b3;
  z-index: 99 !important;
}
#mobile-sticky {
  border-bottom: 1px solid #b3b3b3;
}
.main-header * {
  -moz-transition: inherit !important;
  -webkit-transition: inherit !important;
  -o-transition: inherit !important;
  -ms-transition: inherit !important;
  transition: inherit !important;
}
@media (min-width: 1601px) {
  #main-header-sticky-wrapper .logo img {
    max-height: 70px;
  }
}
@media (min-width: 1025px) {
  #main-header-sticky-wrapper.is-sticky .logo img {
    height: auto;
    max-width: 140px;
    margin: 5px 10px 5px 0;
  }
}
@media (min-width: 1601px) {
  #main-header-sticky-wrapper.is-sticky .logo img {
    max-width: 160px;
    margin: 5px 10px;
  }
}
@media (min-width: 1024px) {
  #main-header-sticky-wrapper.is-sticky .logo {
    margin-top: -5px !important;
  }
}
@media (min-width: 1601px) {
  #main-header-sticky-wrapper.is-sticky .logo {
    margin: 0 0 0 5px !important;
  }
}
#main-header-sticky-wrapper .main-header {
  box-shadow: none;
}
.header-logo.pull-left.visible-xs .header__additional-menu {
  display: none;
  position: absolute;
  top: 50px;
  width: 100%;
  background: #ffffff;
  border-top: 1px solid #dfdfdf;
  clear: both;
  z-index: 10;
}
.header-logo.pull-left.visible-xs .header__additional-menu.in {
  display: block;
}
.header-logo.pull-left.visible-xs .header__additional-menu .nav-accordion li:before {
  top: 16px;
}
.header-logo.pull-left.visible-xs .header__additional-menu .nav-accordion a {
  width: auto;
  height: auto;
  line-height: 2.75;
}
.header-logo.pull-left.visible-xs ul.nav-accordion {
  width: 100%;
}
.header-logo.pull-left.visible-xs .logo {
  position: relative;
  cursor: pointer;
}
.header-logo.pull-left.visible-xs .accordion-toggle {
  line-height: 40px;
}
.header-logo.pull-left.visible-xs .icon__submenu {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  color: #ff5f00;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  margin: 15px 0 0 5px;
  padding: 0 !important;
  line-height: 1;
  background: transparent;
  border: none !important;
}
.header-logo.pull-left.visible-xs .icon__submenu:active,
.header-logo.pull-left.visible-xs .icon__submenu:focus,
.header-logo.pull-left.visible-xs .icon__submenu:hover {
  color: #000000;
}
.header-logo.pull-left.visible-xs .icon__submenu i {
  -webkit-transform: rotate(180deg) !important;
  -moz-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  -o-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
  -moz-transition: transform 0.5s !important;
  -webkit-transition: transform 0.5s !important;
  -o-transition: transform 0.5s !important;
  -ms-transition: transform 0.5s !important;
  transition: transform 0.5s !important;
  color: #00c6b2;
  font-size: 1.953rem !important;
}
.header-logo.pull-left.visible-xs .icon__submenu.collapsed i {
  -webkit-transform: rotate(0deg) !important;
  -moz-transform: rotate(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  -o-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
  -moz-transition: transform 0.5s !important;
  -webkit-transition: transform 0.5s !important;
  -o-transition: transform 0.5s !important;
  -ms-transition: transform 0.5s !important;
  transition: transform 0.5s !important;
}
.top-navigation li {
  -moz-transition: inherit !important;
  -webkit-transition: inherit !important;
  -o-transition: inherit !important;
  -ms-transition: inherit !important;
  transition: inherit !important;
}
.top-navigation li.level0.active a.level-top:after {
  width: 100% !important;
}
.top-navigation li.level0:focus.active .level-top:after,
.top-navigation li.level0:hover.active .level-top:after {
  content: '';
  display: block;
  height: 6px;
  width: 100% !important;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}
.top-navigation li.level0:focus .menu-wrap-sub,
.top-navigation li.level0:hover .menu-wrap-sub {
  display: block;
  z-index: 99;
}
.top-navigation li.level0.parent:not(.custom-block) > a span:after {
  content: "\F0DD";
  position: relative;
  font-family: FontAwesome;
  padding-left: 8px;
  vertical-align: top;
  top: -2px;
}
.top-navigation li a.level-top {
  padding: 0;
  margin: 0;
}
.top-navigation li a.level-top:after {
  height: 6px !important;
}
.top-navigation li a.level-top:hover:after {
  display: none;
}
.top-navigation li a.level-top span {
  padding: 0 10px;
  font-weight: 900;
  letter-spacing: 0.2rem;
  font-size: 0.8rem;
}
@media (min-width: 1200px) {
  .top-navigation li a.level-top span {
    font-size: 1rem !important;
  }
}
.top-navigation li.nav-4 a span,
.top-navigation li.nav-5 a span {
  color: #00c6b2;
}
.top-navigation li.nav-4 a span:hover,
.top-navigation li.nav-5 a span:hover {
  color: #007a6d;
}
.top-navigation .menu-wrap-sub {
  display: none;
  padding: 0 !important;
  left: 0 !important;
  border: 1px solid #b3b3b3 !important;
  box-shadow: none !important;
}
.top-navigation .menu-wrap-sub li.level1.groups {
  height: 185px;
  width: 23%;
  margin-bottom: 25px;
}
.top-navigation .menu-wrap-sub li.level1.groups span.title_group {
  padding: 0 15px;
  text-transform: uppercase;
  font-family: Lato, arial, serif;
  font-weight: 400;
  letter-spacing: 0.1rem;
  font-size: 1rem;
}
.top-navigation .menu-wrap-sub li.level1.groups.first {
  margin-right: 2%;
}
.top-navigation .menu-wrap-sub li.level1.groups > a {
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
  padding-bottom: 0;
  float: none;
  color: #00c6b2;
  font-weight: 400;
  border: 0;
  text-transform: none;
}
.top-navigation .menu-wrap-sub li.level1.groups > a:active,
.top-navigation .menu-wrap-sub li.level1.groups > a:focus,
.top-navigation .menu-wrap-sub li.level1.groups > a:hover {
  color: #007a6d;
}
.top-navigation .menu-wrap-sub li.level1.groups .level2.menu-wrap-sub {
  visibility: hidden;
}
.top-navigation .menu-wrap-sub li.level1.groups .level2 a {
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
  width: 100%;
  color: #cdcdcd;
}
.top-navigation .menu-wrap-sub li.level1.groups .level2 a:active span,
.top-navigation .menu-wrap-sub li.level1.groups .level2 a:focus span,
.top-navigation .menu-wrap-sub li.level1.groups .level2 a:hover span {
  color: #00c6b2;
}
.top-navigation .menu-wrap-sub li.level1.groups .level2 a:active span:after,
.top-navigation .menu-wrap-sub li.level1.groups .level2 a:focus span:after,
.top-navigation .menu-wrap-sub li.level1.groups .level2 a:hover span:after {
  text-decoration: none;
}
.top-navigation .menu-wrap-sub li .link__all-sub-categories a {
  width: 100%;
  font-size: 0.8rem;
}
.top-navigation .menu-wrap-sub li .link__all-sub-categories a:active span,
.top-navigation .menu-wrap-sub li .link__all-sub-categories a:focus span,
.top-navigation .menu-wrap-sub li .link__all-sub-categories a:hover span {
  text-decoration: underline;
}
.top-navigation .menu-wrap-sub li .link__all-sub-categories a span {
  font-size: 0.8rem !important;
  font-weight: 400;
  text-transform: uppercase;
  color: #00c6b2 !important;
}
.top-navigation .menu-wrap-sub li .menu-wrapper li span {
  padding: 0 15px;
  width: 100%;
  color: #000000;
  font-size: 1rem;
  letter-spacing: 0.05rem;
}
.top-navigation .menu-wrap-sub li .menu-wrapper li span.link__all-sub-categories {
  color: #00c6b2 !important;
}
.top-navigation .menu-wrap-sub li .menu-wrapper li.first {
  margin-top: 0;
}
.top-navigation .menu-wrap-sub li .menu-wrapper a:before {
  display: none !important;
}
.top-navigation .menu-wrap-sub .ulmenu-block.ulmenu-block-right {
  padding: 0;
  margin-right: 0;
  position: absolute;
  right: 0;
  height: 100%;
}
.top-navigation .menu-wrap-sub .ulmenu-block.ulmenu-block-center {
  padding: 25px 10px 0 10px;
}
.right-header-menu {
  height: 100%;
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
}
.right-header-menu .right-header-menu__top-icon.fa-shopping-cart {
  position: relative;
}
@media (min-width: 1024px) {
  .right-header-menu .right-header-menu__top-icon {
    font-size: 1.563rem;
  }
}
.right-header-menu .right-header-menu__item {
  cursor: pointer;
  border: none !important;
}
.right-header-menu .right-header-menu__item.skip-active:not(.icon-cart-header),
.right-header-menu .right-header-menu__item .skip-active:not(.icon-cart-header) {
  background-color: #00c6b2;
  color: #ffffff !important;
}
.right-header-menu .right-header-menu__item.skip-active:not(.icon-cart-header) .overwrite-setting,
.right-header-menu .right-header-menu__item .skip-active:not(.icon-cart-header) .overwrite-setting {
  color: #ffffff !important;
}
@media (min-width: 1024px) {
  .right-header-menu .right-header-menu__item.skip-active:not(.icon-cart-header) .overwrite-setting,
  .right-header-menu .right-header-menu__item .skip-active:not(.icon-cart-header) .overwrite-setting {
    background-color: #ffffff;
    color: #00c6b2 !important;
  }
}
@media (max-width: 1024px) {
  .right-header-menu .right-header-menu__item.skip-active,
  .right-header-menu .right-header-menu__item .skip-active {
    background-color: #00c6b2;
    color: #ffffff !important;
  }
  .right-header-menu .right-header-menu__item.skip-active .overwrite-setting,
  .right-header-menu .right-header-menu__item .skip-active .overwrite-setting {
    color: #ffffff !important;
  }
}
.right-header-menu .right-header-menu__item a:active,
.right-header-menu .right-header-menu__item a:focus,
.right-header-menu .right-header-menu__item a:hover {
  text-decoration: underline;
}
.right-header-menu .setting-switcher {
  height: 100%;
}
.right-header-menu .header-search {
  order: 1;
}
.right-header-menu .links a:before {
  display: none !important;
}
.right-header-menu .header-setting {
  order: 4;
}
.right-header-menu .header-setting.header-service {
  float: none !important;
  border-right: none !important;
  order: 2;
}
.right-header-menu .header-setting.header-service .overwrite-setting span:before {
  content: "\f059" !important;
}
.right-header-menu .header-setting ul.service__footer {
  background-color: #f3f3f3;
}
.right-header-menu .header-setting ul.service__footer li {
  padding: 10px 10px;
  float: left;
}
.right-header-menu .header-setting ul.service__footer li i {
  font-size: 1.953rem;
}
.right-header-menu .header-setting .switcher-content {
  top: auto;
}
.right-header-menu .header-setting .switcher__service {
  position: absolute;
  padding: 0 !important;
  overflow: visible !important;
}
.right-header-menu .header-setting .switcher__service ul.links {
  margin-bottom: 0;
  padding: 20px;
}
.right-header-menu .header-setting .switcher__service .service__footer {
  width: 100%;
  margin: 0;
  padding: 0 20px;
  display: inline-flex;
}
.right-header-menu .header-setting .switcher__service .service__footer li a {
  font-size: 1rem !important;
  font-weight: 400;
  letter-spacing: 0.1rem;
}
.right-header-menu .header-setting .switcher__service .service__footer li:first-child {
  padding-left: 0;
}
.right-header-menu .header-setting .switcher__service .service__footer i {
  color: #b3b3b3;
}
.right-header-menu .header-setting .switcher__service .service__footer i:hover {
  color: #00c6b2;
}
.right-header-menu .header-setting #switcher__user-menu ul {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}
.right-header-menu .header-maincart {
  order: 5;
}
@media (min-width: 768px) {
  .right-header-menu .header-maincart {
    border-left: none !important;
  }
}
.right-header-menu .header-maincart .icon_bag_alt:before {
  content: "\f07a";
}
.right-header-menu .header-maincart .navbar__cart--header {
  width: 100%;
  background-color: #f3f3f3;
  text-align: center;
  text-transform: uppercase;
  line-height: 45px;
}
.right-header-menu .header-maincart .navbar__cart--header .subtotal {
  color: #ff5f00;
  font-weight: 400;
  font-size: 1.25rem;
}
.right-header-menu .header-maincart .navbar__cart--link:active,
.right-header-menu .header-maincart .navbar__cart--link:focus,
.right-header-menu .header-maincart .navbar__cart--link:hover {
  text-decoration: none;
}
.right-header-menu .header-maincart .navbar__cart--link .label {
  color: #ff5f00;
  font-size: 1.25rem;
}
.right-header-menu .header-maincart .navbar__cart--link .price {
  color: #ff5f00;
  font-weight: 900;
}
.right-header-menu .header-maincart .minicart-qty {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  width: 30px;
  margin: 0 auto;
  color: #ff5f00;
  font-family: Lato, arial, serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
}
@media (min-width: 1025px) {
  .right-header-menu .header-maincart .minicart-qty {
    top: 20px;
    font-size: 1rem;
  }
}
@media (min-width: 1025px) {
  .is-sticky .right-header-menu .header-maincart .minicart-qty {
    top: 10px;
  }
}
.right-header-menu .header-maincart .cart-checkout {
  padding: 10px !important;
}
.right-header-menu .header-maincart .cart-checkout .btn-button {
  cursor: pointer;
  border: 1px solid transparent !important;
  border-radius: 4px;
  height: 38px;
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  background-color: #ff5f00 !important;
  width: 100%;
  margin: 0 0 5px !important;
  font-size: 1.25rem !important;
  letter-spacing: 0.05rem;
  line-height: 2.19;
  text-transform: uppercase;
}
.right-header-menu .header-maincart .cart-checkout .btn-button:before {
  content: none !important;
}
.right-header-menu .header-maincart .cart-checkout .btn-button span {
  position: relative;
  padding: 0 3px !important;
  line-height: 1.19 !important;
  vertical-align: inherit !important;
}
.product-boxes__buttons .right-header-menu .header-maincart .cart-checkout .btn-button span {
  height: auto !important;
  padding: 0 !important;
  line-height: 0.75 !important;
}
.right-header-menu .header-maincart .cart-checkout .btn-button span span > span {
  display: inline-block;
  height: auto !important;
  padding: 0 !important;
  float: inherit;
  line-height: 0.75 !important;
}
.right-header-menu .header-maincart .cart-checkout .btn-button span span > span.available,
.right-header-menu .header-maincart .cart-checkout .btn-button span span > span.available-hover {
  line-height: 1.19 !important;
}
.right-header-menu .header-maincart .cart-checkout .btn-button:active,
.right-header-menu .header-maincart .cart-checkout .btn-button:focus,
.right-header-menu .header-maincart .cart-checkout .btn-button:hover {
  background-color: #000000 !important;
}
.right-header-menu .header-maincart .cart-checkout .btn-button span {
  line-height: 1.19 !important;
  height: auto !important;
}
.right-header-menu .header-maincart .cart-checkout .btn-button .fa:before {
  margin: 0 5px;
  font-weight: 900 !important;
}
.right-header-menu .header-maincart .cart-content {
  width: 100% !important;
}
.right-header-menu .header-maincart .cart-content li.item {
  border-bottom-color: #696969 !important;
  padding: 0 0 3px !important;
  margin: 0 0 5px !important;
}
.right-header-menu .header-maincart .cart-content .product-details {
  width: 0;
  font-size: 0.9rem;
  font-weight: 400 !important;
  color: #000000;
}
.right-header-menu .header-maincart .cart-content .product-details strong {
  font-weight: 400;
}
.right-header-menu .header-maincart .cart-content .product-details .product-name {
  letter-spacing: normal;
  font-size: 0.9rem;
  font-weight: 900 important;
  padding: 0 0 5px !important;
}
.right-header-menu .header-maincart .cart-content .product-details .product-name a {
  text-transform: none !important;
  font-weight: 900 !important;
}
.right-header-menu .header-maincart .cart-content .product-details .price {
  color: #000000 !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}
.right-header-menu .header-maincart .cart-content .product-details .fa {
  color: #696969;
  font-weight: 300;
}
.right-header-menu .header-maincart .cart-content .product-details .fa:hover {
  color: #000000;
}
.right-header-menu .header-maincart .cart-content .product-details dd,
.right-header-menu .header-maincart .cart-content .product-details dt {
  text-transform: none !important;
}
.right-header-menu div.header-search:hover,
.right-header-menu div.header-setting:hover,
.right-header-menu div.header-maincart:hover,
.right-header-menu div.header-search .search-container .overwrite-setting:hover {
  color: #ffffff !important;
  background-color: #00c6b2;
}
.right-header-menu div.header-search:hover .switcher-content,
.right-header-menu div.header-setting:hover .switcher-content,
.right-header-menu div.header-maincart:hover .switcher-content,
.right-header-menu div.header-search .search-container .overwrite-setting:hover .switcher-content {
  display: none;
}
@media (min-width: 1024px) {
  .right-header-menu div.header-search:hover,
  .right-header-menu div.header-setting:hover,
  .right-header-menu div.header-maincart:hover,
  .right-header-menu div.header-search .search-container .overwrite-setting:hover {
    background-color: #ffffff;
    color: #00c6b2 !important;
  }
}
.right-header-menu div.header-search:hover .overwrite-setting span,
.right-header-menu div.header-setting:hover .overwrite-setting span,
.right-header-menu div.header-maincart:hover .overwrite-setting span,
.right-header-menu div.header-search .search-container .overwrite-setting:hover .overwrite-setting span,
.right-header-menu div.header-search:hover .icon_bag_alt,
.right-header-menu div.header-setting:hover .icon_bag_alt,
.right-header-menu div.header-maincart:hover .icon_bag_alt,
.right-header-menu div.header-search .search-container .overwrite-setting:hover .icon_bag_alt {
  color: #ffffff !important;
}
.right-header-menu .skip-active {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
}
.right-header-menu .popular-search__searchwords .skip-active {
  display: inline !important;
}
.right-header-menu .switcher-content:not(.switcher-content__search),
.right-header-menu #navbar-cart {
  color: #696969;
  width: 290px !important;
}
@media (min-width: 1024px) {
  .right-header-menu .switcher-content:not(.switcher-content__search),
  .right-header-menu #navbar-cart {
    right: 0 !important;
  }
}
.right-header-menu .switcher-content__search {
  background-color: #ffffff !important;
}
.right-header-menu .switcher-content {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
}
.right-header-menu .switcher-content ul.links a {
  font-size: 1rem !important;
  font-weight: 400;
  letter-spacing: 0.1rem;
}
.right-header-menu #navbar-cart {
  width: 100% !important;
  right: 0px !important;
  position: fixed;
}
@media (min-width: 480px) {
  .right-header-menu #navbar-cart {
    width: 290px !important;
  }
}
.right-header-menu ul.links li a span:before {
  content: inherit;
}
.cms-index-index .main-header {
  background-color: #ffffff !important;
}
.cms-index-index .main-header .level-top {
  color: #000000 !important;
}
.cms-index-index .main-header .level-top:hover,
.cms-index-index .main-header .level-top:active,
.cms-index-index .main-header .level-top:focus {
  color: #00c6b2 !important;
}
.cms-index-index .right-header-menu {
  height: 100%;
}
.navbar {
  border-radius: 0;
}
@media (min-width: 1024px) {
  .navbar {
    width: auto !important;
    height: auto !important;
  }
}
.navbar .navbar-header:hover .navbar-toggle .icon-bar {
  border: 1px solid #ffffff !important;
}
.navbar:hover {
  background-color: #00c6b2;
}
.navbar:hover .icon-bar {
  background-color: #ffffff;
}
.navbar .navbar-header {
  height: 100%;
}
.navbar .navbar-header.skip-active {
  background-color: #00c6b2;
  color: #ffffff !important;
}
.navbar .navbar-header.skip-active .icon-bar {
  border: 1px solid #ffffff !important;
}
.navbar .navbar-toggle {
  display: block;
}
@media (min-width: 1024px) {
  .navbar .navbar-toggle {
    padding: 10px !important;
  }
}
.nav-mobile-accordion #categories_nav,
.sidebar .block-nav #categories_nav {
  border-top: 1px solid #00c6b2;
  background-color: #ffffff;
}
.nav-mobile-accordion .block-content,
.sidebar .block-nav .block-content {
  padding: 0 !important;
}
.nav-mobile-accordion li:before,
.sidebar .block-nav li:before {
  display: none;
}
.nav-mobile-accordion .level0:not(ul),
.sidebar .block-nav .level0:not(ul),
.nav-mobile-accordion .level1:not(.last):not(ul),
.sidebar .block-nav .level1:not(.last):not(ul) {
  border-bottom: 1px solid #00c6b2 !important;
}
.nav-mobile-accordion .level0:not(ul) a:hover,
.sidebar .block-nav .level0:not(ul) a:hover,
.nav-mobile-accordion .level1:not(.last):not(ul) a:hover,
.sidebar .block-nav .level1:not(.last):not(ul) a:hover,
.nav-mobile-accordion .level0:not(ul) a:active,
.sidebar .block-nav .level0:not(ul) a:active,
.nav-mobile-accordion .level1:not(.last):not(ul) a:active,
.sidebar .block-nav .level1:not(.last):not(ul) a:active,
.nav-mobile-accordion .level0:not(ul) a:focus,
.sidebar .block-nav .level0:not(ul) a:focus,
.nav-mobile-accordion .level1:not(.last):not(ul) a:focus,
.sidebar .block-nav .level1:not(.last):not(ul) a:focus {
  background-color: #f8f8f8;
}
.nav-mobile-accordion .level0,
.sidebar .block-nav .level0 {
  padding-left: 0;
}
.nav-mobile-accordion .level0.active .level-top,
.sidebar .block-nav .level0.active .level-top {
  background-color: #f8f8f8;
}
.nav-mobile-accordion .level0 .links li:not(.last),
.sidebar .block-nav .level0 .links li:not(.last) {
  border-bottom: 1px solid #b3b3b3 !important;
}
.nav-mobile-accordion ul.level0,
.sidebar .block-nav ul.level0 {
  border-top: 1px solid #00c6b2 !important;
}
.nav-mobile-accordion ul.level1,
.sidebar .block-nav ul.level1 {
  border-top: 1px solid #00c6b2;
}
.nav-mobile-accordion .level2,
.sidebar .block-nav .level2 {
  background-color: #ffffff;
}
.nav-mobile-accordion .level2 .collapse,
.sidebar .block-nav .level2 .collapse {
  display: none;
}
.nav-mobile-accordion .level2:not(.last),
.sidebar .block-nav .level2:not(.last) {
  border-bottom: 1px solid #00c6b2;
}
.nav-mobile-accordion .level2.active a,
.sidebar .block-nav .level2.active a {
  background-color: #f8f8f8;
}
.nav-mobile-accordion ul.level2,
.sidebar .block-nav ul.level2 {
  display: none !important;
}
.cms-index-index .header-wrapper .header-container .navbar .navbar-toggle .icon-bar {
  background-color: #000000 !important;
}
.category-menu__custom-links {
  position: relative;
  top: -20px;
}
.category-menu__custom-links .level0 {
  background-color: #f8f8f8;
}
#navbar-service a,
#navbar-setting a {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
}
.filled-cart {
  color: #ff5f00;
}
.topmenu-registered-user {
  color: #00c6b2;
}
#mobile-sticky .overwrite-setting.skip-active {
  background: #ff5f00 !important;
}
#mobile-sticky .overwrite-setting.skip-active .filled-cart,
#mobile-sticky .overwrite-setting.skip-active .minicart-qty,
#mobile-sticky .overwrite-setting.skip-active .topmenu-registered-user {
  color: #ffffff;
}
#mobile-sticky .overwrite-setting:hover {
  background: #ff5f00 !important;
}
#mobile-sticky .overwrite-setting:hover .filled-cart,
#mobile-sticky .overwrite-setting:hover .minicart-qty,
#mobile-sticky .overwrite-setting:hover .topmenu-registered-user {
  color: #ffffff;
}
#mobile-sticky .navbar .navbar-header.skip-active {
  background: #ff5f00 !important;
}
#mobile-sticky .navbar:hover {
  background: #ff5f00 !important;
}
.header-wrapper .header-container .main-header .right-header-menu .header-setting .setting-switcher .switcher-content,
.header-wrapper .header-container .main-header .right-header-menu .cart-container .cart-wrapper {
  border: 1px solid #b3b3b3 !important;
}
.top-navigation li.level0 ul li a {
  float: none !important;
}
.minicart__additional-info .additionalInfo__entry {
  font-size: 0.9rem;
  letter-spacing: normal;
  text-transform: none;
}
.footer-wrapper {
  margin-top: 60px;
  border-top: none;
}
@media (min-width: 480px) {
  .footer-wrapper {
    margin-top: 80px;
  }
}
.footer-wrapper .flex__newsletter-category-description {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-width: inherit !important;
}
.footer-wrapper #newsletter-input {
  height: 35px;
  color: #00c6b2;
  text-align: center;
  font-style: italic;
}
.footer-wrapper .block-subscribe {
  padding: 45px 0;
  background-color: #f9f9f9;
}
.footer-wrapper .newsletter__subscribe--header,
.footer-wrapper .newsletter__subscribe--email {
  margin-bottom: 20px;
}
.footer-wrapper .newsletter__subscribe--button {
  background-color: #00c6b2 !important;
  color: white !important;
  border: none;
  text-transform: uppercase;
}
.footer-wrapper .category__description--wrapper {
  padding-bottom: 45px;
}
.footer-wrapper .category__description--wrapper .read-more {
  float: inherit;
}
.footer-wrapper .footer-block-title {
  margin: 15px 0;
  text-transform: uppercase;
}
.footer-wrapper .footer-block-title span {
  color: #000000;
}
.footer-wrapper .footer-block-title h1,
.footer-wrapper .footer-block-title h3 {
  font-size: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  line-height: 35px;
}
.footer-wrapper .newsletter-block-title {
  font-size: 1.25rem;
  text-transform: uppercase;
}
.footer-wrapper .newsletter-block-title span {
  color: #000000;
}
.footer-wrapper .footer-list {
  margin: 30px 0 !important;
}
.footer-wrapper .footer__additional-sections .footer__list--entry {
  border: none;
  padding: 10px 0;
}
.footer-wrapper .footer__additional-sections .footer__list--entry:last-of-type {
  border-bottom: none !important;
}
.footer-wrapper .footer__additional-sections .footer__list--entry .footer__list--link {
  -moz-transition: inherit !important;
  -webkit-transition: inherit !important;
  -o-transition: inherit !important;
  -ms-transition: inherit !important;
  transition: inherit !important;
  padding-left: 0 !important;
  color: #000000;
  font-size: 1rem;
}
.footer-wrapper .footer__additional-sections .footer__list--entry .footer__list--link:before {
  display: none !important;
}
.footer-wrapper .footer__additional-sections .footer__list--entry .footer__list--link:hover,
.footer-wrapper .footer__additional-sections .footer__list--entry .footer__list--link:active,
.footer-wrapper .footer__additional-sections .footer__list--entry .footer__list--link:focus {
  text-decoration: none;
  font-weight: 900;
}
.footer-wrapper .footer__third-area--wrapper {
  background-color: #ffffff;
}
.footer-wrapper .footer__third-area {
  padding: 20px 0;
  background-color: #ffffff;
}
.footer-wrapper .footer__third-area .footer__third-area--left .footer__list--entry {
  float: left;
  padding-right: 35px;
}
.footer-wrapper .footer__third-area .footer__third-area--left .footer__third-area--links .footer__list--entry:not(.vat-info) a:hover,
.footer-wrapper .footer__third-area .footer__third-area--left .footer__third-area--links .footer__list--entry:not(.vat-info) a:focus,
.footer-wrapper .footer__third-area .footer__third-area--left .footer__third-area--links .footer__list--entry:not(.vat-info) a:active {
  font-weight: 900;
}
.footer-wrapper .footer__third-area .footer__third-area--left .footer__third-area--links .vat-info {
  font-size: 1rem;
  letter-spacing: 0.05rem;
}
.footer-wrapper .footer__third-area .footer__third-area--left .footer__third-area--links .vat-info a {
  color: #ff8772;
}
.footer-wrapper .footer__third-area .footer__third-area--right .footer__third-area--social-icons {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  font-size: 1.563rem;
}
.footer-wrapper .footer__third-area .footer__third-area--right .footer__third-area--social-icons .footer__third-area--social-icon {
  color: #000000;
}
.footer-wrapper .footer__third-area .footer__third-area--right .footer__third-area--social-icons .footer__third-area--social-icon:hover,
.footer-wrapper .footer__third-area .footer__third-area--right .footer__third-area--social-icons .footer__third-area--social-icon:active,
.footer-wrapper .footer__third-area .footer__third-area--right .footer__third-area--social-icons .footer__third-area--social-icon:focus {
  color: #ff8772;
}
.footer-wrapper .list-footer {
  padding: 0 15px;
}
.footer-wrapper .footer-top {
  background-color: #f9f9f9;
}
.footer-wrapper .footer-top .vat-info {
  display: none;
}
@media (max-width: 767px) {
  .footer-wrapper .footer-top .about-social {
    display: none !important;
  }
}
.footer-wrapper .about__title {
  margin-top: 15px;
  font-size: 1.25rem;
}
.footer-wrapper .footer-bottom {
  padding-top: 0;
}
.footer-wrapper .footer__second-area {
  padding: 0;
}
.footer-wrapper .footer__second-area a {
  font-weight: 900;
}
.footer-wrapper .footer__second-area .footer-links .row {
  border-top: none;
}
.footer-wrapper .footer__second-area .list-footer {
  padding: 0;
}
.footer-wrapper .footer__second-area--contact .fa-envelope-o,
.footer-wrapper .footer__second-area--contact .fa-phone {
  float: left;
  padding-right: 10px;
  margin-top: 2px;
  font-size: 1.25rem;
}
.footer-wrapper .footer__second-area--contact .footer__second-area--contact-slogan,
.footer-wrapper .footer__second-area--contact .footer__second-area--contact-hours {
  display: block;
}
.footer-wrapper .footer__vat-info {
  background-color: #ffffff;
  text-align: center;
}
.footer-wrapper .footer__vat-info li:not(.vat-info) {
  display: none;
}
.footer-wrapper .footer__vat-info .footer-list {
  margin: 0 !important;
}
@media (min-width: 768px) {
  .footer-wrapper {
    background-color: #ffffff;
    overflow: hidden;
  }
  .footer-wrapper .flex__newsletter-category-description {
    display: flex;
    flex-direction: column-reverse;
  }
  .footer-wrapper #newsletter-input {
    height: 35px;
    color: #00c6b2;
    text-align: left;
    font-style: italic;
  }
  .footer-wrapper .newsletter__subscribe--button {
    background-color: #00c6b2 !important;
    color: white !important;
    border: none;
  }
  .footer-wrapper .form-subscribe-header {
    padding-top: 15px;
    overflow: hidden;
    clear: both;
  }
  .footer-wrapper .newsletter__subscribe--image {
    margin-top: -15px;
  }
  .footer-wrapper .category__description--wrapper {
    width: auto;
  }
  .footer-wrapper .category__description--wrapper .read-more {
    float: right;
  }
  .footer-wrapper .footer-top .vat-info {
    display: block;
  }
  .footer-wrapper .footer-top .about-social {
    padding: 0 15px 20px 15px;
  }
  .footer-wrapper .newsletter-block-title {
    font-size: 1.25rem;
    text-transform: uppercase;
  }
  .footer-wrapper .newsletter-block-title span {
    color: #000000;
  }
  .footer-wrapper .footer__additional-sections {
    border-left: none;
  }
  .footer-wrapper .footer__additional-sections .vat-info {
    display: none;
  }
  .footer-wrapper .footer__additional-sections h3 {
    font-size: 1.25rem !important;
    letter-spacing: 0.15rem !important;
  }
  .footer-wrapper .footer__third-area--links .footer__list--entry {
    border-bottom: none;
    padding-right: 10px !important;
  }
  .footer-wrapper .footer__third-area--links .footer__list--entry:not(.vat-info) {
    display: none;
  }
  .footer-wrapper .footer__second-area {
    background-color: #ff8772;
    padding: 45px 0;
  }
  .footer-wrapper .footer__second-area * {
    color: #ffffff !important;
  }
  .footer-wrapper .footer__second-area .footer-links {
    margin: 0;
  }
  .footer-wrapper .footer__second-area .footer-container .column:not(:last-child) {
    padding-right: 50px;
  }
  .footer-wrapper .footer__second-area .footer-container .header {
    text-transform: uppercase;
    font-size: 1.25rem;
  }
  .footer-wrapper .footer__second-area .footer-container .footer__second-area--blog {
    flex: 2;
  }
  .footer-wrapper .footer__second-area .footer-container .footer__second-area--blog .footer__second-area--blog-info-wrapper {
    display: flex;
  }
  .footer-wrapper .footer__second-area .footer-container .footer__second-area--blog .footer__second-area--blog-info-wrapper .footer__second-area--blog-date {
    flex: 1;
  }
  .footer-wrapper .footer__second-area .footer-container .footer__second-area--blog .footer__second-area--blog-info-wrapper .footer__second-area--blog-text {
    flex: 4;
  }
  .footer-wrapper .footer__second-area .footer-container .footer__second-area--payment {
    flex: 1;
  }
  .footer-wrapper .footer__second-area .footer-container .footer__second-area--payment p:last-of-type {
    margin-bottom: 0;
  }
  .footer-wrapper .footer__second-area .footer-container .footer__second-area--payment .footer__second-area--payment-text p:first-of-type {
    clear: both;
  }
  .footer-wrapper .footer__second-area .footer-container .footer__second-area--contact {
    flex: 1;
  }
  .footer-wrapper .footer__second-area .footer-container .footer__second-area--contact .footer__second-area--contact-slogan,
  .footer-wrapper .footer__second-area .footer-container .footer__second-area--contact .footer__second-area--contact-hours {
    display: block;
  }
  .footer-wrapper .footer__second-area .footer-container .icons {
    display: inline-block !important;
    position: relative;
    left: 10px;
    bottom: 1px;
    font-size: 1.25rem;
  }
  .footer-wrapper .footer__third-area--wrapper {
    padding-top: 10px;
  }
  .footer-wrapper .footer__third-area--left {
    padding-top: 10px;
  }
  .footer-wrapper .footer__third-area {
    padding: 0 0 20px 0;
  }
  .footer-wrapper .footer__third-area--social-icons {
    margin-left: 0 !important;
    font-size: 1.25rem !important;
  }
  .footer-wrapper .footer__third-area--social-icons .facebook {
    padding-left: 0;
  }
  .footer-wrapper .footer-bottom {
    padding-top: 30px;
    background-color: #f9f9f9;
  }
  .footer-wrapper .footer-list {
    margin: 0 !important;
  }
  .footer-wrapper .footer__vat-info {
    display: none !important;
  }
  .footer-wrapper .footer-links .row {
    border-top: none;
  }
  .footer-wrapper .footer-links .info-footer:last-child {
    border-bottom: none;
  }
  .footer-wrapper .custom-footer-content {
    display: none;
  }
  .footer-wrapper .about__title {
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 767px) {
  .footer-wrapper .footer__vat-info {
    display: block !important;
  }
  table .footer-wrapper .footer__vat-info {
    display: table;
  }
  tr .footer-wrapper .footer__vat-info {
    display: table-row !important;
  }
  th .footer-wrapper .footer__vat-info,
  td .footer-wrapper .footer__vat-info {
    display: table-cell !important;
  }
}
@media (max-width: 1023px) {
  .footer-wrapper .footer-links .row {
    border-top: 1px dashed #000000;
  }
  .footer-wrapper .footer-links .info-footer {
    border-bottom: 1px dashed #000000;
  }
}
@media (min-width: 992px) {
  .footer-wrapper .about-social {
    width: 58.333333333333336%;
  }
  .footer-wrapper .footer__additional-sections {
    width: 41.66666666666667%;
  }
  .footer-wrapper .footer__additional-sections .info-footer {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .footer-wrapper .about-social {
    width: 50%;
  }
  .footer-wrapper .footer__additional-sections {
    width: 50%;
  }
  .footer-wrapper .footer-block-title {
    margin: 0 0 15px 0 !important;
    font-size: 1.25rem !important;
  }
  .footer-wrapper .footer-list {
    margin: 0 !important;
  }
  .footer-wrapper .footer__list--entry {
    border-bottom: 1px dashed #00c6b2 !important;
    padding: 10px 0;
  }
  .footer-wrapper .footer__list--entry:first-of-type {
    margin-top: 0;
  }
  .footer-wrapper .footer__list--entry .footer__list--link {
    padding-left: 0 !important;
    color: #000000;
    font-size: 1rem !important;
    letter-spacing: 0.05rem !important;
  }
  .footer-wrapper .footer__list--entry .footer__list--link:before {
    display: none !important;
  }
  .footer-wrapper .footer__list--entry .footer__list--link:active {
    border-bottom: none;
  }
  .footer-wrapper .footer__third-area--links .footer__list--entry {
    border-bottom: none !important;
    display: block !important;
  }
  .footer-wrapper .custom-footer-content {
    display: block;
  }
  .footer-wrapper .footer__second-area .footer-container {
    border: none;
  }
  .footer-wrapper .footer__second-area .footer__blog {
    margin-bottom: 10px;
  }
  .footer-wrapper .footer__second-area .read-more {
    text-transform: uppercase;
    text-decoration: none;
    float: left;
  }
  .footer-wrapper .footer__second-area .read-more:after {
    content: "\f178";
    font-family: FontAwesome;
    padding-left: 5px;
  }
  .footer-wrapper .footer__second-area--blog-date {
    padding: unset;
  }
  .footer-wrapper .footer__third-area--left {
    padding-top: 0;
  }
  .footer-wrapper .footer__third-area--copyright {
    padding: 10px 0;
    font-size: 1rem;
    letter-spacing: 0.05rem;
  }
  .footer-wrapper .footer__additional-sections .footer-links {
    margin: unset;
  }
  .footer-wrapper .footer__additional-sections .info-footer {
    width: 50%;
  }
}
.footer-wrapper .footer-container .about-text {
  font-size: 1rem;
  letter-spacing: 0.05rem !important;
}
.footer-wrapper .footer-container .about-text a {
  color: #000000;
  background-color: #00c6b2;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
}
.footer-wrapper .footer__second-area--contact-mail {
  clear: both;
}
.footer-wrapper .footer__third-area--social-link {
  float: left;
  font-size: 1.25rem;
  padding: 5px 15px 0 15px;
  color: #000000;
}
.footer-wrapper .footer__third-area--social-link:hover {
  color: #ff8772 !important;
}
.footer-wrapper .mobile-button {
  background-color: transparent !important;
}
@media (min-width: 768px) {
  #newsletter-validate-detail {
    max-width: 700px;
    margin: 0 auto;
  }
}
@media (min-width: 1024px) {
  #newsletter-validate-detail {
    max-width: 1000px;
  }
}
@media (min-width: 1024px) {
  #newsletter-validate-detail {
    max-width: 1200px;
  }
}
.category__tag-list--wrapper {
  padding: 25px;
  border-left: 1px solid #EFEFEF;
  background-color: #f3f3f3;
  overflow: hidden;
  height: 100%;
}
.category__tag-list--wrapper .catgeory__tag-list--header {
  line-height: normal;
  padding: 10px 0 0 0;
  text-transform: uppercase;
  font-family: Lato, arial, serif;
  font-weight: 400;
  letter-spacing: 0.15rem;
  font-size: 1rem;
  color: #00c6b2;
}
.category__tag-list--wrapper .category__tag-list--list-wrapper {
  overflow: auto;
  max-height: 78%;
}
.category__tag-list--wrapper a.category__tag-list--link {
  padding: 35px 0 0 0;
  line-height: 30px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  color: #00c6b2;
  letter-spacing: 0.1rem;
}
.category__tag-list--wrapper a.category__tag-list--link:active,
.category__tag-list--wrapper a.category__tag-list--link:focus,
.category__tag-list--wrapper a.category__tag-list--link:hover {
  text-decoration: underline;
}
.category__tag-list--wrapper a.category__tag-list--link span {
  padding: 0 !important;
}
.category__tag-list--wrapper li {
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  float: left;
  margin: 10px 10px 0 0 !important;
  background-color: #ffffff !important;
}
.category__tag-list--wrapper li span {
  padding: 3px 10px !important;
  font-size: 1rem;
  letter-spacing: 0.05rem;
}
.category__tag-list--wrapper li:hover {
  background-color: #00c6b2 !important;
  color: #ffffff;
}
.category__tag-list--wrapper li:hover a {
  color: inherit !important;
  color: red;
}
.category__tag-list--wrapper li:hover a span {
  background-color: transparent !important;
}
#search_mini_form {
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: auto !important;
  margin: 0 !important;
  padding: 10px !important;
}
@media (min-width: 768px) {
  #search_mini_form {
    width: 700px !important;
    height: auto !important;
    margin: 0 auto !important;
    padding: 20px 0 !important;
    border: none !important;
  }
}
@media (min-width: 1024px) {
  #search_mini_form {
    width: 750px !important;
    padding: 48px 0 30px 0 !important;
  }
}
#search_mini_form .search-wrapper {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  height: 34px;
  text-align: right;
}
#search_mini_form .search-wrapper .form-search {
  display: inline !important;
  width: auto;
  margin-right: 10px;
  padding-right: 0 !important;
  float: left;
  flex: 2;
}
@media (min-width: 480px) {
  #search_mini_form .search-wrapper .form-search {
    flex: 5;
  }
}
#search_mini_form .search-wrapper .form-search .category-filter {
  display: none;
}
#search_mini_form .search-wrapper .form-search input {
  padding: 7px 15px !important;
  font-size: 1rem !important;
  background-color: #f3f3f3;
  line-height: inherit !important;
  font-style: italic;
  color: #000000 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#search_mini_form .search-wrapper .form-search .search__label {
  font-size: 1rem !important;
  position: absolute;
  padding: 10px 15px !important;
}
#search_mini_form .popular-search {
  display: none;
}
@media (min-width: 768px) {
  #search_mini_form .popular-search {
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flexbox !important;
    display: -ms-flex !important;
    display: flex;
  }
}
#search_mini_form .popular-search .popular-search__header {
  display: block;
  width: 100%;
  padding: 10px 0;
  color: #b3b3b3;
}
@media (min-width: 768px) {
  #search_mini_form .popular-search .popular-search__header {
    display: inline;
    width: auto;
    padding: 10px 0 0;
  }
}
#search_mini_form .popular-search .popular-search__searchwords {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #search_mini_form .popular-search .popular-search__searchwords {
    margin: 10px 0 0 10px;
  }
}
#search_mini_form .popular-search .popular-search__searchwords li a:active,
#search_mini_form .popular-search .popular-search__searchwords li a:focus,
#search_mini_form .popular-search .popular-search__searchwords li a:hover {
  text-decoration: underline;
}
#search_mini_form .popular-search .popular-search__searchwords li:not(:last-child):after {
  padding: 0 7px 0 5px;
  color: #000000;
  content: "|";
}
#navbar-search {
  width: 100%;
  margin-right: 150px;
  color: #000000;
  top: 51px !important;
}
@media (min-width: 768px) {
  #navbar-search {
    position: fixed;
    left: 0;
    right: 0 !important;
    width: 100% !important;
    margin: 0;
    background: #ffffff;
    border-bottom: 1px solid #b3b3b3;
  }
}
#search_mb_mini_form {
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: auto !important;
  margin: 0 !important;
  padding: 10px !important;
  height: 80px !important;
  padding-top: 20px !important;
}
@media (min-width: 768px) {
  #search_mb_mini_form {
    width: 700px !important;
    height: auto !important;
    margin: 0 auto !important;
    padding: 20px 0 !important;
    border: none !important;
  }
}
@media (min-width: 1024px) {
  #search_mb_mini_form {
    width: 750px !important;
    padding: 48px 0 30px 0 !important;
  }
}
#search_mb_mini_form .search-wrapper {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  height: 34px;
  text-align: right;
}
#search_mb_mini_form .search-wrapper .form-search {
  display: inline !important;
  width: auto;
  margin-right: 10px;
  padding-right: 0 !important;
  float: left;
  flex: 2;
}
@media (min-width: 480px) {
  #search_mb_mini_form .search-wrapper .form-search {
    flex: 5;
  }
}
#search_mb_mini_form .search-wrapper .form-search .category-filter {
  display: none;
}
#search_mb_mini_form .search-wrapper .form-search input {
  padding: 7px 15px !important;
  font-size: 1rem !important;
  background-color: #f3f3f3;
  line-height: inherit !important;
  font-style: italic;
  color: #000000 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#search_mb_mini_form .search-wrapper .form-search .search__label {
  font-size: 1rem !important;
  position: absolute;
  padding: 10px 15px !important;
}
#search_mb_mini_form .popular-search {
  display: none;
}
@media (min-width: 768px) {
  #search_mb_mini_form .popular-search {
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flexbox !important;
    display: -ms-flex !important;
    display: flex;
  }
}
#search_mb_mini_form .popular-search .popular-search__header {
  display: block;
  width: 100%;
  padding: 10px 0;
  color: #b3b3b3;
}
@media (min-width: 768px) {
  #search_mb_mini_form .popular-search .popular-search__header {
    display: inline;
    width: auto;
    padding: 10px 0 0;
  }
}
#search_mb_mini_form .popular-search .popular-search__searchwords {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #search_mb_mini_form .popular-search .popular-search__searchwords {
    margin: 10px 0 0 10px;
  }
}
#search_mb_mini_form .popular-search .popular-search__searchwords li a:active,
#search_mb_mini_form .popular-search .popular-search__searchwords li a:focus,
#search_mb_mini_form .popular-search .popular-search__searchwords li a:hover {
  text-decoration: underline;
}
#search_mb_mini_form .popular-search .popular-search__searchwords li:not(:last-child):after {
  padding: 0 7px 0 5px;
  color: #000000;
  content: "|";
}
@media (min-width: 768px) {
  #search_mb_mini_form {
    padding: 48px 0 30px 0 !important;
  }
}
@media (min-width: 1024px) {
  .switcher-content__search {
    position: fixed !important;
    top: 91px !important;
    left: 0;
    right: 0 !important;
    width: 100%;
    margin: 0;
    background: #ffffff;
    border-bottom: 1px solid #b3b3b3;
  }
  .is-sticky .switcher-content__search {
    top: 71px !important;
  }
}
.category-description a,
.home_description_collapsable_4 a {
  color: #000000;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 0.05rem;
}
.category-description a:hover,
.home_description_collapsable_4 a:hover {
  cursor: pointer;
}
.category-description p a,
.home_description_collapsable_4 p a {
  font-size: 1rem;
  letter-spacing: 0.05rem;
}
.category-description p:nth-of-type(2) a,
.home_description_collapsable_4 p:nth-of-type(2) a {
  text-transform: uppercase;
  text-decoration: none;
  color: #00c6b2;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 0.05rem;
}
.category-description p:nth-of-type(2) a:after,
.home_description_collapsable_4 p:nth-of-type(2) a:after {
  content: "\f178";
  font-family: FontAwesome;
  padding-left: 4px;
}
.category-description-after {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}
.category-description-after p:last-of-type {
  padding-bottom: 30px;
}
.category-description-after p:last-of-type a {
  text-decoration: underline;
  color: #000000;
}
.category-description-after p:last-of-type a:after {
  text-decoration: none;
  content: "\f178";
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 4px;
}
.category-description-after:after {
  content: url('/media/wysiwyg/icotheme/puro/icons/wavy-line.png');
}
.read-more {
  color: #000000;
  font-weight: 900;
  text-decoration: underline;
  float: right;
}
.read-more:hover {
  text-decoration: underline;
  cursor: pointer;
}
.breadcrumbs {
  display: none;
}
.breadcrumbs * {
  text-transform: none !important;
}
@media (min-width: 768px) {
  .breadcrumbs {
    display: block;
  }
}
@media (orientation: portrait) {
  .breadcrumbs {
    display: none;
  }
}
.breadcrumbs .home {
  display: none;
}
.breadcrumbs ul li a,
.breadcrumbs ul li strong {
  font-size: 1rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.1rem !important;
}
.main-breadcrumbs {
  background-color: transparent !important;
}
.category-products .product-action .actions {
  bottom: auto;
  top: 1px;
  height: 0;
}
.category-products .product-action .fa-heart {
  color: #00c6b2;
}
.category-products .product-action .link-wishlist {
  background-color: transparent !important;
}
.category-products .products-grid .item {
  height: auto !important;
  margin-top: 20px;
}
@media (min-width: 1200px) {
  .category-products .products-grid .item:nth-child(-n+3) {
    margin-top: 0;
  }
}
.category-products .add-to-links .compare {
  display: none;
}
.category-products .add-to-links .wishlist {
  display: none;
}
@media (min-width: 1024px) {
  .category-products .add-to-links .wishlist {
    display: inherit;
  }
}
.category-products .add-to-links li a:before {
  border-right: 0;
}
.category-products .addtocart {
  flex: 2;
}
.category-products .product-name {
  clear: left;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 0.2rem;
}
.category-products .product-name__link {
  color: #000000;
  text-transform: none !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  padding-bottom: 5px;
}
.category-products .addToCart__qty,
.category-products .addToCart__label {
  text-align: left;
}
.category-products .addToCart__qty label.error,
.category-products .addToCart__label label.error {
  position: absolute;
  left: 0px;
  background-color: #00c6b2;
  border: 1px solid #00ad9b;
  z-index: 1;
  padding: 10px;
  width: 210px;
  top: 48px;
}
.category-products .addToCart__qty .qty,
.category-products .addToCart__label .qty {
  margin-right: 10px;
}
.category-products .product-content {
  padding: 5px 0 0 0 !important;
  text-align: left;
}
.category-products .additionalInfo__entry {
  font-weight: 300;
}
.category-products .additionalInfo__entry:first-of-type {
  padding-top: 5px;
}
.category-products .additionalInfo__contentsAndProductInfo {
  font-size: 1rem;
  font-weight: 300;
}
.category-products .additionalInfo__contentsAndProductInfo,
.category-products .product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.category-products .additionalInfo__tierPrice li:not(:first-of-type) {
  display: none;
}
.category-products .additionalInfo__tierPrice .tier-prices {
  font-size: 1rem;
  font-weight: 900;
}
.category-products .price-box {
  padding-top: 0 !important;
}
.category-products .additionalInfo__prices .price-box > :not(.old-price) {
  display: none !important;
}
.category-products .additionalInfo__prices .price-box .old-price {
  padding-right: 0;
  margin-bottom: 0;
}
.category-products .additionalInfo__prices .price-box .old-price,
.category-products .additionalInfo__prices .price-box .old-price-label {
  font-weight: 900;
}
.category-products .img-responsive {
  margin: 0 auto;
}
.category-products .img-responsive.hidden-tablet,
.category-products .img-responsive.hidden-phone {
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 1024px) {
  .category-products .img-responsive.hidden-tablet,
  .category-products .img-responsive.hidden-phone {
    opacity: 1;
    visibility: visible;
  }
}
.category-products .product-boxes__buttons {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 10px 0 0;
}
.category-products .add-to-cart__label--pdf {
  display: block;
  padding: 0 10px 0 0;
  color: #b6b6b6;
  font-size: 1.953rem;
}
.category-products .product-choice-info {
  position: absolute;
  top: auto;
  padding: 5px 10px;
  right: 5px;
  bottom: 0;
  color: #000000;
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  z-index: 6;
}
.category-products .product__images img.alt-img {
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 480px) {
  .category-products .product__images img.alt-img {
    opacity: 0;
  }
}
@media (min-width: 1024px) {
  .category-products .product__images img.alt-img {
    visibility: inherit;
  }
}
@media (min-width: 1200px) {
  .category-products .toolbar {
    margin-right: 185px;
  }
}
.category-products .toolbar .pager ol li {
  border: none !important;
}
.category-products .toolbar .pager ol li a {
  color: #00c6b2;
}
.category-products .toolbar .pager ol li.current {
  background: #00c6b2;
}
.category-products .toolbar .pager ol li:hover {
  background-color: #00c6b2 !important;
}
.catalogsearch-result-index .product-action .actions {
  bottom: auto;
  top: 1px;
  height: 0;
}
.catalogsearch-result-index .product-action .fa-heart {
  color: #00c6b2;
}
.catalogsearch-result-index .product-action .link-wishlist {
  background-color: transparent !important;
}
.catalogsearch-result-index .products-grid .item {
  height: auto !important;
  margin-top: 20px;
}
@media (min-width: 1200px) {
  .catalogsearch-result-index .products-grid .item:nth-child(-n+3) {
    margin-top: 0;
  }
}
.catalogsearch-result-index .add-to-links .compare {
  display: none;
}
.catalogsearch-result-index .add-to-links .wishlist {
  display: none;
}
@media (min-width: 1024px) {
  .catalogsearch-result-index .add-to-links .wishlist {
    display: inherit;
  }
}
.catalogsearch-result-index .add-to-links li a:before {
  border-right: 0;
}
.catalogsearch-result-index .addtocart {
  flex: 2;
}
.catalogsearch-result-index .product-name {
  clear: left;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 0.2rem;
}
.catalogsearch-result-index .product-name__link {
  color: #000000;
  text-transform: none !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  padding-bottom: 5px;
}
.catalogsearch-result-index .addToCart__qty,
.catalogsearch-result-index .addToCart__label {
  text-align: left;
}
.catalogsearch-result-index .addToCart__qty label.error,
.catalogsearch-result-index .addToCart__label label.error {
  position: absolute;
  left: 0px;
  background-color: #00c6b2;
  border: 1px solid #00ad9b;
  z-index: 1;
  padding: 10px;
  width: 210px;
  top: 48px;
}
.catalogsearch-result-index .addToCart__qty .qty,
.catalogsearch-result-index .addToCart__label .qty {
  margin-right: 10px;
}
.catalogsearch-result-index .product-content {
  padding: 5px 0 0 0 !important;
  text-align: left;
}
.catalogsearch-result-index .additionalInfo__entry {
  font-weight: 300;
}
.catalogsearch-result-index .additionalInfo__entry:first-of-type {
  padding-top: 5px;
}
.catalogsearch-result-index .additionalInfo__contentsAndProductInfo {
  font-size: 1rem;
  font-weight: 300;
}
.catalogsearch-result-index .additionalInfo__contentsAndProductInfo,
.catalogsearch-result-index .product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.catalogsearch-result-index .additionalInfo__tierPrice li:not(:first-of-type) {
  display: none;
}
.catalogsearch-result-index .additionalInfo__tierPrice .tier-prices {
  font-size: 1rem;
  font-weight: 900;
}
.catalogsearch-result-index .price-box {
  padding-top: 0 !important;
}
.catalogsearch-result-index .additionalInfo__prices .price-box > :not(.old-price) {
  display: none !important;
}
.catalogsearch-result-index .additionalInfo__prices .price-box .old-price {
  padding-right: 0;
  margin-bottom: 0;
}
.catalogsearch-result-index .additionalInfo__prices .price-box .old-price,
.catalogsearch-result-index .additionalInfo__prices .price-box .old-price-label {
  font-weight: 900;
}
.catalogsearch-result-index .img-responsive {
  margin: 0 auto;
}
.catalogsearch-result-index .img-responsive.hidden-tablet,
.catalogsearch-result-index .img-responsive.hidden-phone {
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 1024px) {
  .catalogsearch-result-index .img-responsive.hidden-tablet,
  .catalogsearch-result-index .img-responsive.hidden-phone {
    opacity: 1;
    visibility: visible;
  }
}
.catalogsearch-result-index .product-boxes__buttons {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 10px 0 0;
}
.catalogsearch-result-index .add-to-cart__label--pdf {
  display: block;
  padding: 0 10px 0 0;
  color: #b6b6b6;
  font-size: 1.953rem;
}
.catalogsearch-result-index .product-choice-info {
  position: absolute;
  top: auto;
  padding: 5px 10px;
  right: 5px;
  bottom: 0;
  color: #000000;
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  z-index: 6;
}
.catalogsearch-result-index .product__images img.alt-img {
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 480px) {
  .catalogsearch-result-index .product__images img.alt-img {
    opacity: 0;
  }
}
@media (min-width: 1024px) {
  .catalogsearch-result-index .product__images img.alt-img {
    visibility: inherit;
  }
}
@media (min-width: 1200px) {
  .catalogsearch-result-index .toolbar {
    margin-right: 185px;
  }
}
.catalogsearch-result-index .toolbar .pager ol li {
  border: none !important;
}
.catalogsearch-result-index .toolbar .pager ol li a {
  color: #00c6b2;
}
.catalogsearch-result-index .toolbar .pager ol li.current {
  background: #00c6b2;
}
.catalogsearch-result-index .toolbar .pager ol li:hover {
  background-color: #00c6b2 !important;
}
.sidebar {
  /*
    %%%%%%%%%%%%%%%%%%%
    %%% result view %%%
    %%%%%%%%%%%%%%%%%%%
    */
}
.sidebar .block-content {
  display: none;
}
@media (min-width: 1024px) {
  .sidebar .block-content {
    display: block;
  }
}
.sidebar .block-nav {
  display: none;
  border-bottom: none !important;
  background-color: #ffffff !important;
}
@media (min-width: 1024px) {
  .sidebar .block-nav {
    display: block;
  }
}
.sidebar .block-nav .block-title span {
  font-size: 1.25rem !important;
  font-weight: 900 !important;
}
.sidebar #narrow-by-list dt {
  color: #00c6b2;
  font-size: 1rem !important;
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis) {
  padding-top: 0 !important;
}
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis) li {
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
}
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis) li a {
  background-color: #b3b3b3 !important;
  color: #ffffff !important;
  text-transform: uppercase;
}
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis) li a:hover,
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis) li a:focus,
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis) li a:active {
  background-color: #00c6b2 !important;
  border: 1px solid transparent !important;
}
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis).filter-group__farbe li .swatch-link {
  background-color: transparent !important;
  color: #000000 !important;
}
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis).filter-group__farbe li .swatch-link:hover,
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis).filter-group__farbe li .swatch-link:focus,
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis).filter-group__farbe li .swatch-link:active {
  border: 1px solid #000000 !important;
}
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis).filter-group__farbe .swatch-link .has-image {
  background-color: #ffffff !important;
}
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis).filter-group__farbe .swatch-link .has-image:hover,
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis).filter-group__farbe .swatch-link .has-image:focus,
.sidebar #narrow-by-list dd[class*='filter-group']:not(.filter-group__preis).filter-group__farbe .swatch-link .has-image:active {
  border: 1px solid #000000 !important;
}
.sidebar #narrow-by-list .filter-group__preis ol {
  padding-top: 10px;
}
.sidebar #narrow-by-list #categories_nav a {
  height: auto;
}
.sidebar #categories_nav li span {
  font-size: 0.8rem !important;
}
.sidebar #categories_nav li.level-top > a span {
  font-weight: 900;
}
.sidebar #categories_nav li.active > ul .active {
  background-color: #f8f8f8;
}
.sidebar #categories_nav li.active > ul .active > a span {
  font-weight: 900;
}
.sidebar .block-layered-nav {
  background-color: #ffffff;
}
.sidebar .block-layered-nav .block-title span {
  font-size: 1.25rem !important;
  font-weight: 900 !important;
}
.sidebar .block-layered-nav .actions a {
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 0.05rem;
}
.sidebar .currently li .value {
  float: none !important;
}
.sidebar .currently .block-subtitle {
  color: #00c6b2;
  font-size: 0.8rem !important;
  border-bottom: none !important;
  padding-bottom: 0 !important;
  margin-bottom: 0;
}
.sidebar .actions {
  padding-top: 0 !important;
}
.cms-page-view a[name] {
  margin-top: -70px;
  visibility: hidden;
  display: block;
  height: 70px;
}
.page-action-set {
  padding-left: 30px;
}
.page-action-set .add-bookmark .icon {
  background-position: -44px 0px;
}
.page-action-set .facebook .icon {
  background-position: -156px 0px;
}
.page-action-set .action-icon-link {
  float: left;
  padding-left: 10px;
}
.page-action-set .action-icon-link .icon {
  background-repeat: no-repeat;
  display: block;
  background-image: url(../images/iconset-416595.png);
  height: 22px;
  width: 22px;
  float: left;
  margin-right: 10px;
  background-color: transparent;
}
/**
*
 * imports all styles for article main page
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 30.05.16
 *
 */
/**
*
 * product detail styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 28.05.16
 *
 */
.product-view .product-img-box {
  display: block;
}
.product-view .product-img-box .product-img-list .product-image .product-image-gallery {
  border: none;
}
.owl-theme .owl-controls .owl-page span {
  background: #dcdcdc;
}
.owl-theme .owl-controls .owl-page.active span {
  background: #000000;
}
.product-view .product-options {
  padding: 0;
}
.product-view .product-options dl {
  margin: 0;
  padding: 0;
}
.product-view .product-options dl dt label {
  display: none;
  margin: 0;
  padding: 0;
}
.product-view .product-options dl .options-list {
  display: none;
  margin: 0;
  padding: 0;
}
.product-view .product-shop {
  width: 100%;
  margin: 48px 0;
}
@media (min-width: 1024px) {
  .product-view .product-shop {
    width: 50%;
    margin: 0;
  }
}
.product-view .product-shop .product-shop-wrapper {
  border: none;
}
.product-view .product-shop .product-shop-wrapper .product-new-label {
  display: none;
}
.product-view .product-shop .product-shop-wrapper .product-prev-next {
  display: none;
}
.product-view .product-shop .product-name {
  margin: 5px 0 20px;
  padding: 0;
}
@media (min-width: 1024px) {
  .product-view .product-shop .product-name {
    display: inline-block;
    margin: 15px 10px;
  }
}
.product-view .product-shop .product-name h2 {
  font-size: 1.953rem;
}
.product-view .product-shop .middle-product-detail {
  border-top: none;
}
.product-view .product-shop .middle-product-detail .additionalInfo__entry {
  margin: 10px 0;
  font-size: 1rem;
  color: #656565;
}
@media (min-width: 1024px) {
  .product-view .product-shop .middle-product-detail .additionalInfo__entry {
    margin: 10px;
  }
}
.product-view .product-shop .middle-product-detail .review-product-details {
  padding: 0;
}
.product-view .product-shop .product-type-data {
  padding: 0;
}
@media (min-width: 1024px) {
  .product-view .product-shop .product-type-data {
    padding: 0 10px;
  }
}
.product-view .product-shop .product-type-data .col2-set > .col-2 {
  width: 100%;
}
.product-view .add-to-cart {
  padding: 0 !important;
}
@media (min-width: 1024px) {
  .product-view .add-to-cart {
    padding: 0 10px;
  }
}
.product-tags__list {
  overflow: hidden;
}
.product-tags__list--element {
  -moz-transition: background 0.6ms !important;
  -webkit-transition: background 0.6ms !important;
  -o-transition: background 0.6ms !important;
  -ms-transition: background 0.6ms !important;
  transition: background 0.6ms !important;
  margin: 5px;
  padding: 3px 10px;
  float: left;
  background: #b3b3b3;
}
.product-tags__list--element:active,
.product-tags__list--element:focus,
.product-tags__list--element:hover,
.product-tags__list--element:visited {
  background-color: #00c6b2;
}
.product-tags__list--element:active .products-tags__list--link,
.product-tags__list--element:focus .products-tags__list--link,
.product-tags__list--element:hover .products-tags__list--link,
.product-tags__list--element:visited .products-tags__list--link {
  color: #ffffff;
}
.products-tags__list--link {
  padding: 3px 5px;
  color: #ffffff;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
}
.product-tags__headline {
  margin: 20px 5px;
  font-size: 1.25rem !important;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .product-tags__headline {
    margin: 0 5px 20px;
  }
}
.product-tags__headline .fa {
  -webkit-transform: rotate(90deg) !important;
  -moz-transform: rotate(90deg) !important;
  -ms-transform: rotate(90deg) !important;
  -o-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
  margin: -2px 5px 0 0;
}
.product-info-addons {
  padding: 10px;
}
@media (min-width: 1024px) {
  .product-info-addons {
    padding: 5px 10px;
  }
}
.product-info-addons.product-info-addons__configurable {
  padding: 5px 0;
}
.product-info-addons li {
  margin: 3px 0;
  color: #00c6b2;
  font-size: 1rem;
  font-style: italic;
}
.product-info-addons li:before {
  font-family: FontAwesome;
  padding: 5px 5px 5px 0;
  content: "\f00c";
}
.product-info-addons li:last-child:before {
  content: "\f292";
}
.free-pdf__info {
  color: #656565;
  font-size: 1.25rem;
  padding: 10px;
}
.free-pdf__info span {
  color: #ff5f00;
}
.product-infobox__description {
  margin: 10px 0;
}
.container__product-infobox .additionalInfo__contentsAndProductInfo {
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: #656565;
}
.widget-products-related h2 {
  color: #00c6b2;
  font-size: 1.563rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 15px;
}
.widget-products-related hr {
  border-color: #00c6b2;
  border-width: 2px;
  margin: 15px;
}
.widget-products-related .category-products .product-content {
  text-align: left !important;
}
.widget-products-related .category-products .addtocart button i {
  position: absolute;
  margin-right: 0;
}
.product-img-box .product-image {
  width: 100% !important;
}
.product-img-box #image-main {
  display: none !important;
}
.product-img-box .gallery-image {
  display: block !important;
}
.product-img-box .more-views-verticle {
  display: none;
}
.product-img-box .owl-buttons .owl-prev,
.product-img-box .owl-buttons .owl-next {
  color: #66ddd1 !important;
}
/**
*
 * product attributes
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 30.05.16
 *
 */
.configurable-optionlist {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 20px 0 0;
}
@media (min-width: 768px) {
  .configurable-optionlist {
    margin: 20px 0;
  }
}
.configurable-optionlist__element {
  order: 2;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  border-top: 1px solid #f3f3f3;
}
@media (min-width: 1024px) {
  .configurable-optionlist__element {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .configurable-optionlist__element:nth-child(even) {
    padding-right: 10px;
  }
}
@media (min-width: 1024px) {
  .list__collapse .configurable-optionlist__element:nth-child(even) {
    padding-right: 0;
    padding-left: 10px;
  }
}
@media (min-width: 1024px) {
  .configurable-optionlist__element:nth-child(odd) {
    padding-left: 10px;
  }
}
@media (min-width: 1024px) {
  .list__collapse .configurable-optionlist__element:nth-child(odd) {
    padding-right: 10px;
    padding-left: 0;
  }
}
@media (min-width: 1024px) {
  .configurable-optionlist__element:first-child {
    border-top: none;
  }
}
@media (min-width: 1024px) {
  .list__collapse .configurable-optionlist__element:first-child {
    border-top: 1px solid #f3f3f3;
  }
}
.configurable-optionlist__element:last-child {
  border-top: none;
}
.list__collapse .configurable-optionlist__element:last-child {
  border-top: 1px solid #f3f3f3;
}
.configurable-optionlist__element.color-all {
  order: 1;
}
@media (min-width: 1024px) {
  .configurable-optionlist__element.color-all {
    padding-right: 10px;
    padding-left: 0;
  }
}
.product-attributes {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.product-attributes__item-info {
  flex: 2;
  margin-right: 10px;
}
.product-attributes__item-info .product-attribute__color {
  display: block;
  letter-spacing: 0.1rem;
}
.product-attributes__item-info .product-attribute__number {
  display: block;
  color: #656565;
  font-weight: 400;
  letter-spacing: 0.1rem;
}
.product-attribute__image {
  flex: 1;
  text-align: center;
}
.product-attribute__image > img {
  border: 1px solid #cdcdcd;
}
.product-attribute__qty {
  flex: 1;
}
.product-attribute__qty input {
  float: right;
}
.product-attribute__qty input:active,
.product-attribute__qty input:focus,
.product-attribute__qty input:hover {
  background-color: #ffffff;
}
.product-attribute__qty input[disabled] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background-color: #dcdcdc;
  border: 1px solid #b3b3b3 !important;
}
.product-attribute__qty input[disabled]:active,
.product-attribute__qty input[disabled]:focus,
.product-attribute__qty input[disabled]:hover {
  background-color: #dcdcdc !important;
}
.product-attribute__order-number {
  color: #00c6b2;
  font-style: italic;
}
/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 26.05.2016
 */
.extra-attributes {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex;
  margin: 0 5px;
  padding-top: 10px;
  clear: both;
}
.extra-attributes .flex-item {
  flex: 1;
}
.extra-attributes .flex-item:before {
  font-family: FontAwesome;
  font-size: 1.563rem;
  padding-left: 5px;
}
.extra-attributes--age:before {
  color: #ffa3a3;
  content: "\f1ae";
}
.extra-attributes--age .extra-attributes--label {
  color: #ffa3a3;
}
.extra-attributes--technology:before {
  color: #f2e500;
  content: "\f0c4";
}
.extra-attributes--technology .extra-attributes--label {
  color: #f2e500;
}
.extra-attributes--promotion:before {
  color: #66ddd1;
  content: "\f0e6";
}
.extra-attributes--promotion .extra-attributes--label {
  color: #66ddd1;
}
.extra-attributes--label,
.extra-attributes--value {
  font-size: 0.8rem;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
}
.product-tabs-topic__h2 {
  display: block !important;
}
/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 03.06.2016
 */
.product-view .product-shop .middle-product-detail .price-box .special-price .price,
.product-view .product-shop .middle-product-detail .price-box .regular-price .price {
  font-weight: 900;
}
.product-view .product-shop .middle-product-detail .price-box .special-price .price {
  color: #ff5f00;
}
.product-view .product-shop .middle-product-detail .price-box > .old-price {
  margin-bottom: 5px;
  text-decoration: line-through;
}
.product-view .product-shop .middle-product-detail .product-baseprice {
  display: none;
  font-size: 1rem;
  color: #000000;
}
.product-view .product-shop .middle-product-detail .product-baseprice .price {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #000000 !important;
}
.product-view .product-shop .middle-product-detail .old-price-label,
.product-view .product-shop .middle-product-detail .old-price {
  color: #656565;
  font-size: 1rem;
  text-decoration: line-through;
}
.product-view .product-shop .middle-product-detail .tier-prices {
  color: #000000;
  font-size: 1.25rem;
  padding: 10px;
}
.product-view .product-shop .middle-product-detail .tier-prices .price {
  color: #ff5f00;
}
.product-view .product-shop .middle-product-detail .tier-baseprice {
  color: #ff5f00;
  font-size: 0.8rem;
  font-weight: 400;
}
/**
*
 * styles for product description and information
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 07.06.16
 *
 */
.product-view .product-collateral .nav-tabs li {
  border-top: 1px solid #00c6b2;
  border-right: 1px solid #00c6b2;
  border-left: 1px solid #00c6b2;
}
@media (min-width: 1024px) {
  .product-view .product-collateral .nav-tabs li {
    border-left: transparent;
  }
}
.product-view .product-collateral .nav-tabs li.first {
  border-left: 1px solid #00c6b2;
}
.product-view .product-collateral .nav-tabs li.active a {
  color: #000000;
  border-right: none;
}
.product-view .product-collateral .nav-tabs li.active a:before {
  left: auto;
  right: 0;
  width: 100%;
  border-top: 4px solid #00c6b2;
}
.product-view .product-collateral .nav-tabs li.active a:hover:before {
  left: auto;
  right: 0;
  width: 100%;
  border-top: 4px solid #00c6b2;
}
.product-view .product-collateral .nav-tabs li.active:hover a:before {
  border-top: 4px solid #00c6b2;
}
.product-view .product-collateral .nav-tabs li.active:after {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background: #ffffff;
  content: '';
}
.product-view .product-collateral .nav-tabs li a {
  color: #656565;
  border-right: none;
}
.product-view .product-collateral .nav-tabs li a:before {
  left: auto;
  right: 0;
  background-color: #00c6b2;
}
.product-view .product-collateral .nav-tabs li a:hover:before {
  left: auto;
  right: 0;
  width: 100%;
  border-top: 4px solid #00c6b2;
}
.product-view .product-collateral .tab-content {
  border: 1px solid #00c6b2;
}
.product-view .product-collateral .tab-content .tab-pane {
  padding: 20px;
  border-top: none;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-view .product-collateral .nav-tabs li {
    display: block;
    width: 100%;
  }
}
/**
*
 * grouped items toggle action
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 30.06.16
 *
 */
#grouped-items__table--body {
  display: table-row-group !important;
}
.grouped-items__table--row:nth-child(n+7) {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  display: none;
}
@media (min-width: 1024px) {
  .grouped-items__table--row:nth-child(n+7) {
    display: table-row;
  }
}
.in .grouped-items__table--row:nth-child(n+7) {
  display: table-row;
}
/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 06.06.2016
 *
 * inherit from "...\src\.modman\Mindscreen.LabbeAll\skin\frontend\labbe\default\css\styles.css"
 */
/* Topics ================================================================================ */
.topics {
  margin-bottom: 30px;
}
.topics ul {
  margin: 0;
}
.topics li {
  margin-bottom: 8px;
}
.topics h3 {
  margin-bottom: 5px;
}
.topics h2 {
  font-weight: 400;
  color: #007a6d;
}
.c1 {
  text-align: center;
}
.topics table,
.topics table td,
.topics table th {
  border: solid 1px #66ddd1;
  padding: 5px 3px 8px 3px;
}
.topics table th {
  font-weight: bold;
  padding: 5px 3px 8px 5px;
  color: #007a6d;
  background-color: #66ddd1;
}
.topics dl {
  display: block;
  text-align: center;
}
.topics dd {
  display: block;
  padding: 4px 10%;
}
.topics dd p {
  text-align: left;
  font-size: 0.8rem;
  font-style: italic;
}
/* topics index list */
.topics-index-list {
  -moz-column-count: 2;
  -moz-column-rule: none;
  -moz-column-gap: 29px;
  -webkit-column-count: 2;
  -webkit-column-rule: none;
  -webkit-column-gap: 29px;
  padding-bottom: 30px;
  background: url(../images/hr-dotted-orange.png) repeat-x bottom left;
}
.topics-category-list {
  margin: 1em 0 3em 0;
}
.topics-category-list ul {
  margin-bottom: 2em;
}
.topics-category-list li {
  margin: 0.4em 0;
}
.topic-header {
  font-weight: bold;
  margin-bottom: 1em;
}
.topic-article {
  margin: 2em 0 3em 0;
}
.topics-idea-list .sectionheader {
  clear: both;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
/* topic featured */
.topic-featured {
  margin-top: 50px;
}
.topics.topic-featured {
  margin-bottom: 0;
}
/* topics typo general */
.hinweis {
  border-radius: 8px;
  background: #fff;
  margin: 20px 0;
  padding: 10px 20px;
  border: 1px solid #778d0b;
  background: none repeat scroll 0% 0% transparent;
}
.hinweis h3 {
  color: #778d0b;
}
.hinweis li {
  padding-left: 8px;
  background: url(../images/topics-list-icon.png) no-repeat left 0.6em;
}
/* topics magazine teaser */
.topic-grid .img {
  display: block;
  width: 180px;
  height: 120px;
  overflow: hidden;
}
.topic-grid li {
  width: 260px;
  padding: 0 10px 5px 0;
  min-height: 190px;
  float: left;
}
.topic-grid .topic-info {
  padding-top: 0.6em;
}
.topic-grid li strong {
  display: block;
  padding-bottom: 0.5em;
}
/* Grid-List */
.grid-list {
  margin: 2em 0;
  position: relative;
}
.grid-list a {
  display: block;
  text-align: center;
}
.grid-list ul {
  margin: 0 !important;
  width: auto !important;
}
.grid-list li.last {
  margin-right: 0px;
}
.grid-list .link-arrow-before {
  margin: 3px 0 0 0;
  position: relative;
  display: block;
}
.grid-list .link-arrow-before .icon {
  margin: 3px 0 0 0;
  height: 8px;
  width: 8px;
  background: url(../images/more-link.png) no-repeat 0 0;
  display: block;
  overflow: hidden;
  text-indent: -99999px;
  left: 0.5em;
  margin-top: 0px;
  position: absolute;
  top: 30%;
}
.grid-list .link-arrow-before .text {
  margin-left: 18px;
  display: block;
  text-align: left;
}
.grid-list .w150 li {
  min-height: 190px !important;
}
.grid-list .w150 .grid-topic-image {
  width: 150px;
  height: 150px;
}
.grid-list li,
.std .grid-list ul li {
  float: left;
  width: 154px;
  min-height: 155px;
  margin: 0 30px 10px 0 !important;
  padding-left: 0 !important;
  background: none;
  font-size: 0.9rem;
}
.main .grid-list a:hover,
.main .grid-list a:focus {
  background-color: #fff !important;
  color: #416595 !important;
  text-decoration: none !important;
}
.grid-list a:hover .link-arrow-before strong,
.grid-list a:focus .link-arrow-before strong {
  background: #4E73A0;
  color: #fff;
}
.grid-topic-image {
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin: 0 auto;
}
.grid-list .w150 a:hover .grid-topic-image,
.grid-list .w150 a:focus .grid-topic-image {
  background-position: 0 -150px !important;
}
.grid-list a:hover .grid-topic-image,
.grid-list a:focus .grid-topic-image {
  background-position: 0 -120px !important;
}
/* sectionheader */
.sectionheader {
  position: relative;
  z-index: 0;
  background-color: #FEF8D6;
  margin: 0;
  margin-bottom: 10px;
  color: #CB4510;
  font-size: 116.66%;
  font-weight: bold;
}
.sectionheader .mod-content {
  position: relative;
  z-index: 2;
  border: solid 1px #fff;
}
.sectionheader .x1 {
  background-position: 1px top;
  z-index: 3;
  top: -1px;
  /* set to the size of the glow */
  left: 0px;
  bottom: 0;
}
.sectionheader .x2 {
  background-position: 1px bottom;
  z-index: 4;
  bottom: -1px;
  right: 0px;
  padding: 0;
}
.sectionheader h1 {
  font-size: 100%;
}
.sectionheader .block-title {
  padding: 2px 8px;
}
.sectionheader .x1,
.sectionheader .x2 {
  display: block;
  background: url(../images/hr-dotted-orange.png) repeat-x;
  /* turn off for IE6 or use a GIF */
  _background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
}
/* Content Columns */
.col2-set {
  clear: both;
}
.col2-set .col-narrow {
  width: 33%;
}
.col2-set .col-wide {
  width: 65%;
}
.col2-set .col-1,
.print-col2-set .col-1 {
  float: left;
  width: 50%;
  position: relative;
}
.col2-set .col-2,
.print-col2-set .col-2 {
  float: right;
  width: 50%;
  position: relative;
}
.grid-list:after,
#magazine-teaser:after,
.col-main:after,
.col2-set:after,
.col3-set:after,
.col4-set:after,
.col-1:after,
.col-2:after,
.col-3:after {
  display: block;
  content: ".";
  clear: both;
  font-size: 0;
  line-height: 0;
  height: 0;
  overflow: hidden;
}
.topics-topic-view .product-item .image {
  display: none;
}
.topics-topic-view .product-item .add-to-cart {
  display: none;
}
.topics-topic-view .product-item .price-box {
  display: none;
}
.topics-topic-view .product-item .hideme {
  display: none;
}
.topics-topic-view .product-item .qty {
  display: none;
}
/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 13.06.2016
 */
/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 13.06.2016
 */
@media (min-width: 992px) {
  .checkout-cart {
    margin-top: 0;
  }
}
@media (max-width: 991px) {
  .checkout-cart .page-title {
    text-align: center;
  }
  .checkout-cart .page-title h1 {
    color: #000000 !important;
    font-size: 1.25rem !important;
    font-weight: 900 !important;
    text-transform: uppercase;
  }
  .checkout-cart .page-title .mobile-qty {
    color: #ff5f00;
    font-weight: 400;
  }
}
.checkout-cart .page-title h1 {
  padding-left: 15px;
  color: #00c6b2;
  font-weight: 400;
  letter-spacing: 1px;
}
.checkout-cart .page-title hr {
  border: 1px solid #00c6b2;
  margin-top: 0;
}
@media (min-width: 1024px) {
  .checkout-cart .page-title {
    margin-top: 0;
  }
}
.checkout-cart .btn-continue {
  padding-left: 20px;
  color: #000000;
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.checkout-cart .btn-continue:before {
  font-family: FontAwesome;
  position: absolute;
  margin: 0 -20px;
  font-weight: 900;
  font-size: 0.9rem;
  content: "\f053";
}
.checkout-cart .totals {
  margin-top: 38px;
}
.checkout-cart .btn-checkout {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  background-color: #ff5f00 !important;
  width: 100%;
}
.checkout-cart .btn-checkout:active,
.checkout-cart .btn-checkout:focus,
.checkout-cart .btn-checkout:hover {
  background-color: #000000 !important;
}
.checkout-cart .btn-checkout span {
  line-height: 1.19 !important;
  height: auto !important;
}
@media (min-width: 1024px) {
  .checkout-cart .btn-checkout {
    float: none;
  }
}
.checkout-cart .data-table {
  margin-top: 0 !important;
}
.checkout-cart .data-table img {
  width: 100px !important;
  height: 100px !important;
}
.checkout-cart .data-table .product-name {
  padding-bottom: 5px !important;
}
.checkout-cart .data-table .product-name a {
  font-size: 1rem !important;
  font-weight: 900;
  text-transform: none !important;
}
.checkout-cart .data-table .product-image {
  float: left;
  border: none;
}
.checkout-cart .data-table .cart-item-actions {
  display: flex;
  clear: both;
  padding-top: 10px;
}
.checkout-cart .data-table .cart-item-info {
  min-height: 50px;
  margin-left: 110px;
}
@media (min-width: 768px) {
  .checkout-cart .data-table .cart-item-info {
    margin-left: 0;
  }
}
.checkout-cart .data-table .cart-item-info .tier-prices {
  color: #ff8772;
  font-size: 0.9rem;
  font-weight: 900;
  margin-bottom: 0 !important;
}
.checkout-cart .data-table .cart-item-info .cart-item-info__item {
  font-size: 0.9rem;
}
.checkout-cart .data-table .cart-actions {
  min-width: 100px;
}
.checkout-cart .data-table .cart-actions .edit-item {
  color: #b3b3b3;
  position: absolute;
  font-size: 0.8rem;
  padding: 3px 10px;
}
.checkout-cart .data-table .cart-actions .edit-item:hover {
  color: #cdcdcd !important;
}
.checkout-cart .data-table .update-item-qty {
  display: flex;
  margin-left: auto;
}
.checkout-cart .data-table .update-item-qty .items {
  background-color: #f3f3f3;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  height: 27px;
  line-height: 27px;
  text-align: center;
  width: 27px;
  margin: 5px;
}
.checkout-cart .data-table .update-item-qty .items:hover {
  background-color: #000000;
  color: #ffffff;
}
.checkout-cart .data-table .update-item-qty .qty {
  margin-top: 5px;
  height: 27px;
  width: 27px !important;
}
.checkout-cart .data-table .btn-update {
  background-color: #ffffff !important;
  padding: 0 5px !important;
  height: 30px;
  z-index: -1;
}
.checkout-cart .data-table .btn-update .fa-refresh {
  font-size: 1.563rem !important;
  color: #ffffff;
}
.checkout-cart .data-table .cart-item-price {
  margin-left: auto;
  text-align: right;
}
.checkout-cart .data-table .cart-item-price .cart-price {
  font-size: 1rem !important;
}
.checkout-cart .data-table .cart-item-price .cart-item-single-price {
  color: #696969;
}
.checkout-cart .data-table .cart-item-price .cart-item-single-price .price {
  font-size: 0.9rem !important;
}
.checkout-cart .data-table .cart-item-price .cart-item-subtotal {
  font-weight: 900;
}
.checkout-cart .data-table .fa-remove {
  color: #00c6b2;
  font-size: 1.563rem;
}
.checkout-cart .data-table .fa-remove:hover {
  color: #007a6d;
}
.checkout-cart .data-table .cart-item__download-info {
  font-size: 0.9rem;
  margin-left: auto;
  margin-top: 20px;
}
.checkout-cart .totals {
  background-color: #ffffff !important;
  padding: 0 !important;
}
.checkout-cart .totals tbody tr {
  background: #f9f9f9;
  border-top: 1px solid #dcdcdc;
}
.checkout-cart .totals tbody tr:last-child {
  border-bottom: 1px solid #dcdcdc;
}
.checkout-cart .totals tbody tr td:last-child {
  text-align: right;
}
.checkout-cart .totals tbody td {
  padding: 15px !important;
}
.checkout-cart .totals.affix {
  position: relative;
}
@media (min-width: 992px) {
  .checkout-cart .totals.affix {
    position: fixed;
    top: 13px;
    width: 294px;
    z-index: 100;
  }
}
@media (min-width: 1024px) {
  .checkout-cart .totals.affix {
    top: 17px;
  }
}
@media (min-width: 1025px) {
  .checkout-cart .totals.affix {
    top: 33px;
  }
}
@media (min-width: 1200px) {
  .checkout-cart .totals.affix {
    width: 370px;
  }
}
.checkout-cart .totals .link-extern {
  text-decoration: underline;
}
.checkout-cart .totals .delivery-de {
  font-weight: 400;
  font-style: italic;
}
.checkout-cart .totals .totals-table__tax {
  font-size: 0.9rem !important;
  font-style: italic;
  font-weight: 400 !important;
}
@media (min-width: 768px) {
  .checkout-cart .totals .totals-table__tax {
    text-align: left !important;
  }
}
.checkout-cart .totals tfoot {
  border-top: none !important;
}
.checkout-cart .totals tfoot .table--totals td {
  padding-top: 30px !important;
}
@media (min-width: 768px) {
  .checkout-cart .totals tfoot .table--totals td:first-child {
    text-align: left !important;
  }
}
.checkout-cart .totals tfoot .table--totals td span,
.checkout-cart .totals tfoot .table--totals td strong {
  font-size: 1.563rem;
}
.checkout-cart .totals .checkout-types {
  display: block;
  padding: 0 !important;
}
@media (min-width: 768px) {
  .checkout-cart .totals .checkout-types {
    width: 100%;
    max-width: inherit;
  }
}
.checkout-cart__trust {
  color: #00c6b2;
  text-align: center;
  clear: both;
}
.checkout-cart__trust dt {
  padding-top: 30px;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
}
.checkout-cart__trust dt i {
  position: relative;
  top: -1px;
}
.checkout-cart__trust dd {
  color: #66ddd1;
  font-size: 1rem;
  font-style: italic;
  letter-spacing: 0.05rem;
}
.cart__additional-info .additionalInfo__entry {
  font-size: 1rem;
  letter-spacing: normal;
  text-transform: none;
}
.cart-review__additional-info .additionalInfo__entry {
  font-size: 1.25rem;
  letter-spacing: normal;
  text-transform: none;
}
/**
 *
 * main styles checkout onepage, steps, content, grid
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 15.06.16
 *
 */
.checkout-onepage-index .main-container .main-wrapper {
  margin-top: 0;
}
@media (min-width: 768px) {
  .checkout-onepage-index .container {
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .checkout-onepage-index .container {
    max-width: 970px;
  }
}
.checkout-onepage-index .col-lg-9 {
  padding: 0 !important;
}
@media (min-width: 1024px) {
  .checkout-onepage-index .col-lg-9 {
    padding: 0 15px !important;
  }
}
.checkout-onepage-index .checkout-onepage__head {
  display: none;
}
@media (min-width: 1024px) {
  .checkout-onepage-index .checkout-onepage__head {
    display: block;
  }
}
.checkout-onepage-index .checkout-onepage__title {
  padding: 0 20px;
  color: #00c6b2;
}
.main-container .opc li {
  margin: 0;
}
.main-container .opc li .step-title .number {
  margin-left: 20px;
  color: #66ddd1;
  font-style: italic;
}
.main-container .opc li .step-title h2 {
  color: #66ddd1;
  font-style: italic;
}
.main-container .opc li.active {
  margin: 0 0 20px;
}
.main-container .opc li.active .step-title {
  padding: 10px 0;
  background-color: #ffffff;
  border-top: 2px solid #00c6b2;
  border-bottom: 2px solid #00c6b2;
}
.main-container .opc li.active .step-title .number {
  margin-left: 20px;
  color: #000000;
  background: transparent;
  font-style: italic;
}
.main-container .opc li.active .step-title .number:before {
  display: inline-block;
  margin-right: 10px;
  font-family: FontAwesome;
  content: '\f00c';
}
.main-container .opc li.active .step-title h2 {
  color: #000000;
  font-style: italic;
}
.main-container .opc li.active.allow .step-title {
  padding: 20px 0 !important;
  border-bottom: 2px solid #00c6b2 !important;
}
.main-container .opc li.active.allow .step-title .number {
  color: #000000 !important;
}
.main-container .opc li.active.allow h2 {
  color: #000000 !important;
}
.main-container .opc li:first-child.allow .step-title {
  padding: 20px 0 10px;
}
@media (min-width: 1024px) {
  .main-container .opc li:first-child.allow .step-title {
    border-top: 2px solid #00c6b2;
  }
}
.main-container .opc li:last-child.active .step-title {
  padding: 0 0 10px;
}
.main-container .opc li.allow .step-title {
  padding: 0 0 10px;
  border-top: none;
  border-bottom: none;
}
.main-container .opc li.allow .step-title .number {
  margin-left: 20px;
  color: #66ddd1;
  background: transparent;
  font-style: italic;
}
.main-container .opc li.allow .step-title .number:before {
  display: inline-block;
  margin-right: 10px;
  font-family: FontAwesome;
  content: '\f00c';
}
.main-container .opc li.allow .step-title h2 {
  color: #66ddd1;
  font-style: italic;
}
#onepage-guest-register-button {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  background-color: #ff5f00 !important;
}
#onepage-guest-register-button:active,
#onepage-guest-register-button:focus,
#onepage-guest-register-button:hover {
  background-color: #000000 !important;
}
#onepage-guest-register-button span {
  line-height: 1.19 !important;
  height: auto !important;
}
.checkout-onepage-index .buttons-set a {
  -moz-transition: color 0.6ms !important;
  -webkit-transition: color 0.6ms !important;
  -o-transition: color 0.6ms !important;
  -ms-transition: color 0.6ms !important;
  transition: color 0.6ms !important;
  text-decoration: underline;
}
.checkout-onepage-index .buttons-set a:active,
.checkout-onepage-index .buttons-set a:focus,
.checkout-onepage-index .buttons-set a:hover {
  color: #00c6b2;
}
.checkout-onepage-index .buttons-set .button {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  background-color: #ff5f00 !important;
}
.checkout-onepage-index .buttons-set .button:active,
.checkout-onepage-index .buttons-set .button:focus,
.checkout-onepage-index .buttons-set .button:hover {
  background-color: #000000 !important;
}
.checkout-onepage-index .buttons-set .button span {
  line-height: 1.19 !important;
  height: auto !important;
}
.checkout-onepage-index .buttons-set .back-link a {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  color: #ff5f00;
  text-decoration: none;
  text-transform: uppercase;
}
.checkout-onepage-index .buttons-set .back-link a:active,
.checkout-onepage-index .buttons-set .back-link a:focus,
.checkout-onepage-index .buttons-set .back-link a:hover {
  color: #000000;
}
.main-container .opc li .step {
  border: none;
  border-bottom: 2px solid #00c6b2;
}
.main-container .opc li .step#checkout-step-review {
  border-bottom: none;
}
.main-container .opc li .step .col2-set {
  padding: 0 20px;
}
.main-container .opc li .step h3 {
  margin-bottom: 10px !important;
  font-size: 1.25rem !important;
  font-weight: 900 !important;
  border-bottom: none !important;
  letter-spacing: 0.01rem !important;
  text-transform: uppercase;
}
/**
*
 * content styles checkout, individual styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 16.06.16
 *
 */
.checkout-onepage__spacing {
  margin: 15px 0 25px !important;
}
.checkout-onepage__list {
  margin: 15px 0 25px !important;
}
.checkout-onepage__list li {
  display: inline-block;
  padding: 0 0 20px !important;
}
.checkout-onepage__list li:last-child {
  padding: 0 !important;
}
@media (min-width: 768px) {
  .checkout-onepage__list li {
    width: 50% !important;
    padding: 0 !important;
  }
}
.checkout-onepage__list li label {
  color: #000000 !important;
  font-size: 1.25rem;
  letter-spacing: 0.01rem !important;
  line-height: 1.19;
}
.checkout-onepage__form-list {
  margin: 15px 0 25px !important;
}
.checkout-onepage__form-list li {
  margin-bottom: 10px !important;
}
#billing-new-address-form .checkout-onepage__form-list li {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
#shipping-new-address-form .checkout-onepage__form-list li:first-child {
  margin-bottom: 0 !important;
}
.checkout-onepage__form-list label {
  top: 12px !important;
  color: #656565 !important;
}
.checkout-onepage__form-list label:before {
  top: 0 !important;
}
.checkout-onepage__form-list label[for="billing:country_id"],
.checkout-onepage__form-list label[for="billing:salutation"],
.checkout-onepage__form-list label[for="billing:region_id"],
.checkout-onepage__form-list label[for="billing-address-select"],
.checkout-onepage__form-list label[for="shipping:country_id"],
.checkout-onepage__form-list label[for="shipping:salutation"],
.checkout-onepage__form-list label[for="shipping:region_id"],
.checkout-onepage__form-list label[for="shipping-address-select"] {
  display: block;
  position: relative !important;
  top: -5px !important;
  left: 0 !important;
  width: auto;
  padding-left: 30px;
  color: #00c6b2 !important;
  font-style: italic !important;
  letter-spacing: 0.05rem !important;
}
.checkout-onepage__form-list label[for="billing:country_id"] em,
.checkout-onepage__form-list label[for="billing:salutation"] em,
.checkout-onepage__form-list label[for="billing:region_id"] em,
.checkout-onepage__form-list label[for="billing-address-select"] em,
.checkout-onepage__form-list label[for="shipping:country_id"] em,
.checkout-onepage__form-list label[for="shipping:salutation"] em,
.checkout-onepage__form-list label[for="shipping:region_id"] em,
.checkout-onepage__form-list label[for="shipping-address-select"] em {
  position: relative;
  top: auto;
  right: auto;
  margin-left: 3px;
  color: #656565;
}
#billing-new-address-form .checkout-onepage__form-list label[for="billing:email"]:before {
  content: '';
}
.checkout-onepage__buttons {
  margin: 0 0 20px !important;
  padding: 0 !important;
}
@media (min-width: 1024px) {
  .checkout-onepage__buttons {
    margin: 0 !important;
  }
}
.checkout-onepage__buttons--left {
  margin: 0 0 20px !important;
  padding: 0 !important;
}
@media (min-width: 1024px) {
  .checkout-onepage__buttons--left {
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  .checkout-onepage__buttons--left {
    margin: 20px 0 !important;
  }
}
@media (min-width: 1024px) {
  .checkout-onepage__buttons--left {
    text-align: left !important;
  }
}
.checkout-onepage__buttons--block {
  margin: 0 0 20px !important;
  padding: 0 !important;
}
@media (min-width: 1024px) {
  .checkout-onepage__buttons--block {
    margin: 0 !important;
  }
}
.checkout-onepage__buttons--block a {
  display: inline-block;
  margin: 10px 0;
  float: left;
}
#checkout-step-billing .checkout-onepage__buttons--block button,
#checkout-step-shipping .checkout-onepage__buttons--block button,
#checkout-step-payment .checkout-onepage__buttons--block button {
  float: right;
}
#checkout-step-login {
  padding: 10px 0;
}
#checkout-step-login .col-1 {
  border-bottom: 1px solid #dcdcdc;
}
@media (min-width: 768px) {
  #checkout-step-login .col-1 {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  #checkout-step-login .col-1 {
    width: 50%;
    padding-right: 10px;
    border-bottom: none;
  }
}
@media (min-width: 768px) {
  #checkout-step-login .col-2 {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  #checkout-step-login .col-2 {
    width: 50%;
    padding-left: 10px;
  }
}
.checkout-onepage__info-text {
  display: none;
}
@media (min-width: 768px) {
  .checkout-onepage__info-text {
    display: block;
  }
}
.chosen-container-single .chosen-single {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0;
  height: 40px;
  color: #656565;
  background: transparent;
  border: none;
  letter-spacing: 0.05rem;
  line-height: 3;
}
.chosen-container-single .chosen-single div b {
  background-position: 0 8px;
}
.chosen-container-active.chosen-with-drop .chosen-single {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: transparent !important;
  border: none !important;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: 0 8px;
}
.checkout-onepage__control-list {
  display: block;
  margin: 10px 0 20px;
  overflow: hidden;
}
.checkout-onepage__control-list li.control label {
  color: #000000;
  letter-spacing: 0.01rem !important;
}
.checkout-onepage__payment-list {
  margin: 10px 0;
}
.checkout-onepage__payment-list label {
  font-weight: 400;
  letter-spacing: 0.05rem;
}
.agreement-content {
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
}
@media (min-width: 768px) {
  .agreement-content {
    max-width: 300px;
  }
}
.agreement-content a {
  color: #ff5f00;
  text-decoration: none !important;
}
.checkout-cart-index .checkout-types,
#review-buttons-container {
  display: block;
  width: 100%;
  padding: 10px;
  float: none;
}
@media (min-width: 768px) {
  .checkout-cart-index .checkout-types,
  #review-buttons-container {
    width: 50%;
    max-width: 300px;
    margin: 10px;
    padding: 0;
    float: right;
    clear: both;
  }
}
.checkout-cart-index .checkout-types > .btn-checkout,
#review-buttons-container > .btn-checkout {
  width: 100%;
  margin: 10px 0;
}
.checkout-cart-index .checkout-types span,
#review-buttons-container span {
  letter-spacing: 0.05rem;
}
.checkout-cart-index .checkout-types span i,
#review-buttons-container span i {
  margin: 0 5px;
  font-weight: 900 !important;
}
#co-payment-form .input-box {
  margin: 0 0 30px;
}
#co-payment-form .payone__info-text {
  display: none;
}
/**
*
 * special styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 22.06.16
 *
 */
#checkout-review-table .col__product {
  width: 100px;
  max-width: 100px;
  padding-right: 0 !important;
}
@media (min-width: 768px) {
  #checkout-review-table .col__product {
    width: inherit;
    max-width: inherit;
    padding-right: 10px !important;
  }
}
#checkout-review-table .col__quantity {
  width: 40px;
  max-width: 40px;
  padding-left: 0 !important;
}
@media (min-width: 768px) {
  #checkout-review-table .col__quantity {
    width: inherit;
    max-width: inherit;
    padding-left: 10px !important;
  }
}
#checkout-review-table .col__price-single {
  width: 40px;
  max-width: 40px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
@media (min-width: 768px) {
  #checkout-review-table .col__price-single {
    width: inherit;
    max-width: inherit;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
#checkout-review-table .col__price-total {
  width: 20px;
  max-width: 20px;
  padding-left: 0 !important;
}
@media (min-width: 768px) {
  #checkout-review-table .col__price-total {
    width: inherit;
    max-width: inherit;
    padding-left: 10px !important;
  }
}
@media (min-width: 768px) {
  #checkout-review-table tfoot tr td:first-child {
    width: 30px;
    max-width: 30px;
  }
}
/**
*
 * sidebar checkout
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 24.06.16
 *
 */
.main-container .sidebar .block {
  border: none;
  background: #f3f3f3;
}
.main-container .sidebar .block dt {
  color: #656565;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.05rem;
}
.main-container .sidebar .block dt.complete {
  color: #000000;
}
.main-container .sidebar .block dt.complete:before {
  display: inline-block;
  margin-right: 10px;
  font-family: FontAwesome;
  content: '\f00c';
}
.main-container .sidebar .block dt.complete .changelink .separator {
  margin: 0 10px;
}
.main-container .sidebar .block dt.complete .changelink a {
  color: #00c6b2;
}
.main-container .sidebar .block dd.complete strong {
  font-weight: 400 !important;
}
@media (min-width: 768px) {
  .checkout-onepage-index .col-lg-3.sidebar {
    display: none;
  }
}
@media (min-width: 1200px) {
  .checkout-onepage-index .col-lg-3.sidebar {
    display: inline-block;
    margin-top: 55px;
  }
}
/**
*
 * main category styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 14.06.16
 *
 */
.category-products .products-grid .item .product-action .product__images,
.category-products .products-grid .item .product-action .product-image {
  border: none;
}
@media (min-width: 768px) {
  .messages {
    margin: 20px;
  }
}
@media (min-width: 1024px) {
  .messages {
    margin: 10px 15px 20px;
  }
}
#messages_product_view .messages {
  display: block;
  margin: 0 20px 20px;
}
.checkout-cart .messages {
  display: block;
  margin: 20px;
}
.messages li {
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
}
.messages li li:before {
  display: inline-block;
  margin-right: 10px;
  font-family: FontAwesome;
}
.messages .success-msg {
  color: #00c6b2;
}
.messages .success-msg li:before {
  content: '\f00c';
}
.messages .notice-msg {
  color: #ff5f00;
}
.messages .notice-msg li:before {
  content: '\f061';
}
.checkout-cart .messages .notice-msg {
  color: #ff6666;
}
.checkout-cart .messages .notice-msg li:before {
  content: '\f05c';
}
.messages .error-msg {
  color: #ff6666;
}
.messages .error-msg li:before {
  content: '\f05c';
}
/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 24.06.2016
 */
.cms-home .home-banner .inner-banner .overlay {
  display: none;
}
.cms-home .home-banner .inner-banner:hover .banner-img img {
  transform: none;
  transition: unset;
}
.cms-home .main-container .shop-now a {
  -moz-transition: 0.6ms !important;
  -webkit-transition: 0.6ms !important;
  -o-transition: 0.6ms !important;
  -ms-transition: 0.6ms !important;
  transition: 0.6ms !important;
  background-color: #66ddd1 !important;
  color: #ffffff !important;
}
.cms-home .main-container .shop-now a:active,
.cms-home .main-container .shop-now a:focus,
.cms-home .main-container .shop-now a:hover {
  background-color: #00c6b2 !important;
}
.cms-home .main-container .category__description--wrapper .is-shorten-text {
  margin-top: 30px;
  margin-bottom: 30px;
}
.cms-home h3.title-widget {
  border-bottom: 2px solid #00c6b2;
  text-align: left !important;
  margin: 0 15px !important;
}
.cms-home h3.title-widget:after {
  background-color: unset;
  height: 0 !important;
}
.cms-home h3.title-widget span {
  border: none !important;
  color: #00c6b2;
  text-align: left !important;
  font-weight: 900 !important;
}
.cms-home .widget-products-new {
  margin-bottom: 48px;
}
.slider-title--dark {
  color: #696969 !important;
}
.home-teaser {
  text-align: center;
  margin: 30px auto;
  max-width: 1200px;
}
.home-teaser__item {
  margin-bottom: 48px;
}
.home-teaser__text p {
  color: #000000;
  font-size: 1rem;
}
.home-teaser__link a {
  color: #00c6b2;
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
.home-teaser__link a:after {
  font-family: FontAwesome;
  padding-left: 10px;
  content: "\f178";
}
.home-teaser__link a:hover {
  font-weight: 900;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table .visible-xs {
    display: table;
  }
  tr .visible-xs {
    display: table-row !important;
  }
  th .visible-xs,
  td .visible-xs {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .visible-sm {
    display: block !important;
  }
  table .visible-sm {
    display: table;
  }
  tr .visible-sm {
    display: table-row !important;
  }
  th .visible-sm,
  td .visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table .visible-md {
    display: table;
  }
  tr .visible-md {
    display: table-row !important;
  }
  th .visible-md,
  td .visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table .visible-lg {
    display: table;
  }
  tr .visible-lg {
    display: table-row !important;
  }
  th .visible-lg,
  td .visible-lg {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs,
  .right-header-menu .header-setting.header-service {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
html {
  font-size: 13px;
}
@media (min-width: 768px) {
  .catalog-category-view .main-wrapper,
  .checkout-cart-index .main-wrapper,
  .main-container .main-wrapper {
    margin-top: 20px !important;
  }
}
@media (min-width: 1024px) {
  .catalog-category-view .main-wrapper,
  .checkout-cart-index .main-wrapper,
  .main-container .main-wrapper {
    margin-top: 0 !important;
  }
}
