/**
*
 * global styles, not ui
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 28.05.16
 *
 */

// label sale style
.product-sale-label {
    display: inline-block;
    top: @spacing-s !important;
    right: auto !important;
    left: @spacing-s;
    padding: 10px 14px !important;
    color: @black;
    font-size: @base-font-size !important;
    background-color: @yellow;
    border-radius: 50%;

    .catalog-product-view & {
        margin: @spacing-s;
        font-weight: @font-weight-bold;
    }

    .product-shop-wrapper & {

        .screen-md({ float: left; });
    }
}

.label-square {
    position: absolute;
    top: auto !important;
    bottom: 0;
    padding: @spacing-xs @spacing-s !important;
    color: @black;
    font-size: @base-font-size;
    font-weight: @font-weight-bold;
    text-transform: uppercase;
    z-index: 6;
}

// label new style
.product-new-label {
    .label-square();

    left: 0;
    background-color: @yellow;
}

// label more choices
.product-choice-info {
    .label-square();

    right: 0;
    background: @white;
}

.tag-product-list {

    .row {

        > .col-lg-3 {
            display: none;
            width: 0 !important;
        }

        > .col-lg-6 {
            width: 100% !important;
        }
    }
}

#v-cookielaw {

    .v-message {
        text-align: center;
    }
}