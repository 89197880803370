.category-description, .home_description_collapsable_4 {
    a {
        color: @black;
        font-weight: @font-weight-bold;
        text-decoration: underline;
        font-size: @font-size-m;
        font-weight: @font-weight-bold;
        letter-spacing: @letter-spacing-xxs;

        &:hover {

            cursor: pointer;
        }
    }

    p {

        a {
            font-size: @font-size-m;
            letter-spacing: @letter-spacing-xxs;
        }

        &:nth-of-type(2) {

            a {
                text-transform: uppercase;
                text-decoration: none;
                color: @mint;
                font-size: @font-size-m;
                font-weight: @font-weight-bold;
                letter-spacing: @letter-spacing-xxs;

                &:after {
                    content: "\f178";
                    font-family: FontAwesome;
                    padding-left: 4px;
                }
            }
        }
    }
}

.category-description-after {
    text-align: center;
    padding-top: 30px;
    padding-bottom: @spacing-m;

    p:last-of-type {
        padding-bottom: 30px;

        a {
            text-decoration: underline;
            color: @black;

            &:after {
                text-decoration: none;
                content: "\f178";
                font-family: FontAwesome;
                display: inline-block;
                padding-left: 4px;
            }
        }
    }

    &:after {
        content: url('/media/wysiwyg/icotheme/puro/icons/wavy-line.png');
    }
}

.read-more {
    color: @black;
    font-weight: @font-weight-bold;
    text-decoration: underline;
    float: right;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}