.footer-wrapper {
    margin-top: 60px;
    border-top: none;

    .screen-xs({ margin-top: 80px; });

    .flex__newsletter-category-description {
        .display-flex();

        flex-direction: column;
        margin: 0;
        padding: 0;
        max-width: inherit !important;
    }

    #newsletter-input {
        height: 35px;
        color: @mint;
        text-align: center;
        font-style: italic;
    }

    .block-subscribe {
        padding: 45px 0;
        background-color: @grey-lighter;
    }

    .newsletter__subscribe--header,
    .newsletter__subscribe--email {
        margin-bottom: 20px
    }

    .newsletter__subscribe--button {
        background-color: @mint !important;
        color: white !important;
        border: none;
        text-transform: uppercase;
    }

    .category__description--wrapper {
        padding-bottom: 45px;

        .read-more {
            float: inherit;
        }
    }

    .footer-block-title {
        margin: 15px 0;
        text-transform: uppercase;

        span {
            color: @black;
        }

        h1, h3 {
            font-size: @font-size-l;
            text-transform: uppercase;
            letter-spacing: @letter-spacing-s;

            margin-top: 0 !important;
            margin-bottom: 0 !important;
            line-height: 35px;
        }
    }

    .newsletter-block-title {
        font-size: @font-size-l;
        text-transform: uppercase;

        span {
            color: @black;
        }
    }

    .footer-list {
        margin: 30px 0 !important;
    }

    .footer__additional-sections {

        .footer__list--entry {
            border: none;
            padding: 10px 0;

            &:last-of-type {
                border-bottom: none !important;
            }

            .footer__list--link {

                .transition(inherit);

                padding-left: 0 !important;
                color: @black;
                font-size: @font-size-m;

                &:before {
                    display: none !important;
                }

                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                    font-weight: @font-weight-bold;

                }
            }
        }
    }

    .footer__third-area--wrapper {
        background-color: @white;
    }

    .footer__third-area {
        padding: 20px 0;
        background-color: @white;

        .footer__third-area--left {

            .footer__list--entry {
                float: left;
                padding-right: 35px;
            }

            .footer__third-area--links {

                .footer__list--entry:not(.vat-info) {
                    a {
                        &:hover,
                        &:focus,
                        &:active {
                            font-weight: @font-weight-bold;
                        }
                    }
                }

                .vat-info {
                    font-size: @font-size-m;
                    letter-spacing: @letter-spacing-xxs;

                    a {
                        color: @orange;
                    }
                }
            }
        }

        .footer__third-area--right {

            .footer__third-area--social-icons {
                width: 100px;
                margin-left: auto;
                margin-right: auto;
                padding-top: @spacing-xs;
                font-size: @font-size-xl;

                .footer__third-area--social-icon {
                    color: @black;

                    &:hover,
                    &:active,
                    &:focus {
                        color: @orange;
                    }
                }
            }
        }
    }

    .list-footer {
        padding: 0 15px;
    }

    .footer-top {
        background-color: @grey-lighter;

        .vat-info {
            display: none;
        }

        .about-social {
            .hidden-xs();
        }
    }

    .about__title {
        margin-top: 15px;

        font-size: @font-size-l;
    }

    .footer-bottom {
        padding-top: 0;
    }

    .footer__second-area {
        padding: 0;

        a {
            font-weight: @font-weight-bold;
        }

        .footer-links .row {
            border-top: none;
        }

        .list-footer {
            padding: 0;
        }

    }

    .footer__second-area--contact {
        .fa-envelope-o,
        .fa-phone {
            float: left;
            padding-right: 10px;
            margin-top: 2px;
            font-size: @font-size-l;
        }
        .footer__second-area--contact-slogan,
        .footer__second-area--contact-hours {
            display: block;
        }
    }

    .footer__vat-info {
        background-color: @white;
        text-align: center;

        li:not(.vat-info) {
            display: none;
        }

        .footer-list {
            margin: 0 !important;
        }
    }

    @media (min-width: @screen-sm) {
        background-color: @white;
        overflow: hidden;

        .flex__newsletter-category-description {
            display: flex;
            flex-direction: column-reverse;
        }

        #newsletter-input {
            height: 35px;
            color: @mint;
            text-align: left;
            font-style: italic;
        }

        .newsletter__subscribe--button {
            background-color: @mint !important;
            color: white !important;
            border: none;

        }

        .form-subscribe-header {
            padding-top: 15px;
            overflow: hidden;
            clear: both;
        }

        .newsletter__subscribe--image {
            margin-top: -15px;
        }

        .category__description--wrapper {
            width: auto;

            .read-more {
                float: right;
            }
        }

        .footer-top {
            .vat-info {
                display: block;
            }

            .about-social {
                padding: 0 15px 20px 15px;
            }
        }

        .newsletter-block-title {

            font-size: @font-size-l;
            text-transform: uppercase;

            span {
                color: @black;
            }
        }

        .footer__additional-sections {
            border-left: none;

            .vat-info {
                display: none;
            }

            h3{
                font-size: @font-size-l!important;
                letter-spacing: @letter-spacing-s!important;
            }
        }

        .footer__third-area--links {

            .footer__list--entry {
                border-bottom: none;
                padding-right: @spacing-s !important;

                &:not(.vat-info) {
                    display: none;
                }
            }
        }

        .footer__second-area {
            background-color: @orange;
            padding: 45px 0;

            * {
                color: @white !important;
            }

            .footer-links {
                margin: 0;
            }

            .footer-container {

                .column:not(:last-child) {
                    padding-right: 50px;
                }

                .header {
                    text-transform: uppercase;
                    font-size: @font-size-l;
                }

                .footer__second-area--blog {
                    flex: 2;

                    .footer__second-area--blog-info-wrapper {
                        display: flex;

                        .footer__second-area--blog-date {
                            flex: 1;
                        }
                        .footer__second-area--blog-text {
                            flex: 4
                        }
                    }

                }

                .footer__second-area--payment {
                    flex: 1;

                    p:last-of-type {
                        margin-bottom: 0;
                    }

                    .footer__second-area--payment-text {
                        p:first-of-type {
                            clear: both;
                        }
                    }
                }

                .footer__second-area--contact {
                    flex: 1;

                    .footer__second-area--contact-slogan,
                    .footer__second-area--contact-hours {
                        display: block;
                    }
                }

                .icons {
                    display: inline-block !important;
                    position: relative;
                    left: 10px;
                    bottom: 1px;
                    font-size: @font-size-l;
                }
            }
        }

        .footer__third-area--wrapper {
            padding-top: @spacing-s;
        }

        .footer__third-area--left {
            padding-top: @spacing-s;
        }

        .footer__third-area {
            padding: 0 0 20px 0;
        }

        .footer__third-area--social-icons {
            margin-left: 0 !important;
            font-size: @font-size-l !important;

            .facebook {
                padding-left: 0;
            }
        }

        .footer-bottom {
            padding-top: 30px;
            background-color: @grey-lighter;
        }

        .footer-list {
            margin: 0 !important;
        }

        .footer__vat-info {
            .visible-xs();
        }

        .footer-links {
            .row {
                border-top: none;
            }
            .info-footer:last-child {
                border-bottom: none;
            }
        }

        .custom-footer-content {
            display: none;
        }

        .about__title {
            margin-top: 0;
        }
    }

    @media (max-width: @screen-sm-max) {

        .footer-links {

            .row {
                border-top: 1px dashed @black;
            }

            .info-footer {
                border-bottom: 1px dashed @black;
            }
        }
    }

    @media (min-width: @screen-sm2) {

        .about-social {
            width: 58.333333333333336%;
        }

        .footer__additional-sections {
            width: 41.66666666666667%;

            .info-footer {
                width: 100%;
            }
        }
    }

    @media (min-width: @screen-md) {

        .about-social {
            width: 50%;
        }

        .footer__additional-sections {
            width: 50%;
        }

        .footer-block-title {
            margin: 0 0 15px 0 !important;
            font-size: @font-size-l !important;
        }

        .footer-list {
            margin: 0 !important;
        }

        .footer__list--entry {
            border-bottom: 1px dashed @mint !important;
            padding: 10px 0;

            &:first-of-type {
                margin-top: 0;
            }

            .footer__list--link {
                padding-left: 0 !important;
                color: @black;
                font-size: @font-size-m!important;
                letter-spacing: @letter-spacing-xxs!important;

                &:before {
                    display: none !important;
                }

                &:active {
                    border-bottom: none;
                }
            }

        }

        .footer__third-area--links {
            .footer__list--entry {
                border-bottom: none !important;
                display: block !important;
            }
        }

        .custom-footer-content {
            display: block;
        }

        .footer__second-area {

            .footer-container {
                border: none;

            }

            .footer__blog {
                margin-bottom: @spacing-s;
            }

            .read-more {
                text-transform: uppercase;
                text-decoration: none;
                float: left;

                &:after {
                    content: "\f178";
                    font-family: FontAwesome;
                    padding-left: @spacing-xs;
                }
            }
        }

        .footer__second-area--blog-date {
            padding: unset;
        }

        .footer__third-area--left {
            padding-top: 0;
        }

        .footer__third-area--copyright {
            padding: @spacing-s 0;
            font-size: @font-size-m;
            letter-spacing: @letter-spacing-xxs;
        }

        .footer__additional-sections {
            .footer-links {
                margin: unset;
            }

            .info-footer {
                width: 50%;
            }
        }
    }

    //==============

    .footer-container {

        .about-text {
            font-size:@font-size-m;
            letter-spacing: @letter-spacing-xxs !important;

            a {
                color: @black;
                background-color: @mint;
                font-size: @font-size-m;
                font-weight: @font-weight-normal;
                letter-spacing: @letter-spacing-xxs;
            }
        }
    }

    .footer__second-area--contact-mail {
        clear: both;
    }

    // third area - right (social links)
    .footer__third-area--social-link {
        float: left;
        font-size: @font-size-l;
        padding: 5px 15px 0 15px;
        color: @black;

        &:hover {
            color: @orange !important;
        }
    }

    .mobile-button {
        background-color: transparent !important;
    }
}

#newsletter-validate-detail {

    .screen-sm({ max-width: 700px; margin: 0 auto; });
    .screen-md({ max-width: 1000px; });
    .screen-md({ max-width: 1200px; });
}
