//noinspection ALL
.category-products {

    .product-action {

        .actions {
            bottom: auto;
            top: 1px;
            height: 0;
        }

        .fa-heart {
            color: @mint;
        }

        .link-wishlist {
            background-color: transparent !important;
        }
    }

    .products-grid {

        .item {
            height: auto !important;
            margin-top: @spacing-m;

            &:nth-child(-n+3) {

                .screen-lg({ margin-top: 0; });
            }
        }
    }

    .add-to-links {

        .compare {
            display: none;
        }

        .wishlist {
            display: none;

            .screen-md({ display: inherit; });
        }

        li {
            a:before {
                border-right: 0;
            }
        }
    }

    .addtocart {
        .flex(2);
    }

    .product-name {
        clear: left;
        font-size: @font-size-m;
        font-weight: @font-weight-bold;
        letter-spacing: @letter-spacing-m;
    }

    .product-name__link {
        color: @black;
        text-transform: none !important;
        font-size: @font-size-m !important;
        font-weight: @font-weight-normal !important;
        padding-bottom: @spacing-xs;
    }

    .addToCart__qty,
    .addToCart__label {

        text-align: left;

        label.error {
            position: absolute;
            left: 0px;
            background-color: @mint-light;
            border: 1px solid @mint-darker;
            z-index: 1;
            padding: @spacing-s;
            width: @product-box__width;
            top: @spacing-xl;
        }

        .qty {
            margin-right: @spacing-s;
        }
    }

    .product-content {
        padding: @spacing-xs 0 0 0 !important;
        text-align: left;
    }

    .additionalInfo__entry {
        font-weight: @font-weight-light;

        &:first-of-type {
            padding-top: @spacing-xs;
        }
    }

    .additionalInfo__contentsAndProductInfo {
        font-size: @font-size-m;
        font-weight: @font-weight-light;
    }

    .additionalInfo__contentsAndProductInfo,
    .product-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .additionalInfo__tierPrice {

        li:not(:first-of-type) {
            display: none;
        }

        .tier-prices {
            font-size: @font-size-m;
            font-weight: @font-weight-bold;
        }
    }

    .price-box {
        padding-top: 0 !important;
    }

    .additionalInfo__prices {

        .price-box {

            > :not(.old-price) {
                display: none !important;
            }

            .old-price {
                padding-right: 0;
                margin-bottom: 0;

            }

            .old-price,
            .old-price-label {
                font-weight: @font-weight-bold;
            }
        }
    }

    .img-responsive {
        margin: 0 auto;

        &.hidden-tablet,
        &.hidden-phone {
            opacity: 0;
            visibility: hidden;

            .screen-md ({ opacity: 1; visibility: visible; });
        }
    }

    .product-boxes__buttons {
        .display-flex();
        .justify-content(space-between);

        margin: @spacing-s 0 0;
    }

    .add-to-cart__label--pdf {
        display: block;
        padding: 0 @spacing-s 0 0;
        color: darken(@grey-light, 15%);
        font-size: @font-size-xxl;
    }

    .product-choice-info {
        position: absolute;
        top: auto;
        padding: @spacing-xs @spacing-s;
        right: @spacing-xs;
        bottom: 0;
        color: @black;
        font-size: @base-font-size;
        font-weight: @font-weight-bold;
        text-transform: uppercase;
        z-index: 6;
    }

    .product__images {

        img.alt-img {
            opacity: 0;
            visibility: hidden;

            .screen-xs({ opacity: 0; });
            .screen-md ({ visibility: inherit; });
        }
    }

    .toolbar {

        @media (min-width: @screen-lg-min) {
            margin-right: 185px;
        }

        .pager {

            ol {

                li {
                    border: none !important;

                    a {
                        color: @mint;
                    }

                    &.current {
                        background: @mint;
                    }

                    &:hover {
                        background-color: @mint !important;
                    }
                }
            }
        }
    }
}
