.category__tag-list--wrapper {
    padding: 25px;
    border-left: 1px solid #EFEFEF;
    background-color: @color__catgeory--tag-box;
    overflow: hidden;
    height: 100%;

    .catgeory__tag-list--header {
        line-height: normal;
        padding: @spacing-s 0 0 0;
        text-transform: uppercase;
        font-family: @font-family;
        font-weight: @font-weight-normal;
        letter-spacing: @letter-spacing-s;
        font-size: @font-size-m;
        color: @mint;
    }

    .category__tag-list--list-wrapper {
        overflow: auto;
        max-height: 78%;


    }

    a.category__tag-list--link {
        padding: 35px 0 0 0;
        line-height: 30px;
        text-transform: uppercase;
        font-size: @font-size-s;
        font-weight: @font-weight-normal;
        color: @mint;
        letter-spacing: @letter-spacing-xs;

        &:active,
        &:focus,
        &:hover {
            text-decoration: underline;
        }

        span{
            padding: 0 !important;
        }
    }

    li {
        border: 1px solid #EFEFEF;
        border-radius: 10px;
        float: left;
        margin: 10px 10px 0 0 !important;
        background-color: @white !important;

        span {
            padding: 3px 10px !important;
            font-size: @font-size-m;
            letter-spacing: @letter-spacing-xxs;
        }

        &:hover {
            background-color: @color__default--highlight !important;
            color: @white;

            a {
                color: inherit !important;  color: red;

                span {
                    background-color: transparent !important;
                }
            }
        }

    }

}