.transition (@transition) {
    -moz-transition: @transition !important;
    -webkit-transition: @transition !important;
    -o-transition: @transition !important;
    -ms-transition: @transition !important;
    transition: @transition !important;
}

.transform(@string) {
    -webkit-transform: @string !important;
    -moz-transform: @string !important;
    -ms-transform: @string !important;
    -o-transform: @string !important;
    transform: @string !important;
}

.responsive-visibility() {
    display: block !important;

    table & {
        display: table;
    }

    tr & {
        display: table-row !important;
    }

    th &,
    td & {
        display: table-cell !important;
    }
}

.responsive-invisibility() {
    display: none !important;
}

// Flexbox display
// flex or inline-flex
.display-flex(@display: flex) {
    display: ~"-webkit-@{display}"!important;
    display: ~"-moz-@{display}"!important;
    display: ~"-ms-@{display}box"!important; // IE10 uses -ms-flexbox
    display: ~"-ms-@{display}"!important; // IE11
    display: @display;
}

.justify-content(@justify-content: flex-start) {
    -webkit-justify-content: @justify-content;
    justify-content: @justify-content;
}


.align-items(@align-items: center) {
    -webkit-align-items: @align-items;
    align-items: @align-items;
}

.align-self(@align-self: center) {
    -webkit-align-self: @align-self;
    align-self: @align-self;
}

.flex(@flex) {
    flex: @flex;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
.flex-wrap(@wrap: nowrap) {
    -webkit-flex-wrap: @wrap;
    -moz-flex-wrap: @wrap;
    -ms-flex-wrap: @wrap;
    flex-wrap: @wrap;
}

.order(@order) {
    order: @order;
}

.screen-xs(@rules) {
    @media (min-width: @screen-xs) {
        @rules();
    }
}

.screen-sm(@rules) {
    @media (min-width: @screen-sm) {
        @rules();
    }
}

.screen-sm2(@rules) {
    @media (min-width: @screen-sm2) {
        @rules();
    }
}

.screen-md(@rules) {
    @media (min-width: @screen-md) {
        @rules();
    }
}

.screen-md-2(@rules) {
    @media (min-width: @screen-md-2) {
        @rules();
    }
}

.screen-lg(@rules) {
    @media (min-width: @screen-lg) {
        @rules();
    }
}

.screen-2lg(@rules) {
    @media (min-width: @screen-2lg) {
        @rules();
    }
}

.screen-custom(@width, @rules) {
    @media (min-width: @width) {
        @rules();
    }
}

.screen-landscape(@rules) {
    @media (orientation: landscape) {
        @rules();
    }
}

.screen-portrait(@rules) {
    @media (orientation: portrait) {
        @rules();
    }
}

.webkit-appearance(@value: none) {
    -webkit-appearance: @value;
    -moz-appearance: @value;
    appearance: @value;
}

.border-radius(@radius) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.shadow(@value: none) {
    -webkit-box-shadow: @value;
    -moz-box-shadow: @value;
    box-shadow: @value;
}