/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 03.06.2016
 */
.product-view {

    .product-shop {

        .middle-product-detail {

            .price-box {

                .special-price,
                .regular-price {

                    .price {
                        font-weight: @font-weight-bold;
                    }
                }

                .special-price {

                    .price {
                        color: @orange-dark;
                    }
                }

                > .old-price {
                    margin-bottom: @spacing-xs;
                    text-decoration: line-through;
                }
            }

            .product-baseprice {
                display: none;
                font-size: @font-size-m;
                color: @black;

                .price {
                    font-size: @font-size-m !important;
                    font-weight: @font-weight-normal !important;
                    color: @black !important;
                }
            }

            .old-price-label,
            .old-price {
                color: @color__attributes;
                font-size: @font-size-m;
                text-decoration: line-through;
            }

            .tier-prices {
                color: @black;
                font-size: @font-size-l;
                padding: @spacing-s;

                .price {
                    color: @orange-dark;
                }
            }

            .tier-baseprice {
                color: @orange-dark;
                font-size: @font-size-s;
                font-weight: @font-weight-normal;
            }
        }

    }

}