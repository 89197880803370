/**
 *
 * all table default styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 08.06.16
 *
 */

table {
    margin: 0 0 @spacing-m;
    border: none !important;
    border-color: transparent !important;
}

thead {
    background: @grey-lighter;
    border-bottom: 1px solid @grey-light !important;

    #shopping-cart-table & {
        background: transparent;

        td {
            padding: @spacing-s 0;
        }
    }
}

th {
    padding: @spacing-sm @spacing-s !important;
    color: @black !important;
    font-weight: @font-weight-normal !important;
    letter-spacing: @letter-spacing-3xs;
    line-height: @line-height-m !important;
    text-align: unset !important;

    &.a-left {
        text-align: left !important;
    }
}

tbody {

    tr {
        border-bottom: 1px solid @grey-light !important;

        td {
            padding: @spacing-sm @spacing-s !important;
            font-size: @font-size-sm !important;

            &.a-left {
                text-align: left;
            }

            &.last {

                .cart-price {
                    font-weight: @font-weight-bold;
                }
            }
        }
    }

    .product-name {
        margin: 0 !important;
        font-weight: @font-weight-bold;
        line-height: @line-height-l;
        text-align: left;
        text-transform: inherit !important;
    }
}

tfoot {

    tr {
        background: @grey-lighter;
        border-bottom: 1px solid @grey-light !important;

        &.table--totals,
        &.table--tax {
            background: transparent;
        }

        td {
            padding: @spacing-sm @spacing-s !important;

            &:first-child {
                text-align: left !important;

                .screen-sm({ text-align: right !important; });

                span {
                    font-style: @font-style-italic;
                }
            }

            a {
                text-decoration: underline;
            }
        }

        &.table--totals {
            border-bottom: none !important;

            td {
                padding: @spacing-sm @spacing-s @spacing-xs !important;
            }

            span,
            strong {
                color: @orange-dark;
                font-size: @font-size-xxl;
                font-weight: @font-weight-normal !important;
                letter-spacing: @letter-spacing-xxs;
            }
        }

        &.table--tax {
            padding: 0 @spacing-s;
            font-style: @font-style-italic;
            font-weight: @font-weight-normal;
            border-bottom: none !important;

            td {
                padding: 0 @spacing-s !important;
                color: @color__attributes;
            }
        }
    }
}