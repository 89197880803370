/**
*
 * product detail styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 28.05.16
 *
 */

// image styles
.product-view .product-img-box {
    display: block;

    .product-img-list .product-image .product-image-gallery {
        border: none;
    }
}

// swipe pagination
.owl-theme .owl-controls .owl-page {

    span {
        background: @grey-light;
    }

    &.active {

        span {
            background: @black;
        }
    }
}

// product description
.product-view {

    // hide download description
    .product-options {
        padding: 0;

        dl {
            margin: 0;
            padding: 0;

            dt label {
                display: none;
                margin: 0;
                padding: 0;
            }

            .options-list {
                display: none;
                margin: 0;
                padding: 0;
            }
        }
    }

    .product-shop {
        width: 100%;
        margin: @spacing-xl 0;

        .screen-md({ width: 50%; margin: 0; });

        .product-shop-wrapper {
            border: none;

            .product-new-label {
                display: none;
            }

            .product-prev-next {
                display: none;
            }
        }

        .product-name {
            margin: @spacing-xs 0 @spacing-m;
            padding: 0;

            .screen-md({ display: inline-block; margin: (@spacing-xs + @spacing-s) @spacing-s; });

            h2 {
                font-size: @font-size-xxl;
            }
        }

        .middle-product-detail {
            border-top: none;

            .additionalInfo__entry {
                margin: @spacing-s 0;
                font-size: @font-size-m;
                color: @color__attributes;

                .screen-md({ margin: @spacing-s; });
            }

            .review-product-details {
                padding: 0;
            }
        }

        .product-type-data {
            padding: 0;

            .screen-md({ padding: 0 @spacing-s; });

            .col2-set {

                > .col-2 {
                    width: 100%;
                }
            }
        }

    }

    .add-to-cart {
        padding: 0 !important;

        .screen-md({ padding: 0 @spacing-s; });
    }
}

.product-tags__list {
    overflow: hidden;
}

.product-tags__list--element {
    .transition(background .6ms);

    margin: @spacing-xs;
    padding: @spacing-xxs @spacing-s;
    float: left;
    background: @grey-darkest;

    &:active,
    &:focus,
    &:hover,
    &:visited {
        background-color: @color__button--background;

        .products-tags__list--link {
            color: @white;
        }
    }
}

.products-tags__list--link {
    padding: @spacing-xxs @spacing-xs;
    color: @white;
    letter-spacing: @letter-spacing-3xs;
    text-transform: uppercase;
}

.product-tags__headline {
    margin: @spacing-m @spacing-xs;
    font-size: @font-size-l !important;
    letter-spacing: @letter-spacing-3xs;
    text-transform: uppercase;

    .screen-md({ margin: 0 @spacing-xs @spacing-m; });

    .fa {
        .transform(rotate(90deg));

        margin: -2px @spacing-xs 0 0;
    }
}

.product-info-addons {
    padding: @spacing-s;

    .screen-md({ padding: @spacing-xs @spacing-s; });

    &.product-info-addons__configurable {
        padding: @spacing-xs 0;
    }

    li {
        margin: @spacing-xxs 0;
        color: @mint;
        font-size: @font-size-m;
        font-style: @font-style-italic;

        &:before {
            font-family: FontAwesome;
            padding: @spacing-xs @spacing-xs @spacing-xs 0;
            content: "\f00c";
        }

        &:last-child:before {
            content: "\f292";
        }
    }
}

.free-pdf__info {
    color: @color__attributes;
    font-size: @font-size-l;
    padding: @spacing-s;

    span {
        color: @orange-dark;
    }
}

.actions-wrapper__base {

    //.screen-sm({ margin: 0 @spacing-s; });
}

.product-infobox__description {
    margin: @spacing-s 0;
}

.container__product-infobox {

    .additionalInfo__contentsAndProductInfo {
        margin-bottom: @spacing-m;
        font-size: @font-size-l;
        color: @color__attributes;
    }
}

.widget-products-related {

    h2 {
        color: @mint;
        font-size: @font-size-xl;
        font-weight: @font-weight-bold;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 0 @spacing-sm;
    }

    hr {
        border-color: @mint;
        border-width: 2px;
        margin: @spacing-sm;
    }

    .category-products {

        .product-content {
            text-align: left !important;
        }

        .addtocart {

            button i {
                position: absolute;
                margin-right: 0;
            }

        }
    }

}