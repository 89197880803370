/**
 * @autor: Marco Dinse (dinse@ironshark.de)
 * Date: 24.06.2016
 */

.cms-home {

    .home-banner {

        .inner-banner {

            .overlay {
                display: none;
            }

            &:hover {

                .banner-img {

                    img {
                        transform: none;
                        transition: unset;
                    }
                }
            }
        }
    }

    .main-container {

        .shop-now {

            a {
                .button--primary();

                color: @white !important;
            }
        }

        .category__description--wrapper {

            .is-shorten-text {
                margin-top: @spacing-l;
                margin-bottom: @spacing-l;
            }
        }

    }

    h3.title-widget {
        border-bottom: 2px solid @mint;
        text-align: left !important;
        margin: 0 @spacing-sm !important;

        &:after {
            background-color: unset;
            height: 0 !important;
        }

        span {
            border: none !important;
            color: @mint;
            text-align: left !important;
            font-weight: @font-weight-bold !important;
        }
    }

    .widget-products-new {
        margin-bottom: @spacing-xl;
    }
}

.slider-title--dark {
    color: @puro-settings__color--footer-text-color !important;
}

.home-teaser {
    text-align: center;
    margin: @spacing-l auto;
    max-width: 1200px;
}

.home-teaser__item {
    margin-bottom: @spacing-xl;
}

.home-teaser__text {

    p {
        color: @black;
        font-size: @font-size-m;
    }
}

.home-teaser__link {

    a {
        color: @mint;
        font-weight: @font-weight-bold;
        font-size: @font-size-m;
        letter-spacing: @letter-spacing-xs;
        text-transform: uppercase;

        &:after {
            font-family: FontAwesome;
            padding-left: @spacing-s;
            content: "\f178";
        }

        &:hover {
            font-weight: @font-weight-bold;
        }
    }
}