//noinspection ALL
.sidebar {
    .block-content {
        display: none;

        .screen-md({ display: block; });
    }

    .block-nav {
        display: none;
        border-bottom: none !important;
        background-color: @white !important;

        .screen-md({ display: block });

        .block-title span {
            font-size: @font-size-l !important;
            font-weight: @font-weight-bold !important;
        }
    }

    #narrow-by-list {
        dt {
            color: @mint;
            font-size: @font-size-m !important;
            border-bottom: none !important;
            padding-bottom: 0 !important;
        }

        dd[class*='filter-group']:not(.filter-group__preis) {
            padding-top: 0 !important;

            li {
                font-size: @font-size-s;
                letter-spacing: @letter-spacing-xs;

                a {
                    background-color: @grey-dark !important;
                    color: @white !important;
                    text-transform: uppercase;

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: @mint !important;
                        border: 1px solid transparent !important;
                    }

                }

            }

            &.filter-group__farbe {
                li {

                    .swatch-link {
                        background-color: transparent !important;
                        color: @black !important;

                        &:hover,
                        &:focus,
                        &:active {
                            border: 1px solid @black !important;
                        }
                    }
                }

                .swatch-link .has-image {
                    background-color: @white !important;

                    &:hover,
                    &:focus,
                    &:active {
                        border: 1px solid @black !important;
                    }
                }
            }
        }

        .filter-group__preis {

            ol {
                padding-top: @spacing-s;
            }
        }

        #categories_nav {

            a {
                height: auto;
            }
        }
    }

    #categories_nav {

        li {

            span {
                font-size: @font-size-s !important;
            }

            &.level-top {

                > a span {
                    font-weight: @font-weight-bold;
                }
            }

            &.active {

                > ul {

                    .active {
                        background-color: @puro-settings__color--background-alternative;

                        > a span {
                            font-weight: @font-weight-bold;
                        }
                    }
                }
            }
        }
    }

    .block-layered-nav {
        background-color: @white;

        .block-title span {
            font-size: @font-size-l !important;
            font-weight: @font-weight-bold !important;
        }

        .actions {

            a {
                font-size: @font-size-m;
                font-weight: @font-weight-bold;
                letter-spacing: @letter-spacing-xxs;
            }
        }
    }

    /*
    %%%%%%%%%%%%%%%%%%%
    %%% result view %%%
    %%%%%%%%%%%%%%%%%%%
    */

    .currently {

        li {
            .value {
                float: none !important;
            }
        }

        .block-subtitle {
            color: @mint;
            font-size: @font-size-s !important;
            border-bottom: none !important;
            padding-bottom: 0 !important;
            margin-bottom: 0;
        }
    }

    .actions {
        padding-top: 0 !important;
    }
}
