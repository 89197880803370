
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    .responsive-invisibility();
}

.visible-xs {

    @media (max-width: @screen-xs-max) {
        .responsive-visibility();
    }
}

.visible-sm {

    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        .responsive-visibility();
    }
}

.visible-md {

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        .responsive-visibility();
    }
}

.visible-lg {

    @media (min-width: @screen-lg-min) {
        .responsive-visibility();
    }
}

.hidden-xs {

    @media (max-width: @screen-xs-max) {
        .responsive-invisibility();
    }
}

.hidden-sm {

    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        .responsive-invisibility();
    }
}

.hidden-md { 

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        .responsive-invisibility();
    }
}

.hidden-lg {

    @media (min-width: @screen-lg-min) {
        .responsive-invisibility();
    }
}

html{
    font-size: @base-font-size;
}

.catalog-category-view,
.checkout-cart-index,
.main-container{

    .main-wrapper {
        .screen-sm({ margin-top: @spacing-m !important; });
        .screen-md({ margin-top: 0 !important; });
    }
}
