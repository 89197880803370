/**
*
 * styles for product description and information
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 07.06.16
 *
 */

.product-view .product-collateral {

    .nav-tabs li {

        border-top: 1px solid @color__border--primary;
        border-right: 1px solid @color__border--primary;
        border-left: 1px solid @color__border--primary;

        .screen-md({ border-left: transparent; });

        &.first {
            border-left: 1px solid @color__border--primary;
        }

        &.active {

            a {
                color: @black;
                border-right: none;

                &:before {
                    left: auto;
                    right: 0;
                    width: 100%;
                    border-top: 4px solid @color__border--primary;
                }

                &:hover {

                    &:before {
                        left: auto;
                        right: 0;
                        width: 100%;
                        border-top: 4px solid @color__border--primary;
                    }
                }
            }

            &:hover {

                a:before {
                    border-top: 4px solid @color__border--primary;
                }
            }

            &:after {
                position: absolute;
                bottom: -2px;
                left: 0;
                right: 0;
                width: 100%;
                height: 3px;
                background: @color__border--default;
                content: '';
            }
        }

        a {
            color: @color__attributes;
            border-right: none;

            &:before {
                left: auto;
                right: 0;
                background-color: @color__border--primary;
            }

            &:hover {

                &:before {
                    left: auto;
                    right: 0;
                    width: 100%;
                    border-top: 4px solid @color__border--primary;
                }
            }
        }
    }

    .tab-content {
        border: 1px solid @color__border--primary;

        .tab-pane {
            padding: @spacing-m;
            border-top: none;
        }
    }
}

.product-view .product-collateral {

    @media (min-width: 768px) and (max-width: 1023px) {

        .nav-tabs li {
            display: block;
            width: 100%;
        }
    }
}