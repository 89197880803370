/**
*
 * collapse main styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 31.05.16
 *
 */

.button--collapse {
    display: block;
    width: 100%;
    margin: 0;
    padding: @spacing-s 0;
    background: @white;
    color: lighten(@black, 20%);
    font-size: @font-size-l;
    border-top: 1px solid @grey;
    text-align: center;
    
    .screen-md({ display: none; });

    .fa {
        .transition(transform .6s);
        .transform(rotate(-180deg));
    }

    &.collapsed {

        .fa {
            .transition(transform .6s);
            .transform(rotate(0deg));
        }
    }
}

.list__collapse {
    .order(3);

    width: 100%;

    .configurable-optionlist {
        margin: 0;
    }

    .screen-md({ display: block !important; height: auto !important; });
}