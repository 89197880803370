.product-img-box {

    .product-image {
        width: 100% !important;
    }

    #image-main {
        display: none !important;
    }

    .gallery-image {
        display: block !important;
    }

    .more-views-verticle {
        display: none;
    }

    .owl-buttons {

        .owl-prev, .owl-next {
            color: @mint-lighter !important;
        }
    }
}
