.breadcrumbs {
    display: none;

    * {
        text-transform: none !important;
    }

    .screen-sm({ display: block; });

    .screen-portrait({ display: none; });

    .home {
        display: none;
    }

    ul {
        li a,
        li strong{
            font-size: @font-size-m!important;
            font-weight: @font-weight-normal!important;
            letter-spacing: @letter-spacing-xs!important;
        }
    }

}

.main-breadcrumbs{
    background-color: transparent !important;
}