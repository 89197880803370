/**
*
 * special styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 22.06.16
 *
 */

#checkout-review-table {

    .col__product {
        width: 100px;
        max-width: 100px;
        padding-right: 0 !important;

        .screen-sm({ width: inherit; max-width: inherit; padding-right: @spacing-s !important; });
    }

    .col__quantity {
        width: 40px;
        max-width: 40px;
        padding-left: 0 !important;

        .screen-sm({ width: inherit; max-width: inherit; padding-left: @spacing-s !important; });
    }

    .col__price-single {
        width: 40px;
        max-width: 40px;
        padding-right: 0 !important;
        padding-left: 0 !important;

        .screen-sm({ width: inherit; max-width: inherit; padding-right: @spacing-s !important; padding-left: @spacing-s !important; });
    }

    .col__price-total {
        width: 20px;
        max-width: 20px;
        padding-left: 0 !important;

        .screen-sm({ width: inherit; max-width: inherit; padding-left: @spacing-s !important; });
    }

    tfoot {

        tr td:first-child {

            .screen-sm({ width: 30px; max-width: 30px; });
        }
    }
}