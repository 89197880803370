/**
*
 * grouped items toggle action
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 30.06.16
 *
 */

#grouped-items__table--body {
    display: table-row-group !important;
}

.grouped-items__table--row {

    &:nth-child(n+7) {
        .transition(.6ms);

        display: none;

        .screen-md({ display: table-row; });

        .in & {
            display: table-row;
        }
    }
}