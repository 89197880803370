.page-action-set {
    padding-left: @spacing-l;

    .add-bookmark .icon {
        background-position: -44px 0px;
    }

    .facebook .icon {
        background-position: -156px 0px;
    }

    .action-icon-link {
        float: left;
        padding-left: @spacing-s;

        .icon {
            background-repeat: no-repeat;
            display: block;
            background-image: url(../images/iconset-416595.png);
            height: 22px;
            width: 22px;
            float: left;
            margin-right: @spacing-s;
            background-color: transparent;
        }
    }
}
