h1,
.h1 {
    font-size: @font-size-xxl;
    font-weight: @font-weight-normal;
}

h2,
.h2 {
    font-size: @font-size-xl;
}

h3,
.h3 {
    font-size: @font-size-l;
}

//all other headlines
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-size: @font-size-m;
}

p,
.description--default {
    color: @color__attributes;
    font-size: @font-size-m;
    letter-spacing: @letter-spacing-3xs;
    line-height: @line-height-l;
}

a {
    outline: 0 !important;
}