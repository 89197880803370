/**
*
 * sidebar checkout
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 24.06.16
 *
 */

.main-container .sidebar .block {
    border: none;
    background: @grey;

    dt {
        color: @color__attributes;
        font-style: @font-style-italic;
        font-weight: @font-weight-normal;
        letter-spacing: @letter-spacing-xxs;

        &.complete {
            color: @black;

            &:before {
                display: inline-block;
                margin-right: @spacing-s;
                font-family: FontAwesome;
                content: '\f00c';
            }
            
            .changelink {

                .separator {
                    margin: 0 @spacing-s;
                }
                
                a {
                    color: @mint;
                }
            }
        }
    }

    dd {

        &.complete {

            strong {
                font-weight: @font-weight-normal !important;
            }
        }
    }
}

.checkout-onepage-index {

    .col-lg-3.sidebar {

        .screen-sm({ display: none; });
        .screen-lg({ display: inline-block; margin-top: 55px; });
    }
}