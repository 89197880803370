input,
.chosen-container-single {
    font-size: @font-size-m;
    letter-spacing: @letter-spacing-3xs;
    border-radius: 0 !important;
}

input[type="password"],
input[type="text"],
.chosen-container-single {
    .border-radius(0);
    .webkit-appearance();

    padding-left: @spacing-l !important;
    font-style: @font-style-italic;
    border: 1px solid @mint-light !important;
    box-shadow: none !important;
}

.field__select-box {
    display: block;
    margin-bottom: @spacing-m;

    .chosen-container-single {
        height: 40px;
    }
}

/* Remove controls from Firefox */
input[type="number"] {
    .webkit-appearance(textfield);
}

/* Remove controls from Safari and Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    .webkit-appearance(textfield);

    margin: 0; /* Removes leftover margin */
}

// %%%%%%%%%%%%%%%%%%%%%
// %%% CATEGORY VIEW %%%
// %%%%%%%%%%%%%%%%%%%%%
//noinspection ALL
.qty {
    &:extend(input[type="text"]);

    padding: 0 !important;
    height: 38px;
    width: 41px !important;
    margin-bottom: 0 !important;
    font-size: @font-size-m;
    font-style: @font-style-base;
    text-align: center;

    &:active,
    &:focus,
    &:hover {
        border: 1px solid @mint-darker !important;
        background-color: @mint-transparent !important;
    }
}

// style radio buttons
// add <span class="radio-button"></span> into label
input[type=radio] {

    .checkout-onepage__control-list &,
    .checkout-onepage__payment-list &,
    .checkout-onepage__list & {
        display: none;
    }

    + label .radio-button {
        .border-radius(50%);

        display: inline-block;
        position: relative;
        bottom: -2px;
        margin-right: @spacing-xs;
        padding: 6px;
        border: 1px solid @black;
    }

    &:checked {

        + label .radio-button {

            &:before {
                .border-radius(50%);
                .transform(translate(-50%) translatey(-50%));

                position: absolute;
                top: 50%;
                left: 50%;
                width: 8px;
                height: 8px;
                background: @black;
                content: '';
            }
        }
    }
}

input[type=checkbox] {

    .checkout-onepage__control-list &,
    .checkout-onepage__payment-list &,
    .checkout-onepage__list {
        display: none;
    }

    + label .checkbox-button {
        display: inline-block;
        position: relative;
        bottom: -2px;
        margin-right: @spacing-xs;
        padding: 6px;
        border: 1px solid @black;
    }

    &:checked {

        + label .checkbox-button {

            &:before {
                .transform(translate(-50%) translatey(-50%));

                position: absolute;
                top: 50%;
                left: 50%;
                width: 8px;
                height: 8px;
                background: @black;
                content: '';
            }
        }
    }
}

p.required {
    float: left;
    color: @color__attributes !important;
    font-size: @font-size-s;
    font-style: @font-style-italic !important;
    letter-spacing: @letter-spacing-xxs;
}

// placeholder styles
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: @color__attributes;
    letter-spacing: @letter-spacing-xxs;
}
::-moz-placeholder { /* Firefox 19+ */
    color: @color__attributes;
    letter-spacing: @letter-spacing-xxs;
}
:-ms-input-placeholder { /* IE 10+ */
    color: @color__attributes;
    letter-spacing: @letter-spacing-xxs;
}
:-moz-placeholder { /* Firefox 18- */
    color: @color__attributes;
    letter-spacing: @letter-spacing-xxs;
}
