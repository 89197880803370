/**
*
 * main validation styles for all forms
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 22.06.16
 *
 */

.validation-advice {
    .border-radius(0);
    .shadow();

    top: 36px;
    padding: @spacing-s !important;
    color: @white !important;
    font-size: @font-size-s;
    letter-spacing: @letter-spacing-xxs;
    background: @pink;
    border: none;

    &:before {
        margin-right: @spacing-s;
        color: @white;
    }

    &:after {
        border-bottom-color: @pink;
    }
}

.main-container .opc li ul.form-list li input {

    &.validation-failed {
        border: 1px solid @pink !important;
    }
}