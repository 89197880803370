.cms-index-index .main-container .main-wrapper {
    margin-top: 130px;
}

.cat-label{
    font-family: @font-family;
}

// search box
.header-wrapper .header-container .header-search {
    position: inherit !important;

    @media (min-width: @screen-sm) {
        bottom: -105px;
    }

    @media (min-width: @screen-md) {
        position: relative !important;
    }

    .search-container {
        position: inherit !important;
        height: 100%;
    }
}

// sticky header
.header-wrapper .header-container .sticky-wrapper  {

    .header-maincart{
        border-left: 1px solid @grey-light;

        .cart-container {
            width: auto !important;
            line-height: 0 !important;
            font-size: @font-size-l;
            height: 100%;
        }
    }
}

// meta menu: search, faq, user & cart
.right-header-menu {

    .screen-md-2({ right: @spacing-s !important; });

    .is-sticky & {

        .screen-md-2({ right: @spacing-xs !important; });
    }

    .header-setting {
        width: auto !important;
    }

    .overwrite-setting {
        line-height: 0 !important;
        font-size: @font-size-l !important;

        .screen-md-2({ width: auto !important; });

        i {
            padding: (@spacing-sm + 2) @spacing-m !important;

            .screen-md-2({ padding: (@spacing-l + 5) @spacing-sm !important; });
            .screen-lg({ padding: (@spacing-l + 5) (@spacing-m + 5) !important; });
            .screen-2lg({ padding: (@spacing-l + 5) !important; });

            .is-sticky & {
                .screen-md-2({ padding: (@spacing-m + 5) @spacing-sm !important; });
                .screen-lg({ padding: (@spacing-m + 5) !important; });
            }
        }
    }
}

.header-wrapper .header-container .right-header-menu .header-search {

    .screen-md({ width: auto !important; });
}

.header-wrapper .header-container .header-maincart,
.header-wrapper .header-container .header-setting {
    width: auto !important;
}

.icon-cart-header {
    color: inherit;

    &:hover{
        color: inherit;
    }
}
