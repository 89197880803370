/**
*
 * main category styles
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 14.06.16
 *
 */

.category-products .products-grid .item .product-action .product__images,
.category-products .products-grid .item .product-action .product-image {
    border: none;
}