#main-header-sticky-wrapper:not(.is-sticky),
#mobile-sticky-sticky-wrapper:not(.is-sticky) {

    .main-header {
        //height: 100%;
        position: absolute;
        border-bottom: 1px solid @grey-dark;
    }
}

#main-header {
    border-bottom: 1px solid @grey-dark;
    z-index: 99 !important;
    //background-color: @white;
}

#mobile-sticky {
    border-bottom: 1px solid @grey-dark;
}

.main-header * {
    // disable the ease in effect of the theme
    // because $megaeffect = Mage::getStoreConfig('ultramegamenu/display_options/effect'); this is not used
    // anymore we need to overwrite it here
    .transition(inherit);
}

// --- LOGO ---
#main-header-sticky-wrapper {

    .logo {

        img {

            .screen-2lg({ max-height: 70px; });
        }
    }

    &.is-sticky {

        .logo {

            img {

                .screen-md-2({ height: auto; max-width: 140px; margin: @spacing-xs @spacing-s @spacing-xs 0; });
                .screen-2lg({ max-width: 160px; margin: @spacing-xs @spacing-s; });
            }

            @media (min-width: @screen-md) {
                margin-top: -5px !important;
            }

            @media (min-width: @screen-2lg) {
                margin: 0 0 0 5px !important;
            }
        }
    }

    .main-header {
        box-shadow: none;
    }
}

.header-logo.pull-left.visible-xs {

    .header__additional-menu {
        display: none;
        position: absolute;
        top: 50px;
        width: 100%;
        background: @white;
        border-top: 1px solid #dfdfdf;
        clear: both;
        z-index: 10;

        &.in {
            display: block;
        }

        .nav-accordion {

            li {

                &:before {
                    top: 16px;
                }
            }

            a {
                width: auto;
                height: auto;
                line-height: 2.75;
            }
        }
    }

    ul.nav-accordion {
        width: 100%;
    }

    .logo {
        position: relative;
        cursor: pointer;
    }

    .accordion-toggle {
        line-height: 40px;
    }

    .icon__submenu {
        .button--clear();
        .webkit-appearance();

        display: inline-block;
        margin: @spacing-sm 0 0 @spacing-xs;
        padding: 0 !important;
        line-height: @line-height-s;
        //font-size: @font-size-xxl !important;
        background: transparent;
        border: none !important;

        i {
            .transform(rotate(180deg));
            .transition(transform .5s);

            color: @mint;
            font-size: @font-size-xxl !important;
        }

        &.collapsed {

            i {
                .transform(rotate(0deg));
                .transition(transform .5s);
            }
        }
    }
}

// --- MENU ---
.top-navigation {

    li {
        // disable the ease in effect of the theme
        // because $megaeffect = Mage::getStoreConfig('ultramegamenu/display_options/effect'); this is not used
        // anymore we need to overwrite it here
        .transition(inherit);

        &.level0 {
            // selected category => display 100% bottomborderline
            &.active a.level-top:after {
                width: 100% !important;
            }

            &:focus,
            &:hover {

                &.active {

                    .level-top:after {
                        content: '';
                        display: block;
                        height: 6px;
                        width: 100% !important;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        .transition(none);
                    }
                }

                .menu-wrap-sub {
                    display: block;
                    z-index: 99;
                }
            }

            // arrows for categories with subcategories
            &.parent:not(.custom-block) {
                > a span:after {
                    content: "\F0DD";
                    position: relative;
                    font-family: FontAwesome;
                    padding-left: 8px;
                    vertical-align: top;
                    top: -2px;
                }
            }

        }

        a.level-top {
            //change padding to margin => to keep bottom border in the box
            padding: 0;
            margin: 0;

            &:after {
                height: 6px !important;
            }

            //no bottom border for hovering categories
            &:hover {
                &:after {
                    display: none;
                }
            }

            & span {
                padding: 0 @spacing-s;
                font-weight: @font-weight-bold;
                letter-spacing: @letter-spacing-m;
                font-size: @font-size-s;

                .screen-lg({ font-size: @font-size-m !important; });

            }
        }

        // change color of the two last category links ixn menu ("themecategories")
        &.nav-4,
        &.nav-5 {
            a span {
                color: @color__theme-category;

                &:hover {
                    color: @mint-dark;
                }
            }
        }
    }

    .menu-wrap-sub {
        display: none;
        padding: 0 !important;
        left: 0 !important;
        border: 1px solid @grey-dark !important;
        box-shadow: none !important;

        li {
            // submenu group headlines
            &.level1.groups {
                height: 185px;
                width: 23%;
                margin-bottom: 25px;

                span.title_group {
                    padding: 0 15px;
                    text-transform: uppercase;
                    font-family: @font-family;
                    font-weight: @font-weight-normal;
                    letter-spacing: @letter-spacing-xs;
                    font-size: @font-size-m;
                }

                &.first {
                    margin-right: 2%;
                }

                > a {
                    .transition(none);

                    padding-bottom: 0;
                    float: none;
                    color: @color__link--main;
                    font-weight: @font-weight-normal;
                    border: 0;
                    text-transform: none;

                    &:active,
                    &:focus,
                    &:hover {
                        color: darken(@color__link--main, 15%);
                    }
                }

                .level2 {

                    &.menu-wrap-sub {
                        visibility: hidden;
                    }

                    a {
                        .transition(none);

                        width: 100%;
                        color: @grey-darker;

                        &:active,
                        &:focus,
                        &:hover {

                            span {
                                color: @mint;

                                &:after {
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                }
            }

            // "show all" - links
            .link__all-sub-categories {

                a {
                    width: 100%;
                    font-size: @font-size-s;

                    &:active,
                    &:focus,
                    &:hover {

                        span {
                            text-decoration: underline;
                        }
                    }

                    span {
                        font-size: @font-size-s !important;
                        font-weight: @font-weight-normal;
                        text-transform: uppercase;
                        color: @color__link--main !important;
                    }
                }
            }

            .menu-wrapper {
                li {

                    span {
                        padding: 0 15px;
                        width: 100%;
                        color: @black;
                        font-size: @font-size-m;
                        letter-spacing: @letter-spacing-xxs;

                        &.link__all-sub-categories {
                            color: @mint !important;
                        }

                    }

                    &.first {
                        margin-top: 0;
                    }
                }

                // remove arrows before subcategories
                a:before {
                    display: none !important;
                }

            }
        }

        .ulmenu-block {

            &.ulmenu-block-right {
                padding: 0;
                margin-right: 0;
                position: absolute;
                right: 0;
                height: 100%;
            }

            &.ulmenu-block-center {
                padding: 25px 10px 0 10px;
            }
        }
    }
}

// --- HEADER - RIGHT MENU ---
.right-header-menu {
    height: 100%;

    .display-flex();

    .right-header-menu__top-icon {

        &.fa-shopping-cart {
            position: relative;
        }

        .screen-md({ font-size: @font-size-xl; });
    }

    .right-header-menu__item {
        cursor: pointer;
        border: none !important;

        &.skip-active,
        .skip-active {

            &:not(.icon-cart-header) {
                background-color: @mint;
                color: @white !important;

                .overwrite-setting {
                    color: @white !important;

                    .screen-md({ background-color: @white; color: @mint !important; });
                }
            }

            @media (max-width: @screen-md) {
                background-color: @mint;
                color: @white !important;

                .overwrite-setting {
                    color: @white !important;
                }
            }
        }

        a {
            &:active,
            &:focus,
            &:hover {

                text-decoration: underline;

            }
        }
    }

    .setting-switcher {
        height: 100%;
    }

    .header-search {
        order: 1;
    }

    .links {
        a:before {
            display: none !important;
        }
    }

    .header-setting {
        order: 4;

        &.header-service {
            &:extend(.hidden-xs);

            float: none !important;
            border-right: none !important;
            order: 2;

            .overwrite-setting {

                span:before {
                    content: "\f059" !important;
                }
            }
        }

        ul.service__footer {
            background-color: @grey;

            li {
                padding: 10px 10px;
                float: left;

                i {
                    font-size: @font-size-xxl;
                }
            }
        }

        .switcher-content {
            top: auto;
        }

        .switcher__service {
            position: absolute;
            padding: 0 !important;
            overflow: visible !important;

            ul.links {
                margin-bottom: 0;
                padding: 20px;
            }

            .service__footer {
                width: 100%;
                margin: 0;
                padding: 0 @spacing-m;
                display: inline-flex;

                li {
                    a {
                        font-size: @font-size-m !important;
                        font-weight: @font-weight-normal;
                        letter-spacing: @letter-spacing-xs;
                    }
                }

                li:first-child {
                    padding-left: 0;
                }

                i {
                    color: @color__social-icons;

                    &:hover {
                        color: @color__social-icons--hover;
                    }
                }
            }
        }

        #switcher__user-menu {
            ul {
                padding-bottom: 0;
                border-bottom: none;
                margin-bottom: 0;
            }
        }
    }

    .header-maincart {
        order: 5;

        .screen-sm({ border-left: none !important; });

        .icon_bag_alt {

            &:before {
                content: "\f07a";
            }
        }

        .navbar__cart--header {
            width: 100%;
            background-color: @grey;
            text-align: center;
            text-transform: uppercase;
            line-height: 45px;

            .subtotal {
                color: @orange-dark;
                font-weight: @font-weight-normal;
                font-size: @font-size-l;
            }
        }

        .navbar__cart--link {

            &:active,
            &:focus,
            &:hover {
                text-decoration: none;
            }

            .label {
                color: @orange-dark;
                font-size: @font-size-l;
            }

            .price {
                color: @orange-dark;
                font-weight: @font-weight-bold;
            }
        }

        .minicart-qty {
            position: absolute;
            top: @spacing-xs;
            left: 0;
            right: 0;
            width: @spacing-l;
            margin: 0 auto;
            color: @orange-dark;
            font-family: @font-family;
            font-size: @font-size-s;
            font-weight: @font-weight-normal;
            text-align: center;

            .screen-md-2({ top: @spacing-m; font-size: @font-size-m; });

            .is-sticky & {
                .screen-md-2({ top: @spacing-s; });
            }
        }

        .cart-checkout {
            padding: @spacing-s !important;

            .btn-button {
                .button();
                .button-cart--primary();

                width: 100%;
                margin: 0 0 @spacing-xs !important;
                font-size: @font-size-l !important;
                letter-spacing: @letter-spacing-xxs;
                line-height: 2.19;
                text-transform: uppercase;

                .fa:before {
                    margin: 0 @spacing-xs;
                    font-weight: @font-weight-bold !important;
                }
            }
        }

        .cart-content {
            width: 100% !important;

            li.item {
                border-bottom-color: @puro-settings__color--footer-text-color !important;
                padding: 0 0 @spacing-xxs !important;
                margin: 0 0 @spacing-xs !important;
            }

            .product-details {
                width: 0;
                font-size: @font-size-sm;
                font-weight: @font-weight-normal !important;
                color: @black;

                strong {
                    font-weight: @font-weight-normal;
                }

                .product-name {
                    letter-spacing: normal;
                    font-size: @font-size-sm;
                    font-weight: @font-weight-bold important;
                    padding: 0 0 @spacing-xs !important;

                    a {
                        text-transform: none !important;
                        font-weight: @font-weight-bold !important;
                    }
                }

                .price {
                    color: @black !important;
                    font-size: @font-size-sm !important;
                    font-weight: @font-weight-normal !important;
                }

                .fa {
                    color: @puro-settings__color--footer-text-color;
                    font-weight: @font-weight-light;

                    &:hover {
                        color: @black;
                    }
                }

                dd, dt {
                    text-transform: none !important;
                }
            }
        }
    }

    div.header-search:hover,
    div.header-setting:hover,
    div.header-maincart:hover,
    div.header-search .search-container .overwrite-setting:hover {

        .switcher-content {
            display: none;
        }

        color: @color__link--hover !important;
        background-color: @color__default--highlight;

        .screen-md({ background-color: @white; color: @mint !important; });

        .overwrite-setting span,
        .icon_bag_alt {
            color: @color__link--hover !important;
        }
    }

    .skip-active {
        display: block !important;
        visibility: visible !important;
        opacity: 1 !important;
    }

    .popular-search__searchwords {

        .skip-active {
            display: inline !important;

        }
    }

    .switcher-content:not(.switcher-content__search),
    #navbar-cart {
        color: #696969;
        width: 290px !important;

        @media (min-width: @screen-md) {
            right: 0 !important;
        }
    }

    .switcher-content__search {
        background-color: @white !important;
    }

    .switcher-content {
        .transform(none);

        ul.links {

            a {
                font-size: @font-size-m !important;
                font-weight: @font-weight-normal;
                letter-spacing: @letter-spacing-xs;
            }
        }
    }

    //noinspection ALL
    #navbar-cart {
        width: 100% !important;
        right: 0px !important;
        position: fixed;

        .screen-xs({ width: 290px !important; });

    }

    ul.links {

        li {

            a {

                span {

                    &:before {
                        content: inherit;
                    }
                }
            }
        }
    }
}

.cms-index-index {

    .main-header {
        background-color: @white !important;

        .level-top {
            color: @black !important;

            &:hover,
            &:active,
            &:focus {
                color: @mint !important;
            }
        }
    }

    .right-header-menu {
        height: 100%;
    }
}

.navbar {
    border-radius: 0;

    .screen-md({ width: auto !important; height: auto !important; });

    .navbar-header:hover {

        .navbar-toggle {

            .icon-bar {
                border: 1px solid @white !important;
            }
        }
    }

    &:hover {
        background-color: @color__link--main;

        .icon-bar {
            background-color: @white;
        }
    }

    .navbar-header {
        height: 100%;

        &.skip-active {
            background-color: @mint;
            color: @white !important;

            .icon-bar {
                border: 1px solid @white !important;
            }
        }
    }

    .navbar-toggle {
        display: block;

        .screen-md({ padding: @spacing-s !important; });
    }
}

.nav-mobile-accordion, .sidebar .block-nav {

    #categories_nav {
        border-top: 1px solid @brand-primary;
        background-color: @white;
    }

    .block-content {
        padding: 0 !important;
    }

    li {

        &:before {
            display: none;
        }

    }

    .level0:not(ul),
    .level1:not(.last):not(ul) {
        border-bottom: 1px solid @brand-primary !important;

        a {
            &:hover,
            &:active,
            &:focus {
                background-color: @puro-settings__color--background-alternative;
            }
        }

    }

    .level0 {
        padding-left: 0;

        &.active {
            .level-top {
                background-color: @puro-settings__color--background-alternative;
            }
        }

        .links {
            li:not(.last) {
                border-bottom: 1px solid @grey-dark !important;
            }
        }
    }

    ul.level0 {
        border-top: 1px solid @brand-primary !important;
    }

    ul.level1 {
        border-top: 1px solid @brand-primary;
    }

    .level2 {
        background-color: @white;

        .collapse {
            display: none;
        }

        &:not(.last) {
            border-bottom: 1px solid @brand-primary;
        }

        &.active {

            a {
                background-color: @puro-settings__color--background-alternative;
            }
        }
    }

    ul.level2 {
        display: none !important;
    }
}

.cms-index-index .header-wrapper .header-container .navbar .navbar-toggle {

    .icon-bar {
        background-color: @black !important;
    }

}

.category-menu__custom-links {
    position: relative;
    top: -@spacing-m;

    .level0 {
        background-color: @puro-settings__color--background-alternative;
    }
}

#navbar-service,
#navbar-setting {

    a {
        font-size: @font-size-m;
        font-weight: @font-weight-normal;
        letter-spacing: @letter-spacing-xs;
    }
}

.filled-cart {
    color: @orange-dark;
}

.topmenu-registered-user {
    color: @mint;
}

#mobile-sticky {

    .overwrite-setting {

        &.skip-active {
            background: @orange-dark !important;

            .filled-cart, .minicart-qty, .topmenu-registered-user {
                color: @white;
            }
        }

        &:hover {
            background: @orange-dark !important;

            .filled-cart, .minicart-qty, .topmenu-registered-user {
                color: @white;
            }
        }
    }

    .navbar {

        .navbar-header.skip-active {
            background: @orange-dark !important;
        }

        &:hover {
            background: @orange-dark !important;
        }
    }
}

.header-wrapper .header-container .main-header .right-header-menu {

    .header-setting .setting-switcher .switcher-content,
    .cart-container .cart-wrapper {
        border: 1px solid @grey-dark !important;
    }
}

.top-navigation li.level0 ul li a {
    float: none !important;
}

.minicart__additional-info {

    .additionalInfo__entry {
        font-size: @font-size-sm;
        letter-spacing: normal;
        text-transform: none;
    }

}