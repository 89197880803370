/**
*
 * product attributes
 *
 * @author: Nadja Gimaletdinow (gimaletdinow@ironshark.de)
 * Date: 30.05.16
 *
 */
.configurable-optionlist {
    .display-flex();
    .flex-wrap(wrap);

    margin: @spacing-m 0 0;

    .screen-sm({ margin: @spacing-m 0; });
}

.configurable-optionlist__element {
    .order(2);

    width: 100%;
    margin: 0;
    padding: @spacing-s 0;
    border-top: 1px solid @grey;

    .screen-md({ width: 50%; });

    &:nth-child(even) {

        .screen-md({ padding-right: @spacing-s; });

        .list__collapse & {

            .screen-md({ padding-right: 0; padding-left: @spacing-s; });
        }
    }

    &:nth-child(odd) {

        .screen-md({ padding-left: @spacing-s; });

        .list__collapse & {

            .screen-md({ padding-right: @spacing-s; padding-left: 0; });
        }
    }

    &:first-child {

        .screen-md({ border-top: none; });

        .list__collapse & {

            .screen-md({ border-top: 1px solid @grey; });
        }
    }

    &:last-child {
        border-top: none;

        .list__collapse & {
            border-top: 1px solid @grey;
        }
    }

    &.color-all {
        .order(1);

        .screen-md({ padding-right: @spacing-s; padding-left: 0; });
    }
}

.product-attributes {
    .display-flex();
    .align-items();
    .justify-content(space-between);
}

.product-attributes__item-info {
    .flex(2);

    margin-right: @spacing-s;
    
    .product-attribute__color {
        display: block;
        letter-spacing: @letter-spacing-xs;
    }
    
    .product-attribute__number {
        display: block;
        color: @color__attributes;
        font-weight: @font-weight-normal;
        letter-spacing: @letter-spacing-xs;
    }
}

.product-attribute__image {
    .flex(1);

    text-align: center;

    > img {
        border: 1px solid @grey-darker;
    }
}

.product-attribute__qty {
    .flex(1);
    
    input {
        float: right;

        &:active,
        &:focus,
        &:hover {
            background-color: @white;
        }

        &[disabled] {
            .webkit-appearance(textfield);

            background-color: @grey-light;
            border: 1px solid @grey-dark !important;

            &:active,
            &:focus,
            &:hover {
                background-color: @grey-light !important;
            }
        }
    }
}

.product-attribute__order-number {
    color: @mint;
    font-style: @font-style-italic;
}